import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

import "./style.css";

import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";

import { AiOutlineWhatsApp } from "react-icons/ai";
import { formatPhoneNumber } from "../../utils/format-phone";

const LeadCustomers = () => {
    const history = useHistory();
    const token = getToken();

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [leadCustomers, setLeadCustomers] = useState([]);

    async function getLeadCustomers() {
        try {
            setLoading(true);
            let URL = `api/v1/dashboard/admin/leads?pageNumber=${currentPage}`;

            const response = await api.get(URL, {
                headers: { Authorization: token },
            });
            setLoading(false);
            setLeadCustomers(response.data.data);
            setCurrentPage(response.data.page);
            setTotalPages(response.data.pages);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getLeadCustomers();
    }, []);

    useEffect(() => {
        getLeadCustomers();
    }, [currentPage]);

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="ListLeadCustomersWrapper">
                <p className="promoHeader">Leads Cadastrados</p>
                <hr />

                {!loading && leadCustomers.length > 0 && (
                    <div className="listPromoCodesMobileWrapper">
                        <table className="listPromoCodes">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th scope="col">Data do cadastro</th>
                                    <th scope="col">Nome</th>
                                    <th>Telefone</th>
                                    <th>CPF</th>
                                    <th>Cadastrado por</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadCustomers?.map((lead) => (
                                    <tr key={lead._id}>
                                        <td>
                                            {new Date(
                                                lead.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>

                                        <td>{lead.name || "-"}</td>
                                        <td>{lead?.phone || "-"}</td>
                                        <td> {lead?.cpf || "-"}</td>
                                        <td>{lead?.createdBy?.name || "-"}</td>
                                        <td>
                                            <a
                                                href={`https://api.whatsapp.com/send?phone=55${formatPhoneNumber(
                                                    lead.phone
                                                )}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="buttonAlter"
                                            >
                                                Entrar em contato{" "}
                                                <AiOutlineWhatsApp />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                    showFirstAndLast={true}
                />
            </div>
        </>
    );
};

export default LeadCustomers;
