import { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import "./style.css";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

export default function CadastroNovoCupom() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setIsLoading] = useState(false);
    const [storeId, setStoreId] = useState("");
    const [description, setDescription] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [originalPrice, setOriginalPrice] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [status, setStatus] = useState("ATIVO");
    const [stores, setStores] = useState([]);

    async function getStores() {
        try {
            const response = await api.get("api/v1/stores/full", {
                headers: { Authorization: token },
            });

            setStores(response.data.data);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    useEffect(() => {
        getStores();
    }, []);

    function resetForm() {
        setStoreId("");
        setDescription("");
        setDiscountPercentage("");
        setOriginalPrice("");
        setExpirationDate("");
        setStatus("ATIVO");
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const newCoupon = {
            discountPercentage: Number(discountPercentage) / 100,
            description,
            originalPrice,
            status,
            store: storeId,
            expirationDate,
        };

        if (
            !newCoupon.store ||
            !discountPercentage ||
            newCoupon.discountPercentage === 0 ||
            !originalPrice
        ) {
            toast.error(
                "Selecione a empresa a qual desejar criar o cupom, porcentagem de desconto e o preço original do produto/serviço!",
                {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.post("api/v1/coupons", newCoupon, {
                headers: { Authorization: token },
            });

            console.log(response);
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            resetForm();
        } catch (error) {
            console.log({ error });
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="container default-page">
                <div className="quick-access-buttons mb-4">
                    <Link
                        to="/admin/cupons"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    <Link
                        to="/admin/lista-cupons"
                        className="btn btn-primary"
                        role="button"
                    >
                        Visualizar Todos
                    </Link>
                </div>
                <p className="promoHeader">Cadastro de Novo Cupom</p>
                <hr />

                <div className="new-cupom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            {
                                // <img
                                //     src={selectedStore?.storeLogo}
                                //     alt="Empresa logo"
                                //     height={48}
                                //     width={48}
                                // />
                            }
                            <label htmlFor="exampleFormControlSelect1">
                                Empresa
                            </label>
                            <select
                                className="form-select"
                                id="exampleFormControlSelect1"
                                onChange={({ target }) =>
                                    setStoreId(target.value)
                                }
                                value={storeId}
                            >
                                <option value={""}>Selecione a empresa</option>
                                {stores?.map((store) => (
                                    <option value={store._id} key={store._id}>
                                        {store.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="formGroupExampleInput">
                                Descrição (opcional)
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                value={description}
                                onChange={({ target }) =>
                                    setDescription(target.value)
                                }
                            />
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="originalPrice">
                                Preço original: R$
                            </label>
                            {/* <input
                                required
                                type="number"
                                className="form-control"
                                id="originalPrice"
                                min={0}
                                placeholder="10.30"
                                value={originalPrice}
                                onChange={({ target }) =>
                                    setOriginalPrice(target.value)
                                }
                            /> */}
                            <input
                                type="number"
                                className="form-control"
                                id="originalPrice"
                                placeholder="12.99"
                                required
                                step="0.01"
                                onChange={({ target }) =>
                                    setOriginalPrice(target.value)
                                }
                                min="0"
                                value={originalPrice}
                            />
                        </div>
                        <div className="form-group my-2">
                            <label htmlFor="discount">Desconto %</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                id="discount"
                                min={0}
                                placeholder="10"
                                value={discountPercentage}
                                onChange={({ target }) =>
                                    setDiscountPercentage(target.value)
                                }
                            />
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="expirationDate">
                                Data de expiração
                            </label>
                            <input
                                required
                                type="date"
                                className="form-control"
                                id="expirationDate"
                                value={expirationDate}
                                onChange={({ target }) =>
                                    setExpirationDate(target.value)
                                }
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                                className="form-select"
                                id="status"
                                onChange={({ target }) =>
                                    setStatus(target.value)
                                }
                                value={status}
                            >
                                <option value={"ATIVO"}>ATIVO</option>
                                <option value={"EXPIRADO"}>EXPIRADO</option>
                                <option value={"INATIVO"}>INATIVO</option>
                                <option value={"CANCELADO"}>CANCELADO</option>
                            </select>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary my-2"
                            disabled={isLoading}
                        >
                            {isLoading ? "Aguarde..." : "Criar"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
