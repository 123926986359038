import React, { useCallback, useState } from "react";
import {} from "../..";
import "./style.css";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

const defaultFormData = {
    cashback: "",
    password: "",
};

const ConfirmationReceiptAcceptModal = ({
    onCancel,
    onConfirm,
    cashback,
    cashbackPercentage,
    sat,
}) => {
    const handleConfirmation = useCallback(() => {
        onCancel();
        onConfirm();
    }, []);

    return (
        <div className="ConfirmationReceiptAcceptModal">
            <form className="bonus-form">
                <h5 className="text-center">
                    Por favor, confirme as informações digitadas:
                </h5>
                <div className="form-group mt-4">
                    <p>
                        SAT informado: <span>{sat}</span>
                    </p>
                    <p>
                        Porcentagem correspondente:{" "}
                        <span>{cashbackPercentage}%</span>
                    </p>
                    <h6>
                        Cashback atribuído à nota:{" "}
                        <span className="text-danger">
                            R$
                            {(cashback * 1)
                                .toFixed(2)
                                .toLocaleString(
                                    "pt-br",
                                    { minimumFractionDigits: 2 },
                                    { style: "currency", currency: "BRL" }
                                )}
                        </span>
                    </h6>
                </div>

                <button
                    tabIndex={1}
                    className="btn btn-danger mt-2"
                    type="button"
                    onClick={onCancel}
                >
                    CORRIGIR
                </button>
                <button
                    className="btn btn-success mt-2"
                    type="button"
                    onClick={handleConfirmation}
                >
                    ESTÁ TUDO OK, CONFIRMAR!
                </button>
            </form>
        </div>
    );
};

export default ConfirmationReceiptAcceptModal;
