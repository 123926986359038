import React, { useState } from "react";

import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Button from "../../button/index";
import WithdrawalRequestModal from "../../Modals/withdrawRequest";

import "./style.scss";
import Swipe from "../../../assets/swipe.gif";
import Arrow from "../../../assets/arrow-order.png";
import useSortedData from "../../../hooks/useSortedData";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const prepareDataToSort = (data) => {
    const newData = data.map((row) => {
        return {
            _id: row._id,
            name: row.customer.name,
            cpf: row.customer.cpf,
            value: row.value,
            status: row.status,
            pix: row.pix,
            accountOwnerName: row.accountOwnerName,
            phoneToContact: row.phoneToContact,
            createdAt: row.createdAt,
        };
    });

    return newData;
};

const WithdrawTable = ({ withdrawals, loadWithdrawals }) => {
    console.log("WITHDRAW =>>>", withdrawals);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const [sortedField, setSortedField] = useState("createdAt");
    const [descData, setDescData] = useState(true);
    const { items, requestSort, sortConfig } = useSortedData(
        prepareDataToSort(withdrawals)
    );
    console.log("ITEMS", items);
    const handleChangeStatus = (id) => {
        setId(id);
        setOpen(true);
    };

    const handleClick = (field) => {
        if (field === sortedField) {
            setDescData((prev) => !prev);
            requestSort(field);
            return;
        }

        setSortedField(field);
        setDescData(false);
        requestSort(field);
    };

    return (
        <div className="TableContainer">
            <div component={Paper} className="container" id="tabelaSaques">
                <h5 className="TableTitle">Solicitações de Saque</h5>
                <table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    className="table table-hover"
                >
                    <thead className="thead-dark" id="headTabela">
                        <tr className="TableRowHead">
                            <th
                                className="CellHead"
                                onClick={() => handleClick("createdAt")}
                            >
                                <span>
                                    Data da solicitação:
                                    {sortedField === "createdAt" && (
                                        <img
                                            className={
                                                descData ? "desc" : "asc"
                                            }
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("name")}
                            >
                                <span>
                                    Cliente:{" "}
                                    {sortedField === "name" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("cpf")}
                            >
                                <span>
                                    CPF do Cliente:{" "}
                                    {sortedField === "cpf" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("value")}
                            >
                                <span>
                                    Valor:{" "}
                                    {sortedField === "value" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("pix")}
                            >
                                <span>
                                    Titular da conta:{" "}
                                    {sortedField === "accountOwnerName" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("pix")}
                            >
                                <span>
                                    Chave PIX:{" "}
                                    {sortedField === "pix" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("phoneToContact")}
                            >
                                <span>
                                    Contato:{" "}
                                    {sortedField === "phoneToContact" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="CellHead"
                                align="center"
                                onClick={() => handleClick("status")}
                            >
                                <span>
                                    Status:{" "}
                                    {sortedField === "status" && (
                                        <img
                                            className={descData ? "desc" : ""}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    )}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items &&
                            items.map((row) => (
                                <StyledTableRow
                                    key={row._id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <th
                                        component="th"
                                        scope="row"
                                        align="center"
                                    >
                                        {new Date(row.createdAt).toLocaleString(
                                            "pt-br"
                                        )}
                                    </th>
                                    <th align="center">{row.name}</th>

                                    <th align="center">{row.cpf}</th>

                                    <th align="center">
                                        R$
                                        {` ` +
                                            row.value.toLocaleString(
                                                "pt-br",
                                                { minimumFractionDigits: 2 },
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}
                                    </th>
                                    <th align="center">
                                        {row.accountOwnerName}
                                    </th>
                                    <th align="center">{row.pix}</th>
                                    <th align="center">{row.phoneToContact}</th>

                                    <th align="center">
                                        <Button
                                            onClick={() =>
                                                handleChangeStatus(row._id)
                                            }
                                        >
                                            {row.status}
                                        </Button>
                                    </th>
                                </StyledTableRow>
                            ))}
                    </tbody>
                </table>
            </div>
            {open && (
                <WithdrawalRequestModal
                    setOpen={setOpen}
                    open={open}
                    id={id}
                    onConfirm={loadWithdrawals}
                ></WithdrawalRequestModal>
            )}
        </div>
    );
};

export default WithdrawTable;
