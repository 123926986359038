import { React, useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { RiArrowGoBackFill } from "react-icons/ri";

import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import { getToken } from "../../services/auth";

const ListClientPurchases = () => {
    const history = useHistory();
    const token = getToken();
    const { idCliente } = useParams();

    const [comprasCliente, setComprasCliente] = useState([]);

    const [currentPageStores, setCurrentPageStores] = useState(1);
    const [totalPagesStores, setTotalPagesStores] = useState(1);
    const search = useLocation().search;
    const customerName = new URLSearchParams(search).get("customer");

    async function getComprasCliente() {
        try {
            const response = await api.get(
                `api/v1/customers/${idCliente}/purchases`,
                {
                    headers: { Authorization: token },
                }
            );
            setComprasCliente(response.data.data);
            setTotalPagesStores(response.data.pages);
            console.log(response.data);
        } catch (e) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    // async function getCliente() {
    //     try {
    //         const response = await api.get(
    //             `api/v1/customers/${idCliente}`,
    //             {
    //                 headers: { Authorization: token },
    //             }
    //         );
    //         console.log(response)
    //     } catch (e) {
    //         if (!window.navigator.onLine) {
    //             toast.error(
    //                 "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
    //                 {
    //                     position: "top-right",
    //                     autoClose: 6000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                 }
    //             );
    //             return;
    //         }
    //     }
    // }

    // useEffect(() => {
    //     getCliente();
    // }, []);

    useEffect(() => {
        getComprasCliente();
    }, [currentPageStores]);

    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="promoCodeWrapper">
                <div>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={goBack}
                    >
                        <RiArrowGoBackFill /> Voltar
                    </button>
                </div>

                {customerName && (
                    <p className="promoHeader">
                        Compras feitas por "{customerName}"
                    </p>
                )}
                <hr />

                <div className="listPromoCodesMobileWrapper">
                    <table className="listPromoCodes">
                        <thead>
                            <tr>
                                <th>Data da Compra</th>
                                <th>Estabelecimento</th>
                                <th>Valor R$</th>
                                <th>Cashback R$</th>
                            </tr>
                        </thead>
                        <tbody>
                            {comprasCliente?.map((compra) => {
                                var valorCompra = compra.value;
                                var valorCashback = compra.cashback;
                                return (
                                    <tr key={compra._id}>
                                        <td>
                                            {new Date(
                                                compra.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>
                                        <td>{compra.storeName}</td>
                                        <td>R${valorCompra.toFixed(2)}</td>
                                        <td>R${valorCashback.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            {/* <tr>
                                <td>20/04/2022 17:10:12</td>
                                <td>Paraíso do Bebê</td>
                                <td>R$ 10,00</td>
                                <td>R$ 0,10</td>
                            </tr>
                            <tr>
                                <td>20/04/2022 17:10:12</td>
                                <td>Paraíso do Bebê</td>
                                <td>R$ 10,00</td>
                                <td>R$ 0,10</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ListClientPurchases;
