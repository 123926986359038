import React from "react";
import { useHistory } from "react-router-dom";

import "./style.css";


import Menu from "../../../components/Menu/Menu";
import logo from "../../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";

import CadCodigoCliente from './cadCodCliente.js'

const PromoCode = () => {
    const history = useHistory();

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="promoCodeWrapper">
                <p className="promoHeader">Cadastro de Códigos Promocionais</p>
                <hr />
                <CadCodigoCliente/>
                
                

            </div>
        </>
    );
};

export default PromoCode;
