import React, { useState } from "react";
import {} from "../..";
import "./style.css";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

const defaultFormData = {
    bonus: "",
    password: "",
};

const BonusModal = ({ onCancel, id, setCustomerId, defaultBonusValue }) => {
    const [formData, setFormData] = useState({
        ...defaultFormData,
        bonus: defaultBonusValue,
    });
    const [loading, setLoading] = useState(false);
    const token = getToken();

    const handleInputChange = ({ target }) => {
        setFormData({ ...formData, [target.name]: target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            password: formData.password,
            bonus: formData.bonus * 1,
        };

        try {
            setLoading(true);
            const response = await api.patch(
                `api/v1/customers/${id}/balance`,
                data,
                {
                    headers: { Authorization: token },
                }
            );

            setCustomerId(null);
            setFormData(defaultFormData);
            onCancel();
            setLoading(false);
            toast.success("Bônus atribuído com sucesso!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log(error);
            console.log(error.response);
            if (error.response.status === 403) {
                toast.error("Senha incorreta!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setFormData({ ...formData, password: "" });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="BonusModal">
            <form className="bonus-form" onSubmit={handleSubmit}>
                <h4 className="text-center">Atribuir bônus ao cliente</h4>
                <div className="form-group mt-3">
                    <label htmlFor="bonus">Valor do bônus</label>
                    <input
                        type="number"
                        className="form-control"
                        id="bonus"
                        name="bonus"
                        placeholder="8.50"
                        required
                        onChange={handleInputChange}
                        value={formData.bonus}
                    />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="password">Senha de Admin</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        onChange={handleInputChange}
                        value={formData.password}
                        required
                    />
                </div>
                <button
                    className="btn btn-success mt-4"
                    type="submit"
                    disabled={loading}
                >
                    Atribuir bônus
                </button>
                <button
                    className="btn btn-danger mt-2"
                    type="button"
                    onClick={onCancel}
                >
                    Cancelar
                </button>
            </form>
        </div>
    );
};

export default BonusModal;
