import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import Pagination from "../../components/Pagination";
import "./style.scss";
import { FaList, FaKey } from "react-icons/fa";
import {
    BiTachometer,
    BiDollar,
    BiBody,
    BiMessageSquareDetail,
    BiClipboard,
    BiBarcode,
    BiUserPlus,
} from "react-icons/bi";

import { AiFillHeart, AiOutlineFire, AiOutlineHeart } from "react-icons/ai";

import StoresTable from "../../components/admin/stores/index";

import { cidades } from "../../utils/optionsSelect";

import React from "react";

import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";

import Swipe from "../../assets/swipe.gif";
import { MdOutlinePersonSearch, MdReceiptLong } from "react-icons/md";

export default function SimpleTabs() {
    const clienteIDTemporario = "624c5340e00c681f20243a41";

    const history = useHistory();

    const token = getToken();

    const [city, setCity] = useState("Capistrano");
    const [countStores, setCountStores] = useState();

    const [stores, setStores] = useState([]);
    const [currentPageStores, setCurrentPageStores] = useState(1);
    const [totalPagesStores, setTotalPagesStores] = useState(1);

    const [balance, setBalance] = useState();
    const [countCustomers, setCountCustomers] = useState();
    const [totalSales, setTotalSales] = useState();

    /*const n = ["janeiro", "fevereiro", "março",
  "abril", "maio", "junho", "julho", "agosto",
  "setembro", "outubro", "novembro", "dezembro"][(new Date()).getMonth()]
*/

    //var mes = (new Date().getMonth() + 1)

    /*const CountSales = async () =>{
    const response = await api.get(
      `api/v1/sales/count?month=${mes}`,
      {
        headers:{Authorization: token}
      }
    )

    setCountSales(response.totalSales)
  }
*/
    const loadStores = async () => {
        const response = await api.get(
            `/api/v1/admin/stores?sortBy=name&city=${city}&pageSize=6&pageNumber=${currentPageStores}`,
            {
                headers: { Authorization: token },
            }
        );

        setStores(response.data);
        setCountStores(response.data.totalItems);
        setTotalPagesStores(response.data.pages);
    };

    const Balance = async () => {
        const response = await api.get("/api/v1/sales/monthly/balance", {
            headers: { Authorization: token },
        });

        setBalance(Object.values(response.data)[0]);
        // .toFixed(2)
    };

    const CountCustomers = async () => {
        const response = await api.get("/api/v1/customers/count", {
            headers: { Authorization: token },
        });
        setCountCustomers(Object.values(response.data)[0]);
    };

    const CountSales = async () => {
        const response = await api.get("/api/v1/sales/count", {
            headers: { Authorization: token },
        });
        setTotalSales(Object.values(response.data)[0]);
    };

    useEffect(() => {
        loadStores();
        CountSales();

        /* eslint-disable-next-line */
    }, [city]);

    useEffect(() => {
        Balance();
        CountCustomers();

        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        loadStores();

        /* eslint-disable-next-line */
    }, [currentPageStores, city]);

    function Graph1(props) {
        return (
            <div className="graph">
                <button className="buttonGraph" onClick={props.linkDestination}>
                    <div>
                        <span>{props.title}</span>
                    </div>
                    <div>
                        <FaList />
                        <h3> {props.value}</h3>
                    </div>
                    <div
                        style={{
                            border: `8px solid ${props.color}`,
                            borderRadius: "15px",
                        }}
                    >
                        {props.icon}
                    </div>
                </button>
                {props.new && <span className="badge-new">NOVO!</span>}
            </div>
        );
    }

    return (
        <div className="containerAdmin">
            <Menu
                title1="Cadastrar Empresa"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/cadastro")}
                title2="Saques"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/saques")}
                title3="Depositos"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/depositos")}
                // title4="Cadastro de Código"
                // icon4={<img src={logo} alt="" with="25" height="25" />}
                // onClick4={() => history.push("/admin/promoCodeGuest")}

                title4="Códigos Cadastrados"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/codigos-promocionais")}
            />
            <div className="container-esquerdo"></div>

            <div className="container-dash">
                <section className="groupGraph">
                    <Graph1
                        title="Gerenc. Módulos"
                        value={balance}
                        linkDestination={() =>
                            history.push("/admin/empresas/modulos")
                        }
                        linkTitle=""
                        icon={<FaKey size={24} color="#192a56" />}
                        color="#192a56"
                        new={true}
                    />
                    <Graph1
                        title="Últimas vendas"
                        value=""
                        linkTitle="Últimas vendas cadastradas na plataforma"
                        icon={<AiOutlineFire />}
                        color="red"
                        linkDestination={() =>
                            history.push("/admin/ultimas-vendas")
                        }
                    />
                    <Graph1
                        title="Top Compradores"
                        value=""
                        linkTitle="Top 10 compradores da plataforma"
                        icon={<AiFillHeart />}
                        color="#85e"
                        linkDestination={() =>
                            history.push("/admin/top-compradores")
                        }
                    />

                    <Graph1
                        title="Depositos Pendentes"
                        linkDestination={() => history.push("/admin/depositos")}
                    />
                    <Graph1
                        title="Saques Pendentes"
                        linkDestination={() => history.push("/admin/saques")}
                    />
                    <Graph1
                        title="Nova Empresa"
                        linkDestination={() => history.push("/admin/cadastro")}
                    />
                    <Graph1
                        title="Relatórios"
                        icon={<BiTachometer />}
                        color="blue"
                        linkDestination={() =>
                            history.push("/admin/relatorios")
                        }
                    />
                    {/* <Graph1
                        title="Novo Código Promocional"
                        icon={<BiMessageSquareDetail />}
                        color="#dc3545"
                        linkDestination={() =>
                            history.push(
                                "/admin/codigo-promocional-cliente/criar"
                            )
                        }
                    /> */}

                    <Graph1
                        title="Meus Cupons"
                        icon={<MdReceiptLong />}
                        color="rgb(28,190,211)"
                        linkDestination={() => history.push("/admin/cupons")}
                    />
                    <Graph1
                        title="Total de Vendas"
                        value={totalSales}
                        linkTitle=""
                        icon={<BiDollar />}
                        color="rgb(99,183,100)"
                    />

                    <Graph1
                        title="Notas Fiscais"
                        icon={<BiBarcode />}
                        color="green"
                        linkDestination={() =>
                            history.push("/admin/notas-fiscais")
                        }
                    />
                    <Graph1
                        title="Busca Usuários"
                        icon={<MdOutlinePersonSearch />}
                        color="orange"
                        linkDestination={() =>
                            history.push("/admin/clientes/busca")
                        }
                        new={true}
                    />
                    <Graph1
                        title="Leads"
                        value={countCustomers}
                        icon={<BiUserPlus />}
                        color="#00EE00"
                        linkDestination={() => history.push("/admin/leads")}
                    />
                    <Graph1
                        title="Vendas Avulsas"
                        icon={<BiUserPlus />}
                        color="#1a0061"
                        linkDestination={() =>
                            history.push("/admin/vendas-avulsas")
                        }
                    />
                    <Graph1
                        title="Carteira"
                        value={balance}
                        linkDestination={() => history.push("/admin/carteira")}
                        linkTitle=""
                        icon={<BiBody />}
                        color="rgb(28,190,211)"
                    />
                </section>

                <div className="empresas">
                    <h5 className="TableTitle">Empresas Cadastradas</h5>
                    <button
                        className="btnMarket"
                        onClick={() => history.push("/admin/criarSeguimento")}
                    >
                        Cadastrar Novo Seguimento
                    </button>

                    <div className="SelectCity">
                        <select
                            className="campo"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            name="city"
                            id="city"
                            placeholder="Ex: Fortaleza"
                        >
                            <option value="">Selecione...</option>
                            {cidades.sort().map((cidades) => (
                                <option key={cidades} value={cidades}>
                                    {cidades}
                                </option>
                            ))}
                        </select>
                    </div>

                    {stores.data && (
                        <StoresTable
                            stores={stores.data}
                            loading={loadStores}
                            countStores={countStores}
                        />
                    )}
                    <Pagination
                        currentPage={currentPageStores}
                        totalPages={totalPagesStores}
                        paginate={setCurrentPageStores}
                    />
                </div>
            </div>
        </div>
    );
}
