import React from "react";
import avatar from "../../assets/logoAmarelaCircular.png";
import logoRoxa from "../../assets/logoCircularRoxo.png";
import "./style.scss";
import Logotipo from "../../assets/LOGO.svg";
import { getName } from "../../services/Data_User";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
///Botões de notficação

import AccountCircle from "@material-ui/icons/AccountCircle";

import FeedbackIcon from "@material-ui/icons/Feedback";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "fixed",
        zIndex: 5,
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        background: "#009c60",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    Menu: {
        marginRight: 900,
    },

    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    section: {
        display: "flex",
        textDecoration: "none",
        color: "black",
        outline: "none",
    },
}));

export default function MiniDrawer(props) {
    const userName = getName();

    let name = "";
    if (userName) {
        name = userName.split(" ", 2).join(" ");
    }

    const history = useHistory();
    const HandleLogout = () => {
        history.push("/");
        localStorage.clear();
    };

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    //let result = userName.split(" ", 2).join(" ")
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                id={'backgroundHeader'}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        }, 'backgroundButton')}
                    >
                        <MenuIcon />
                    </IconButton>
                    <a href={props.logo}>
                        <div>
                            <img
                                className="logoTipo"
                                src={Logotipo}
                                alt=""
                                width="100"
                                height="35"
                                style={{ display: (open == true ? 'none' : '') }}
                            />
                        </div>
                    </a>

                    <div className={classes.grow} />
                    <div className={classes.section}>
                        <IconButton color="default">
                            <img
                                id="avatar"
                                src={logoRoxa}
                                width="30"
                                height="30"
                                alt="AvatarIcon"
                            />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>

                    {
                        <div className="Perfilborder">
                            <img
                                id="avatar"
                                src={avatar}
                                width="30"
                                height="30"
                                alt="AvatarIcon"
                            />
                        </div>
                    }
                    <List>
                        <ListItem button>
                            <ListItemText primary={name} />
                        </ListItem>
                    </List>
                </div>
                <Divider />
                <div className={classes.items}>
                    <List>
                        {props.title1 && (
                            <ListItem button onClick={props.onClick1}>
                                <ListItemIcon>{props.icon1}</ListItemIcon>
                                <ListItemText primary={props.title1} />
                            </ListItem>
                        )}

                        {props.title2 && (
                            <ListItem button onClick={props.onClick2}>
                                <ListItemIcon>{props.icon2}</ListItemIcon>
                                <ListItemText primary={props.title2} />
                            </ListItem>
                        )}
                        {props.title3 && (
                            <ListItem button onClick={props.onClick3}>
                                <ListItemIcon>{props.icon3}</ListItemIcon>
                                <ListItemText primary={props.title3} />
                            </ListItem>
                        )}
                        {props.title4 && (
                            <ListItem button onClick={props.onClick4}>
                                <ListItemIcon>{props.icon4}</ListItemIcon>
                                <ListItemText primary={props.title4} />
                            </ListItem>
                        )}
                        {props.title5 && (
                            <ListItem button onClick={props.onClick5}>
                                <ListItemIcon>{props.icon5}</ListItemIcon>
                                <ListItemText primary={props.title5} />
                            </ListItem>
                        )}

                        <ListItem button>
                            <ListItemIcon>{<AccountCircle />}</ListItemIcon>
                            <ListItemText />
                        </ListItem>
                    </List>
                </div>
                <Divider />

                <Divider />
                <List>
                    <ListItem button>
                        <ListItemIcon>{<FeedbackIcon />}</ListItemIcon>
                        <ListItemText />
                    </ListItem>

                    <ListItem button onClick={HandleLogout}>
                        <ListItemIcon>{<ExitToAppIcon />}</ListItemIcon>
                        <ListItemText primary={"Sair"} />
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <main className={classes.content}></main>
        </div>
    );
}
