
  import React , {

    useState
  } from "react";

  import useSortedData from "../../hooks/useSortedData";
    import Arrow from "../../assets/arrow-order.png";

    import './style.scss'

  const prepareDataToSort = (data) => {
    const newData = data.map((row) => {
      return {
          _id: row._id,
          createdAt: row.createdAt,
          lucreiCodeCustomer: row.lucreiCodeCustomer,
          value: row.value,
          cashbackPercentage: row.cashbackPercentage,
          cashback: row.cashback,
          kobeTax: row.kobeTax

      };
    });

    return newData;
  };


  export default function SalesTable({ sales, loading}){

    const [sortedField, setSortedField] = useState("createdAt");
    const [descData, setDescData] = useState(true);
    const { items, requestSort } = useSortedData(
      prepareDataToSort(sales)
    );



    const handleClick = (field) => {
        if (field === sortedField) {
          setDescData((prev) => !prev);
          requestSort(field);
          return;
        }

        setSortedField(field);
        setDescData(false);
        requestSort(field);
      };




    return(
<div className="container" id="tabelaVendas">
<table className="table">
  <thead classNam="thead-dark">
    <tr>
    <th scope="col"
     align="center"
    onClick={() => handleClick("createdAt")}>
                 <span>
                  Data do cadastro:{" "}
                  {sortedField === "createdAt" && (
                    <img
                      className={descData ? "desc" : "asc"}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>


  </th>
      <th scope="col"
        onClick={() => handleClick("lucreiCodeCustomer")}
        align="center"
      >
      <span>
                  Código do cliente{" "}
                  {sortedField === "lucreiCodeCustomer" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
        </span>


      </th>
      <th scope="col"
      onClick={() => handleClick("value")}
      align="center"
      >

                <span>
                  Valor da venda{" "}
                  {sortedField === "value" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>

      </th>

      <th scope="col"
        onClick={() => handleClick("cashbackPercentage")}
        align="center"
      >
      <span>
                 % de Cashback {" "}
                  {sortedField === "cashbackPercentage" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span></th>
      <th scope="col"
      onClick={() => handleClick("cashback")}
      align="center"
      >
      <span>
                  Valor do Cashback $${" "}
                  {sortedField === "cashback" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>


      </th>
      <th scope="col"
      onClick={() => handleClick("kobeTax")}
      align="center"
      >
      <span>
                  Taxa Lucrei{" "}
                  {sortedField === "kobeTax" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>

      </th>
    </tr>
  </thead>
  <tbody>
  {items &&
      items.map((row) => (
            <tr key={row._id}>

                <td  align="center" > {new Date(row.createdAt).toLocaleString("pt-br")}</td>
                <td  align="center" > {row.lucreiCodeCustomer} </td>
                <td  align="center" > R$ {row.value.toFixed(2)} </td>
                <td  align="center" >{row.cashbackPercentage*100}%</td>
                <td  align="center" >R$ {row.cashback.toFixed(2)}</td>
                <td  align="center">R$ {row.kobeTax.toFixed(2)}</td>

             </tr>
  ))}
  </tbody>
</table>


</div>
      )
  }
