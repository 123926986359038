import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../services/auth";
import { toast } from "react-toastify";

import InputMask from "react-input-mask";

import api from "../../../services/api";

import "./style.css";
import CircularProgress from "@material-ui/core/CircularProgress";

toast.configure();

const CadCodigo_Cliente = () => {
    const history = useHistory();

    const token = getToken();

    const [loading, setloading] = useState(false);

    const [code, setCode] = useState("");
    const [bonusToCustomers, setBonusToCustomers] = useState("");
    const [bonusToOwner, setBonusToOwner] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [ownerCpf, setOwnerCpf] = useState("");
    const [ownerCity, setOwnerCity] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");

    function resetForm() {
        setCode("");
        setBonusToCustomers("");
        setBonusToOwner("");
        setOwnerName("");
        setOwnerCpf("");
        setOwnerCity("");
        setOwnerPhone("");
        setOwnerEmail("");
    }

    async function cadPromoCode(e) {
        e.preventDefault();

        const data = {
            code,
            bonusToCustomers,
            bonusToOwner,
            promoCodeOwnerInfo: {
                name: ownerName,
                email: ownerEmail,
                cpf: ownerCpf,
                city: ownerCity,
                phone: ownerPhone,
            },
        };
        console.log(data);

        try {
            const response = await api.post("api/v1/promo-codes/", data, {
                headers: { Authorization: token },
            });
            console.log(response);
            resetForm();

            toast.success("Código cadastrado com sucesso", {
                position: toast.POSITION.TOP_RIGHT,
            });

            console.log(response);
        } catch (e) {
            setloading(false);

            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            const { status, data } = e.response;
            const { message } = data;

            if (status && status === 400) {
                toast.error("O código enviado já está cadastrado no banco", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            if (status && status === 403) {
                toast.error("Algo deu errado nas permissões de administrador", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
    }


  return (
    <>
    <form method="POST" onSubmit={cadPromoCode}>
        <div className="promoCodeForm">
            <p className="promoSubHeader">
                Informações sobre o Código Promocional do Cliente
            </p>

            <div className="promoCodeFormRow">
                <div className="promoCodeFormComponent">
                    <label>Código (4-20 Caracteres): </label>
                    <input
                        onChange={(e) => {
                            setCode(e.target.value);
                        }}
                        value={code}
                        name="PromoCode"
                        type="Text"
                        className="campo"
                        required
                    />
                </div>

                <div className="promoCodeFormComponent">
                    <label>Bônus de Usuário:</label>
                        <input
                            onChange={(e) => {
                                setBonusToCustomers(e.target.value);
                            }}
                            value={bonusToCustomers}
                            name="UserBonus"
                            type="Number"
                            className="campo"
                            min={0}
                            required
                        />
                    </div>
            </div>

            <div className="promoCodeFormRow">
                <div className="promoCodeFormComponent">
                    <label>Bônus do Proprietário:</label>
                    <input
                        onChange={(e) => {
                            setBonusToOwner(e.target.value);
                        }}
                        value={bonusToOwner}
                        name="OwnerBonus"
                        type="Number"
                        className="campo"
                        min={0}
                        required
                    />
                </div>
            </div>

            <hr />

            <p className="promoSubHeader">
                Infomações sobre o Fornecedor do Código
            </p>

            <div className="promoCodeFormRow">
                <div className="promoCodeFormComponent">
                    <label>Nome Completo: </label>
                    <input
                        onChange={(e) => {
                            setOwnerName(e.target.value);
                        }}
                        value={ownerName}
                        name="NomeCompleto"
                        type="Text"
                        className="campo"
                        required
                    />
                </div>

                <div className="promoCodeFormComponent">
                    <label>Cidade: </label>
                    <input
                        onChange={(e) => {
                            setOwnerCity(e.target.value);
                        }}
                        value={ownerCity}
                        name="Cidade"
                        type="Text"
                        className="campo"
                        required
                    />
                </div>
            </div>

            <div className="promoCodeFormRow">
                <div className="promoCodeFormComponent">
                    <label>Telefone*:</label>
                    <InputMask
                        onChange={(e) => {
                            setOwnerPhone(e.target.value);
                        }}
                        value={ownerPhone}
                        name="Telefone"
                        type="Tel"
                        className="campo"
                        mask='(99) 9 9999-9999'
                    />
                </div>

                <div className="promoCodeFormComponent">
                    <label>CPF*:</label>
                    <InputMask
                        onChange={(e) => {
                            setOwnerCpf(e.target.value);
                        }}
                        value={ownerCpf}
                        name="CPF"
                        className="campo"
                        mask="999.999.999-99"
                        type="text"
                    />
                </div>
            </div>

            <div className="promoCodeFormRow">
                <div className="promoCodeFormComponent">
                    <label>Email*:</label>
                    <input
                        onChange={(e) => {
                            setOwnerEmail(e.target.value);
                        }}
                        value={ownerEmail}
                        name="E-mail"
                        type="Email"
                        className="campo"
                    />
                </div>

                <div className="promoCodeFormComponent">
                    <label>
                        *: Pelo menos um desses campos precisa ser
                        preenchido. Não se faz a necessidade de
                        preencher todos!
                    </label>
                </div>
            </div>

            <div className="promoCodeFormRow">
                <button
                    className="promoCodeFormSubmit"
                    type="Submit"
                >
                    {loading ? (
                        <CircularProgress
                            color="primary"
                            size={21}
                        />
                    ) : (
                        "Cadastrar"
                        )}
                </button>

                <button className='promoCodeFormAlter' onClick={() => history.push('/admin/promoCodeSeller')}>Cadastrar código para Empresas</button>
            </div>
        </div>

    </form>
    
    </>
  )
}

export default CadCodigo_Cliente