import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";

const MainReportsPage = () => {
    const history = useHistory();

    useEffect(() => {}, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="MainReportsPage">
                <div className="row">
                    <h3 className="text-center">
                        Página Inicial de Relatórios e Dados
                    </h3>
                </div>
                <div className="row d-flex align-items-stretch groupItems">
                    <div className="col-6">
                        <div className="card card-links">
                            <div className="card-body">
                                <h5 className="mb-4">Relatórios em PDF</h5>
                                <ul>
                                    <li>Relatórios de Vendas</li>
                                    <li>Relatórios de Vendas por Cidade</li>
                                    <li>Relatórios de Clientes por Cidade</li>
                                </ul>
                                <Link to={"/admin/relatorios-pdf"}>
                                    Ir para a página de Relatórios em PDF
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card card-links">
                            <div className="card-body">
                                <h5 className="mb-4">Dados Mensais</h5>
                                <ul>
                                    <li>Clientes que mais compraram no mês</li>
                                    <li>
                                        Total de cashback recebido por cliente
                                        no mês
                                    </li>
                                    <li>
                                        Número total de compras feitas por mês
                                    </li>
                                    <li>
                                        Número de vendas mensais por empresa
                                    </li>
                                    <li>Número total de vendas mensais</li>
                                    <li>Total vendido por empresa no mês</li>
                                    <li>
                                        Total de cashback dado aos clientes no
                                        mês
                                    </li>
                                </ul>
                                <Link to={"/admin/dados-mensais"}>
                                    Ir para a página de Dados Mensais
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-6" style={{ marginTop: "10px" }}>
                        <div className="card card-links">
                            <div className="card-body">
                                <h5 className="mb-4">Notas Fiscais</h5>
                                <ul>
                                    <li>
                                        Relatório de Notas Fiscais por empresa
                                    </li>
                                    <li>
                                        Total de usuários com envios de notas
                                        fiscais
                                    </li>
                                    <li>
                                        Relatório - TOP 30 Usuários com + envios
                                    </li>
                                </ul>
                                <div className="mb-2">
                                    <Link
                                        to={"/admin/relatorios-notas-fiscais"}
                                    >
                                        Relatório Notas Fiscais por empresas
                                    </Link>
                                </div>
                                <div className="mb-2">
                                    <Link
                                        to={"/admin/top-senders-notas-fiscais"}
                                    >
                                        Relatório TOP 30 usuários com + envios
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to={"/admin/top-senders-notas-fiscais"}
                                    >
                                        Número de quantos usuários enviaram
                                        notas fiscais
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-6" style={{ marginTop: "10px" }}>
                        <div className="card card-links">
                            <div className="card-body">
                                <h5 className="mb-4">
                                    Relatório de Informações de Clientes
                                </h5>
                                <ul>
                                    <li>
                                        Relatório de Informações de Clientes por
                                        data de cadastro
                                    </li>
                                </ul>
                                <Link to={"/admin/relatorios-clientes"}>
                                    Relatório de Informações de Clientes por
                                    data de cadastro
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default MainReportsPage;
