import { useState } from "react";
import "./style.scss";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FaUserLock } from "react-icons/fa";
import RedefinePasswordModal from "../../components/RedefinePasswordModal";
import { useEffect } from "react";

const MODULES = [
    "cashback",
    "venda-avulsa",
    "cartao-fidelidade",
    "cupom-desconto",
    "financeiro",
];

export default function StoreModules() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setLoading] = useState(false);
    const [modules, setModules] = useState(null);
    const [originalModules, setOriginalModules] = useState(null);
    const [store, setStore] = useState();
    const [stores, setStores] = useState([]);

    const handleUpdateModules = async () => {
        try {
            setLoading(true);

            console.log("store =>", store);

            const response = await api.patch(
                `api/v1/dashboard/admin/stores/${store}/modules`,
                { modules },
                {
                    headers: { Authorization: token },
                }
            );

            setLoading(false);
            setStore("");
            setModules(null);
            setOriginalModules(null);
            toast.success(response.data.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    };

    async function getStores() {
        try {
            const response = await api.get("api/v1/stores/full", {
                headers: { Authorization: token },
            });

            setStores(response.data.data);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    async function getStoreModules() {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/stores/${store}/modules`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log(response);
            setModules(response.data.modules);
            setOriginalModules(response.data.modules);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    function handleAddModule(module) {
        setModules((modules) => [...modules, module]);
    }

    function handleRemoveModule(module) {
        setModules((modules) => modules.filter((mod) => mod !== module));
    }

    useEffect(() => {
        setModules(null);
        setOriginalModules(null);
    }, [store]);

    useEffect(() => {
        getStores();
    }, []);

    return (
        <div>
            <div className="container default-page">
                <div className="quick-access-buttons mb-4">
                    <Link
                        to="/admin/dash"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    {/* <Link
                        to="/admin/novo-cupom"
                        className="btn btn-primary"
                        role="button"
                        disabled={true}
                    >
                        Ir para Busca Usuários Empresas
                    </Link> */}
                </div>
                <h2 className="text-left mt-4">Gerenciamento de Módulos</h2>
                <hr />

                {isLoading && <Loader />}
                <div className="tabela mt-4">
                    <div className="row d-flex align-items-center">
                        <div className="col-10">
                            <select
                                className="form-select"
                                id="exampleFormControlSelect1"
                                onChange={({ target }) =>
                                    setStore(target.value)
                                }
                                value={store}
                            >
                                <option value={""}>Selecione a empresa</option>
                                {stores?.map((store) => (
                                    <option value={store._id} key={store._id}>
                                        {store.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-2">
                            <button
                                className="btn btn-primary"
                                onClick={getStoreModules}
                                disabled={!store}
                            >
                                CARREGAR MÓDULOS
                            </button>
                        </div>
                    </div>
                    {store && modules && (
                        <table className="table table-striped mt-4">
                            <thead>
                                <tr>
                                    <th scope="col">Módulo</th>

                                    <th scope="col">Habilitar/Desabilitar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {MODULES?.map((module) => (
                                    <tr key={module}>
                                        <td scope="row">{module}</td>

                                        {
                                            <td>
                                                {modules.includes(module) ? (
                                                    <button
                                                        style={{
                                                            minWidth: "108px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                            handleRemoveModule(
                                                                module
                                                            )
                                                        }
                                                    >
                                                        DESABILITAR
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={{
                                                            minWidth: "108px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            handleAddModule(
                                                                module
                                                            )
                                                        }
                                                    >
                                                        HABILITAR
                                                    </button>
                                                )}
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {originalModules !== modules && (
                        <div className="d-flex justify-content-end mt-4">
                            <button
                                type="button"
                                className="btn btn-lg btn-success ml-auto"
                                onClick={handleUpdateModules}
                            >
                                SALVAR ALTERAÇÕES
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
