import React from "react";

function CurrencyInputTel(props) {
    return (
        <div>
            <label htmlFor={props.id}>{props.label}</label>
            <input
                type="tel"
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                value={
                    props.value === 0
                        ? ""
                        : props.value.toFixed(2).replace(".", ",")
                }
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
}

export default CurrencyInputTel;
