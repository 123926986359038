import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";

import Logon from "../pages/Logon/index";

import Customer from "../pages/customer/index";
import Admin from "../pages/Admin/index";

import ViewStore from "../pages/ViewStores/index";

import CadStore from "../pages/cadastroEmpresa/index";
import DepositsTable from "../pages/depositos/index";
import WithdrawTable from "../pages/saques/index";

import Segments from "../pages/segments/index";

import Carteira from "../pages/Carteira/index";
import TestingComponentsPage from "../pages/TestingComponentsPage";

import PromoCodeCliente from "../pages/cadastroCodigoPromocional/cadastroCodigoCliente/index";
import PromoCodeVendedor from "../pages/cadastroCodigoPromocional/cadastroCodigoVendedor/index";

import ListPromoCode from "../pages/listarCodigoPromocional";
import PromoCodeDetails from "../pages/PromoCodeDetails";
import MainReportsPage from "../pages/MainReportsPage";
import ReportsPage from "../pages/ReportsPage";
import CustomersStoreReportsPage from "../pages/CustomersStoreReportsPage";
import ListClientPurchases from "../pages/listarComprasCliente";
import ListCustomersReceipts from "../pages/ListCustomersReceipts";
import CustomersSearch from "../pages/CustomersSearch";
import LatestSalesPage from "../pages/LatestSalesPage";
import CustomerSalesDetailsPage from "../pages/CustomerSalesDetailsPage";
import TopBuyersPage from "../pages/TopBuyersPage";
import CustomerReceiptsDetailsPage from "../pages/CustomerReceiptsDetailsPage";
import StoreReceiptsPage from "../pages/StoreReceiptsPage";
import CustomersReportPage from "../pages/CustomersReportPage/CustomersReportPage";
import TopReceiptsSendersPage from "../pages/TopReceiptsSendersPage";
import CustomersSingleLoginReportPage from "../pages/CustomersSingleLoginReportPage/CustomersSingleLoginReportPage";
import LeadCustomers from "../pages/LeadCustomers";
import StoreSeparateSalesPage from "../pages/StoreSeparateSalesPage";
import CadastroNovoCupom from "../pages/CadastroNovoCupom";
import CupomListPage from "../pages/CupomListPage";
import CreatedCustomerCouponsListPage from "../pages/CreatedCustomerCouponsListPage";
import MainCouponsPage from "../pages/MainCouponsPage";
import EditCupomPage from "../pages/EditCupomPage";
import StatsReportPage from "../pages/StatsReportPage";
import CustomerCouponsStatsPage from "../pages/CustomerCouponsStatsPage";
import SearchCustomer from "../pages/SearchCustomer";
import StoreMonthlyReport from "../pages/StoreMonthlyReport";
import StoreModules from "../pages/StoreModules";

const PrivateRoute = ({ component: Component, ...params }) => (
    <Route
        {...params}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                />
            )
        }
    />
);

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Logon} />

                {/**Admin*/}
                <PrivateRoute path="/admin/cadastro" component={CadStore} />
                <PrivateRoute path="/admin/dash" component={Admin} />
                <PrivateRoute path="/admin/empresa" component={ViewStore} />
                <PrivateRoute
                    path="/admin/depositos"
                    component={DepositsTable}
                />
                <PrivateRoute path="/admin/saques" component={WithdrawTable} />
                <PrivateRoute
                    path="/admin/criarSeguimento"
                    component={Segments}
                />
                {/* <PrivateRoute path="/admin/clientes" component={Customer} /> */}
                <PrivateRoute
                    path="/admin/clientes/busca"
                    component={SearchCustomer}
                />
                <PrivateRoute
                    path="/admin/relatorios"
                    component={MainReportsPage}
                />
                <PrivateRoute
                    path="/admin/rms"
                    component={StoreMonthlyReport}
                />
                <PrivateRoute
                    path="/admin/dados-mensais"
                    component={CustomersStoreReportsPage}
                />

                <PrivateRoute path="/admin/leads" component={LeadCustomers} />

                <PrivateRoute
                    path="/admin/relatorios-pdf"
                    component={ReportsPage}
                />

                <PrivateRoute
                    path="/admin/relatorios-notas-fiscais"
                    component={StoreReceiptsPage}
                />

                <PrivateRoute
                    path="/admin/relatorios-clientes-unico-login"
                    component={CustomersSingleLoginReportPage}
                />

                <PrivateRoute
                    path="/admin/top-senders-notas-fiscais"
                    component={TopReceiptsSendersPage}
                />

                <PrivateRoute
                    path="/admin/relatorios-clientes"
                    component={CustomersReportPage}
                />

                <PrivateRoute path="/admin/carteira" component={Carteira} />
                <PrivateRoute
                    path="/admin/codigo-promocional-cliente/criar"
                    component={PromoCodeCliente}
                />
                <PrivateRoute
                    path="/admin/promoCodeSeller"
                    component={PromoCodeVendedor}
                />
                <PrivateRoute
                    path="/admin/empresas/modulos"
                    component={StoreModules}
                />
                <PrivateRoute
                    path="/admin/ultimas-vendas"
                    component={LatestSalesPage}
                />
                <PrivateRoute
                    path="/admin/vendas-avulsas"
                    component={StoreSeparateSalesPage}
                />
                <PrivateRoute
                    path="/admin/top-compradores"
                    component={TopBuyersPage}
                />
                <PrivateRoute
                    path="/admin/codigos-promocionais"
                    component={ListPromoCode}
                />

                <PrivateRoute
                    path="/admin/cupons/:id/cupons-gerados"
                    component={CreatedCustomerCouponsListPage}
                />

                <PrivateRoute
                    path="/admin/cupons/:id"
                    component={EditCupomPage}
                />

                <PrivateRoute
                    path="/admin/cupons"
                    component={MainCouponsPage}
                />

                <PrivateRoute
                    path="/admin/novo-cupom"
                    component={CadastroNovoCupom}
                />

                <PrivateRoute
                    path="/admin/lista-cupons"
                    component={CupomListPage}
                />

                <PrivateRoute
                    path="/admin/cupons-cliente"
                    component={CustomerCouponsStatsPage}
                />

                <PrivateRoute
                    path="/admin/notas-fiscais"
                    component={ListCustomersReceipts}
                />
                <PrivateRoute
                    path="/admin/promo-codes/:code/detalhes"
                    component={PromoCodeDetails}
                />
                <PrivateRoute
                    path="/clientes/:idCliente/compras"
                    component={CustomerSalesDetailsPage}
                />
                <PrivateRoute
                    path="/clientes/:idCliente/notas-fiscais"
                    component={CustomerReceiptsDetailsPage}
                />
                <PrivateRoute
                    path="/admin/:idCliente/detalhes-compras"
                    component={ListClientPurchases}
                />
                <PrivateRoute
                    path="/relatorios/:idEmpresa/stats"
                    component={StatsReportPage}
                />
                <PrivateRoute
                    path="/vasconha"
                    component={TestingComponentsPage}
                />
            </Switch>
        </BrowserRouter>
    );
}
