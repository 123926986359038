import { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import "./style.css";
import api from "../../services/api";

import { useHistory, Link, useParams } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

export default function EditCupomPage() {
    const history = useHistory();
    const token = getToken();
    const couponId = useParams().id;

    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [selectedCoupon, setSelectedCoupon] = useState({
        description: "",
        status: "",
        expirationDate: "",
        discountPercentage: "",
        originalPrice: "",
    });

    async function getCouponById() {
        try {
            const response = await api.get(`api/v1/coupons/${couponId}`, {
                headers: { Authorization: token },
            });

            console.log(response);
            const data = response?.data?.data;
            setSelectedCoupon({
                ...data,
                discountPercentage: Number(data.discountPercentage) * 100,
                originalPrice: data.originalPrice
                    ? Number(data.originalPrice)
                    : 0,
            });
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    useEffect(() => {
        getCouponById();
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const {
            discountPercentage,
            status,
            description,
            expirationDate,
            originalPrice,
        } = selectedCoupon;

        console.log("EXPIRATION DATE", expirationDate);
        if (discountPercentage === 0) {
            toast.error("Informe uma porcentagem de desconto válida!", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        if (originalPrice === 0) {
            toast.error("O preço original não pode ser igual a zero!", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        if (!password) {
            toast.error(
                "Informe a sua senha de Admin para prosseguir com a operação",
                {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.patch(
                `api/v1/coupons/${couponId}`,
                {
                    discountPercentage: Number(discountPercentage) / 100,
                    status,
                    description,
                    expirationDate,
                    password,
                    originalPrice: originalPrice ? Number(originalPrice) : 0,
                },
                {
                    headers: { Authorization: token },
                }
            );

            console.log(response);
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            history.push("/admin/lista-cupons");
        } catch (error) {
            console.log({ error });
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="container default-page">
                <div className="quick-access-buttons mb-4">
                    <Link
                        to="/admin/lista-cupons"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    <Link
                        to={`/admin/cupons/${couponId}/cupons-gerados`}
                        className="btn btn-primary"
                        role="button"
                    >
                        Ver Cupons Gerados
                    </Link>
                </div>
                <p className="promoHeader">Editar Cupom</p>
                <hr />
                {selectedCoupon?.storeLogo && (
                    <img
                        src={selectedCoupon?.storeLogo}
                        alt="Logo da empresa"
                        width={128}
                        height={128}
                    />
                )}
                <div className="new-cupom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group my-2">
                            <label htmlFor="formGroupExampleInput">
                                Descrição (opcional)
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                value={selectedCoupon?.description}
                                onChange={({ target }) =>
                                    setSelectedCoupon((selectedCoupon) => ({
                                        ...selectedCoupon,
                                        description: target.value,
                                    }))
                                }
                            />
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="discount">Preço original R$</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                id="discount"
                                placeholder="10"
                                value={selectedCoupon?.originalPrice}
                                onChange={({ target }) =>
                                    setSelectedCoupon((selectedCoupon) => ({
                                        ...selectedCoupon,
                                        originalPrice: target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="form-group my-2">
                            <label htmlFor="discount">Desconto %</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                id="discount"
                                placeholder="10"
                                value={selectedCoupon?.discountPercentage}
                                onChange={({ target }) =>
                                    setSelectedCoupon((selectedCoupon) => ({
                                        ...selectedCoupon,
                                        discountPercentage: target.value,
                                    }))
                                }
                            />
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="expirationDate">
                                Data de expiração
                            </label>
                            <input
                                required
                                type="date"
                                className="form-control"
                                id="expirationDate"
                                value={selectedCoupon?.expirationDate.slice(
                                    0,
                                    10
                                )}
                                onChange={({ target }) =>
                                    setSelectedCoupon((selectedCoupon) => ({
                                        ...selectedCoupon,
                                        expirationDate: target.value,
                                    }))
                                }
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                                className="form-select"
                                id="status"
                                onChange={({ target }) =>
                                    setSelectedCoupon((selectedCoupon) => ({
                                        ...selectedCoupon,
                                        status: target.value,
                                    }))
                                }
                                value={selectedCoupon?.status}
                            >
                                <option value={"ATIVO"}>ATIVO</option>
                                <option value={"EXPIRADO"}>EXPIRADO</option>
                                <option value={"INATIVO"}>INATIVO</option>
                                <option value={"CANCELADO"}>CANCELADO</option>
                            </select>
                        </div>

                        <div className="form-group my-2">
                            <label htmlFor="formGroupExampleInput">
                                Confirme a sua senha
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="formGroupExampleInput"
                                value={password}
                                onChange={({ target }) =>
                                    setPassword(target.value)
                                }
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-success my-2"
                            disabled={isLoading}
                        >
                            {isLoading ? "Aguarde..." : "Salvar alterações"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
