import React, { useState, useEffect } from "react";
import "./style.scss";

import logo from "../../assets/LogotipoRoxo.png";

import { useHistory } from "react-router-dom";
//import MaskedInput from 'react-text-mask';

import loginToken from "../../services/auth";
import { loginData } from "../../services/Data_User";
import api from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";


import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateCredential } from "../../utils/credential-validate";
import useForm from "../../hooks/useForm";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


function Login(props) {
  const confirmationEmail = props.location.search.split("?")[1];

  const history = useHistory();

 const credential = ''
 // const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [setError] = useState(null);


  //const [open, setOpen] = useState(false);

  //const onOpenModal = () => setOpen(true);
  //const onCloseModal = () => setOpen(false);
  const inputCredential = useForm("credential");
  const inputPassword = useForm("password");

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnBlur = () => {
    if (credential && credential.length === 0) return;
    if (!validateCredential(credential))
      setError("Email ou telefone inválido.");
    else setError(null);
  };

  //função de conexao com API"store"

  async function HandleSignIn(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      credential: inputCredential.value,
      password: inputPassword.value,
    };

    try {
      const response = await api.post("/api/v1/auth/login", data);

      loginToken(response.data.token);


  console.log(response.data.account.role)

      if (response.data.account.role === "admin") {
        history.push("/admin/dash");
      }

      loginData(
        response.data.account.user.name,
        response.data.account.email,
        response.data.account.phone
      );

      setLoading(true);
      toast.success("Seja Bem-Vindo", { position: toast.POSITION.BOTTOM_RIGHT });
    } catch (error) {
      console.log("ERROR", error.response.status);
      const { status, data } = error.response;
      const { message } = data;

      if (
        status === 400 &&
        message === "Unable to login! Check your credentials."
      )
        toast.error(
          "Não foi possível fazer login! Cheque os seus dados e tente novamente.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      setLoading(false);
    }
  }

  useEffect(() => {
    if (confirmationEmail === "confirmation=success") {
      toast.success("E-mail confirmado com sucesso!.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }, [confirmationEmail]);

  return (
    <div>
   <div className="logoLoad">
      {loading ? <LinearProgress /> :  "" }

      </div>
      <img id="logo" src={logo} alt="logo" />
      <div id="box">
        <div id="box-left">
          {/*Lado esquerdo*/}
          {/* Lado do formulário */}
          <form onSubmit={HandleSignIn} className="LoginForm">
            {}
            <strong>
              <h5>ENTRE NA SUA CONTA:</h5>

            </strong>
            <div className="form-group">
              <label htmlFor="credencial">Telefone ou E-mail:</label>
              <input
                id="credencial"
                className="campo"
                onChange={inputCredential.onChange}
                value={inputCredential.value}
                type="text"
                name="credencial"
                placeholder="85991234567 | joao@gmail.com"
                required
                onBlur={inputCredential.onBlur}
              />
              {inputCredential.error && (
                <span className="error-message">{inputCredential.error}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="credencial">Senha:</label>
              <Input
                className="campo"
                onChange={inputPassword.onChange}
                value={inputPassword.value}
                onBlur={inputPassword.onBlur}
                type={values.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                name="senha"
                placeholder="Senha"
                required
                minLength="6"
                maxLength="50"
              />

              {inputPassword.error && (
                <span className="error-message">{inputPassword.error}</span>
              )}
            </div>
            <div className="form-group">
              <button
                id="button_Entrar"
                className="button_Entrar"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="primary" size={21} />
                ) : (
                  "Entrar"
                )}
              </button>
            </div>
          </form>

        </div>


      </div>

    </div>
  );
}

export default Login;
