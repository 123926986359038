import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

import "./style.css";

import Menu from "../../components/Menu/Menu";
import ReceiptStatusModal from "../../components/ReceiptStatusModal";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import { IoReceiptOutline, IoCashOutline } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";

import { MdAccessTime, MdOutlineDone } from "react-icons/md";
import { convertLinkToHttpsAndCDN } from "../../utils/format-links";
import ConfirmationReceiptAcceptModal from "../../components/ConfirmationReceiptAcceptModal";

const ListCustomersReceipts = () => {
    const history = useHistory();
    const token = getToken();

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [receipts, setReceipts] = useState([]);
    const [receiptId, setReceiptId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState("");
    const [currentStatus, setCurrentStatus] = useState(undefined);
    const [currentReceiptValue, setCurrentReceiptValue] = useState(0);
    const [receiptPhotoValue, setReceiptPhotoValue] = useState("");
    const [totalCashback, setTotalCashback] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);
    const [acceptedReceipts, setAcceptedReceipts] = useState(0);
    const [deniedReceipts, setDeniedReceipts] = useState(0);
    const [pendingReceipts, setPendingReceipts] = useState(0);
    const [searchFilter, setSearchFilter] = useState("");

    async function getReceipts() {
        try {
            setLoading(true);
            let URL = filter
                ? `api/v1/receipts?sortBy=-createdAt&pageNumber=${currentPage}&status=${filter}`
                : `api/v1/receipts?sortBy=-createdAt&pageNumber=${currentPage}`;

            URL = searchFilter
                ? `api/v1/receipts?sortBy=-createdAt&pageNumber=${currentPage}&status=${filter}&customerName=${searchFilter}`
                : URL;

            const response = await api.get(URL, {
                headers: { Authorization: token },
            });
            setLoading(false);
            setCurrentPage(response.data.page);
            setReceipts(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    const getTotalCashback = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/total-cashback`,
                {
                    headers: { Authorization: token },
                }
            );
            setLoading(false);
            setTotalCashback(response.data.totalCashback);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    async function listAll() {
        try {
            setLoading(true);
            const URL = `api/v1/receipts?sortBy=-createdAt&pageNumber=1`;
            const response = await api.get(URL, {
                headers: { Authorization: token },
            });
            setLoading(false);
            setCurrentPage(response.data.page);
            setReceipts(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    const getTotalReceiptsInfo = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/count`,
                {
                    headers: { Authorization: token },
                }
            );
            setLoading(false);
            setTotalReceipts(response.data.total);
            setAcceptedReceipts(response.data.accepted);
            setDeniedReceipts(response.data.denied);
            setPendingReceipts(response.data.pending);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    useEffect(() => {
        getReceipts();
        getTotalCashback();
        getTotalReceiptsInfo();
    }, []);

    useEffect(() => {
        getReceipts();
    }, [currentPage]);

    const handleChangeStatus = (id, status, value, receiptPhoto) => {
        setReceiptId(id);
        setCurrentStatus(status);
        setCurrentReceiptValue(value);
        setReceiptPhotoValue(receiptPhoto);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleCopyToClipboard = useCallback((code) => {
        navigator.clipboard.writeText(code);
        toast.info("Código copiado para a área de transferência.", {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    }, []);

    const getFilteredReceipts = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/receipts?sortBy=-createdAt&pageNumber=1&status=${filter}`,
                {
                    headers: { Authorization: token },
                }
            );
            setLoading(false);
            setCurrentPage(1);
            setReceipts(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    const handleFilterChange = ({ target }) => {
        setFilter(target.value);
    };

    const handleSearchFilterChange = ({ target }) => {
        setSearchFilter(target.value);
    };

    const getReceiptsByCustomerName = async () => {
        setLoading(true);
        const URL = filter
            ? `api/v1/receipts?sortBy=-createdAt&pageNumber=${currentPage}&status=${filter}&customerName=${searchFilter}`
            : `api/v1/receipts?sortBy=-createdAt&pageNumber=${currentPage}&customerName=${searchFilter}`;

        const response = await api.get(URL, {
            headers: { Authorization: token },
        });
        setLoading(false);
        setCurrentPage(response.data.page);
        setReceipts(response.data.data);
        setTotalPages(response.data.pages);
    };

    const handleListAllReceipts = async () => {
        setSearchFilter("");
        setFilter("");
        listAll();
    };

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="ListReceiptsWrapper">
                <p className="promoHeader">Notas Fiscais Cadastradas</p>
                <hr />
                <div className="info-receipts">
                    <div className="card">
                        <div className="icon-wrapper primary-blue">
                            <IoReceiptOutline />
                        </div>
                        <div className="card-info">
                            <span className="primary-blue">
                                {totalReceipts}
                            </span>
                            <h5>Notas cadastradas</h5>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon-wrapper green">
                            <IoCashOutline />
                        </div>
                        <div className="card-info">
                            <span className="green">R$ {totalCashback}</span>
                            <h5>Cashback atribuído</h5>
                        </div>
                    </div>
                </div>
                <div className="info-receipts-status">
                    <div className="card">
                        <div className="icon-wrapper light-green">
                            <MdOutlineDone />
                        </div>
                        <div className="card-info">
                            <span className="light-green">
                                {acceptedReceipts}
                            </span>
                            <h6>Aceitas</h6>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon-wrapper red">
                            <FaTimes />
                        </div>
                        <div className="card-info">
                            <span className="red">{deniedReceipts}</span>
                            <h6>Recusadas</h6>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon-wrapper yellow">
                            <MdAccessTime />
                        </div>
                        <div className="card-info">
                            <span className="yellow">{pendingReceipts}</span>
                            <h6>Pendentes</h6>
                        </div>
                    </div>
                </div>
                <div className="link-report-wrapper">
                    <Link to={"/admin/relatorios-notas-fiscais"}>
                        Ir para Relatório de Notas Fiscais
                    </Link>
                </div>
                <div className="search-wrapper">
                    <input
                        type="search"
                        name="search"
                        id="search"
                        value={searchFilter}
                        onChange={handleSearchFilterChange}
                    />
                    <button
                        onClick={getReceiptsByCustomerName}
                        type="button"
                        className="btn-search"
                    >
                        Buscar por nome
                    </button>
                    <button
                        onClick={handleListAllReceipts}
                        type="button"
                        className="btn-list-all"
                    >
                        Mostrar todas
                    </button>
                </div>
                <div className="filter-wrapper">
                    <div className="filter">
                        <input
                            type="radio"
                            name="status"
                            id="all"
                            value={""}
                            checked={filter === ""}
                            onChange={handleFilterChange}
                        />
                        <label htmlFor="all">Mostrar todas</label>
                    </div>
                    <div className="filter">
                        <input
                            type="radio"
                            name="status"
                            id="accept"
                            checked={filter === "ACEITA"}
                            onChange={handleFilterChange}
                            value={"ACEITA"}
                        />
                        <label htmlFor="accept">Aceita</label>
                    </div>
                    <div className="filter">
                        <input
                            type="radio"
                            name="status"
                            id="denied"
                            value={"RECUSADA"}
                            checked={filter === "RECUSADA"}
                            onChange={handleFilterChange}
                        />
                        <label htmlFor="denied">Recusadas</label>
                    </div>
                    <div className="filter">
                        <input
                            type="radio"
                            name="status"
                            id="pending"
                            checked={filter === "PENDENTE"}
                            onChange={handleFilterChange}
                            value={"PENDENTE"}
                        />
                        <label htmlFor="pending">Pendentes</label>
                    </div>

                    <button onClick={getFilteredReceipts}>Filtrar</button>
                </div>
                {!loading && receipts.length > 0 && (
                    <div className="listPromoCodesMobileWrapper">
                        <table className="listPromoCodes">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th scope="col">Data da compra</th>
                                    <th scope="col">Usuário</th>
                                    <th>Estabelecimento</th>
                                    <th>Valor</th>
                                    <th>% Cashback</th>
                                    <th>Cashback</th>
                                    <th>Alterado por</th>
                                    <th>Anexo</th>
                                    <th>Status</th>
                                    <th>Detalhes</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {receipts?.map((receipt) => (
                                    <tr key={receipt._id}>
                                        <td>
                                            {new Date(
                                                receipt.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>
                                        {/* <td
                                            title={receipt.code}
                                            className="col-receipt-code"
                                        >
                                            {receipt.code
                                                ? `${receipt.code.slice(
                                                      0,
                                                      8
                                                  )}...`
                                                : "-"}
                                            {receipt.code && (
                                                <span
                                                    onClick={() =>
                                                        handleCopyToClipboard(
                                                            receipt.code
                                                        )
                                                    }
                                                >
                                                    Copiar
                                                </span>
                                            )}
                                        </td> */}
                                        <td>
                                            <Link
                                                to={`/clientes/${receipt.customer._id}/notas-fiscais`}
                                            >
                                                {receipt.customer.name}
                                            </Link>
                                        </td>
                                        <td>{receipt.store}</td>
                                        <td>
                                            {receipt.value
                                                ? receipt.value.toLocaleString(
                                                      "pt-br",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      },
                                                      {
                                                          style: "currency",
                                                          currency: "BRL",
                                                      }
                                                  )
                                                : "-"}
                                        </td>
                                        <td>
                                            {" "}
                                            {receipt.cashbackPercentage
                                                ? receipt.cashbackPercentage.toLocaleString(
                                                      "pt-br",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      },
                                                      {
                                                          style: "currency",
                                                          currency: "BRL",
                                                      }
                                                  )
                                                : "-"}
                                        </td>
                                        <td>
                                            {" "}
                                            {receipt.cashback
                                                ? receipt.cashback.toLocaleString(
                                                      "pt-br",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      },
                                                      {
                                                          style: "currency",
                                                          currency: "BRL",
                                                      }
                                                  )
                                                : "-"}
                                        </td>
                                        <td>
                                            {" "}
                                            {receipt.statusChangedBy
                                                ? receipt.statusChangedBy
                                                : "-"}
                                        </td>
                                        <td>
                                            {" "}
                                            {receipt.receiptPhoto ? (
                                                <a
                                                    href={convertLinkToHttpsAndCDN(
                                                        receipt.receiptPhoto
                                                    )}
                                                    download={true}
                                                >
                                                    Anexo
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                        <td>{receipt.status}</td>
                                        <td>
                                            {receipt.denyDetails
                                                ? receipt.denyDetails
                                                : "-"}
                                        </td>
                                        <td>
                                            <button
                                                disabled={
                                                    receipt.status !==
                                                    "PENDENTE"
                                                }
                                                className="buttonAlter"
                                                onClick={() =>
                                                    handleChangeStatus(
                                                        receipt._id,
                                                        receipt.status,
                                                        receipt.value,
                                                        receipt.receiptPhoto
                                                    )
                                                }
                                            >
                                                Alterar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {!loading && receipts.length === 0 && (
                    <div className="text-center">
                        <h6 style={{ color: "#00000080" }}>
                            Nenhum resultado encontrado para esse filtro!
                        </h6>
                    </div>
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                    showFirstAndLast={true}
                />
            </div>
            {showModal && (
                <ReceiptStatusModal
                    id={receiptId}
                    onCancel={closeModal}
                    currentStatus={currentStatus}
                    receiptValue={currentReceiptValue}
                    getReceipts={getReceipts}
                    receiptPhoto={receiptPhotoValue}
                />
            )}
        </>
    );
};

export default ListCustomersReceipts;
