import {
    useHistory
  } from "react-router-dom";
  import {
    useState
  } from "react";
  import api from "../../services/api";
  import {
    getToken
  } from "../../services/auth";


  import Menu from "../../components/Menu/Menu";
  import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'

  import TableMarket from '../../components/market/index'



  import "react-toastify/dist/ReactToastify.css";
  import {
    toast
  } from "react-toastify";

  import './style.scss'




  export default function CreateMarket() {

    const token = getToken();
    const history = useHistory();
    const [market, setMarket] = useState("")
    const seguimento ={
      name: market
    }

    async function Create(e){
        e.preventDefault();

       try {

           const response = await api.post('/api/v1/segments/', seguimento, {
               headers: {Authorization: token},


           })
           console.log(response)
           setMarket('')
           toast.success("Seguimento Cadastrado", {
            position: toast.POSITION.TOP_RIGHT,
          });
       } catch (error) {
        toast.error("algo deu errado, tente novamente", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(error);
       }
    }



    return (
      <div className="ViewMarket">

     <div className="menu">
     <Menu
         title1="Dashboard"
         icon1={
           <img src={logo} alt="" with="25" height="25" />
         }
         onClick1={() => history.push("/admin/dash")}


        title2="Cadastrar Empresa"
        icon2={
          <img src={logo} alt="" with="25" height="25" />
        }
        onClick2={() => history.push("/admin/cadastro")}


        title3="Saques"
        icon3={<img src={logo} alt="" with="25" height="25" />}
        onClick3={() => history.push("/admin/saques")}

        title4="Depositos"
        icon4={<img src={logo} alt="" with="25" height="25" />}
        onClick4={() => history.push("/admin/depositos")}

      />
     </div>
     <div className="contentMarket">

        <div className="createMarket">
        <form className="Marketform" onSubmit={Create}>
        <label className="labelMarket">
            Criar Seguimento:
        </label>

            <input
            className="inputMarket"
            required
            placeholder="Digite um seguimento"
            onChange={(e) => setMarket(e.target.value)}
            value={market}

            >
        </input>
        <button className="submitMarket" type="submit">
        Cadastrar
        </button>
        </form>


        </div>

  </div>
          <div className="tableMarket">

               <TableMarket/>

         </div>

  </div>
    );
  }
