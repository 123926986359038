import React, { useCallback, useState } from "react";
import {} from "../..";
import "./style.css";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

const defaultFormData = {
    cashback: "",
    password: "",
};

const ConfirmationDeleteCouponModal = ({ onCancel, onConfirm, coupon }) => {
    const handleConfirmation = useCallback(() => {
        onCancel();
        onConfirm();
    }, []);

    return (
        <div className="ConfirmationDeleteCouponModal">
            <form className="bonus-form">
                <h5 className="text-center mb-3">Deseja realmente excluir?</h5>
                {
                    <p>
                        <span>Descrição:</span> {coupon?.description || "-"}
                    </p>
                }
                <p>
                    <span>Desconto:</span> {coupon?.discountPercentage}%
                </p>
                <p>
                    <span>Empresa:</span> {coupon?.store?.name}
                </p>
                <p>
                    <span>Expira em:</span>{" "}
                    {new Date(
                        coupon?.expirationDate.slice(0, 10)
                    ).toLocaleDateString(undefined, {
                        timeZone: "UTC",
                    })}
                </p>

                <button
                    tabIndex={1}
                    className="btn btn-danger mt-4"
                    type="button"
                    onClick={onCancel}
                >
                    Cancelar
                </button>
                <button
                    className="btn btn-success mt-2"
                    type="button"
                    onClick={handleConfirmation}
                >
                    Sim, excluir!
                </button>
            </form>
        </div>
    );
};

export default ConfirmationDeleteCouponModal;
