import { useState } from "react";
import { checkMinimumAge } from "../utils/dates";

const validateCPF = (cpf) => {
  const digits = cpf.replace(/\D/g, "");

  let sum = 0;
  let rest;

  if (digits === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(digits.substring(i - 1, i)) * (11 - i);

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;

  if (rest !== parseInt(digits.substring(9, 10))) return false;

  sum = 0;

  for (let j = 1; j <= 10; j++)
    sum = sum + parseInt(digits.substring(j - 1, j)) * (12 - j);

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;

  if (rest !== parseInt(digits.substring(10, 11))) return false;

  return true;
};

function validarCNPJ(numeros) {
  let digitos_12 = numeros.split("-")[0];
  let digitosVerificadores = numeros.split("-")[1];

  let digitos = digitos_12.replace(/\D/g, "");

  digitos = digitos.split("");
  digitos.forEach((element, index) => {
    digitos[index] = parseInt(element);
  });


  let resultado = 0;
  let modulo;
  digitos[0] = digitos[0] * 5;
  digitos[1] = digitos[1] * 4;
  digitos[2] = digitos[2] * 3;
  digitos[3] = digitos[3] * 2;

  digitos[4] = digitos[4] * 9;
  digitos[5] = digitos[5] * 8;
  digitos[6] = digitos[6] * 7;
  digitos[7] = digitos[7] * 6;

  digitos[8] = digitos[8] * 5;
  digitos[9] = digitos[9] * 4;
  digitos[10] = digitos[10] * 3;
  digitos[11] = digitos[11] * 2;

  let soma = 0;

  for (let i = 0; i <= digitos.length - 1; i++) soma += digitos[i];

  modulo = soma % 11;

  if (modulo < 2) resultado = 0;
  else {
    resultado = 11 - modulo;
  }

  let o = digitos_12.replace(/\D/g, "");
  o = o.split("");
  o.forEach((element, index) => {
    o[index] = parseInt(element);
  });

  o.push(resultado);

  o[0] = o[0] * 6;
  o[1] = o[1] * 5;
  o[2] = o[2] * 4;
  o[3] = o[3] * 3;

  o[4] = o[4] * 2;
  o[5] = o[5] * 9;
  o[6] = o[6] * 8;
  o[7] = o[7] * 7;

  o[8] = o[8] * 6;
  o[9] = o[9] * 5;
  o[10] = o[10] * 4;
  o[11] = o[11] * 3;
  o[12] = o[12] * 2;

  let pSoma = 0;
  for (let j = 0; j <= o.length - 1; j++) pSoma += o[j];

  const modulo2 = pSoma % 11;
  let segundoDigito;
  if (modulo2 < 2) segundoDigito = 0;
  else {
    segundoDigito = 11 - modulo2;
  }

  return (
    resultado === parseInt(digitosVerificadores[0]) &&
    segundoDigito === parseInt(digitosVerificadores[1])
  );
}

const formatRandomKey = (value) => {
  value = value.toLowerCase();
  return value
    .replace(
      /([a-z0-9]{8})([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{12})/,
      "$1-$2-$3-$4-$5"
    )
    .replace(/(-[a-z0-9]{12})[a-z0-9]+?$/, "$1");
};

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Informe um e-mail válido.",
  },
  number: {
    regex: /^\d+$/,
    message: "Utilize somente números.",
  },
  cpf: {
    regex: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    validate: validateCPF,
    message: "Informe um CPF válido.",
  },
  phone: {
    regex: /^\([1-9]{2}\) (?:[2-8]|9 [1-9])[0-9]{3}-[0-9]{4}$/,
    message: "Informe um nº de celular válido.",
  },
  password: {
    regex: /^[a-zA-Z0-9*.!@#$%^&(){}[\]:;<>,.?/~_+-]{6,}$/,
    message: "Informe uma senha de no mínimo 6 caracteres",
  },
  date: {
    regex:
      /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/,
    message: "Informe uma Data de Nascimento válida.",
  },
  credential: {
    regex: /^[0-9]{11,11}$/,
    message: "E-mail ou telefone inválido.",
  },
  cnpj: {
    regex: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
    validate: validarCNPJ,
    message: "CNPJ inválido",
  },
  random: {
    regex: /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
    message: "Chave aleatória inválida",
  },
};

const useForm = (type) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  function validate(value) {
    if (type === false) return true;
    if (type === "date" && value.length === 0) {
      setError("Preencha um valor válido.");
    } else if (value.length === 0) {
      setError("Preencha um valor.");
      return false;
    } else if (type === "cpf" && value === "___.___.___-__") {
      setError("Preencha um valor.");
      return false;
    } else if (type === "cpf" && !types["cpf"].validate(value)) {
      setError("CPF inválido");
      return false;
    } else if (type === "cnpj" && !types["cnpj"].validate(value)) {
      setError("CNPJ inválido");
      return false;
    } else if (type === "phone" && value === "(__) _ ____-____") {
      setError("Preencha um valor.");
      return false;
    } else if (type === "credential") {
      if (
        !types["email"].regex.test(value) &&
        !types["credential"].regex.test(value)
      ) {
        setError("Telefone ou email inválido");
        return false;
      }
    } else if (type === "date") {
      if (!checkMinimumAge(value)) {
        setError("Preencha um valor válido");
        return false;
      }
      const values = value.split("-");
      const date = `${values[2]}/${values[1]}/${values[0]}`;
      if (!types["date"].regex.test(date)) {
        setError(types[type].message);
        return false;
      }

      if (types[type] && !types[type].regex.test(date)) {
        setError(types[type].message);
        return false;
      }
      return true;
    } else if (types[type] && !types[type].regex.test(value)) {
      setError(types[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  }

  function onChange({ target }) {
    //if (error) validate(target.value);
    if (error) setError(null);
    if (type === "random") setValue(formatRandomKey(target.value));
    else setValue(target.value);
  }

  return {
    value,
    error,
    setValue,
    setError,
    onChange,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
