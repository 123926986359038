import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";

import { FaFileExport } from "react-icons/fa";

import api from "../../services/api";

const StoreSeparateSalesPage = () => {
    const history = useHistory();

    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [separateSales, setSeparateSales] = useState([]);
    const [totalSeparateSales, setTotalSeparateSales] = useState(0);
    const token = getToken();

    const getAllSeparateSalesRegistered = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/total-separate-sales-by-store`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }
            console.log(response.data);
            setSeparateSales(response.data);
            setTotalSeparateSales(getTotalSepateSales(response.data));
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getTotalSepateSales = (separateSales) => {
        const totalSeparateSales = separateSales.reduce((total, item) => {
            return total + item.totalSeparateSalesRegistered;
        }, 0);

        return totalSeparateSales;
    };

    useEffect(() => {
        getAllSeparateSalesRegistered();
    }, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                <div className="row">
                    {!loading && (
                        <div className="listPromoCodesMobileWrapper">
                            <div>
                                <h5>
                                    Total Vendas Avulsas cadastradas:{" "}
                                    {totalSeparateSales}
                                </h5>
                            </div>
                            <table className="listPromoCodes">
                                <thead>
                                    <tr>
                                        <th scope="col">Empresa:</th>
                                        <th scope="col">
                                            Num. Vendas Avulsas cadastradas:
                                        </th>
                                        <th scope="col">
                                            Última venda cadastrada em:
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {separateSales?.map((separateSale) => (
                                        <tr key={separateSale._id}>
                                            <td className="col-receipt-code">
                                                {separateSale.storeName}
                                            </td>
                                            <td>
                                                {
                                                    separateSale.totalSeparateSalesRegistered
                                                }
                                            </td>
                                            <td>
                                                {new Date(
                                                    separateSale.lastSeparatedSaleDate
                                                ).toLocaleString("pt-br")}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <hr />
            </div>
        </div>
    );
};

export default StoreSeparateSalesPage;
