import React, { useState } from "react";
import "./style.scss";

import CurrencyInput from "../../components/CurrencyInput";
import CurrencyInputNumber from "../../components/CurrencyInputNumber";
import CurrencyInputTel from "../../components/CurrencyInputTel";

const TestingComponentsPage = () => {
    const [receiptValue, setReceiptValue] = useState(0);

    const handleChange = (event) => {
        const newValue = Number(event.target.value.replace(/[^\d]/g, "")) / 100;
        setReceiptValue(newValue);
    };

    const handleBlur = (event) => {
        const newValue = Number(event.target.value.replace(/[^\d]/g, "")) / 100;
        setReceiptValue(newValue);
    };

    const [receiptValueNumber, setReceiptValueNumber] = useState(0);

    const handleChangeNumber = (event) => {
        // const newValue = Number(event.target.value.replace(/[^\d]/g, "")) / 100;
        setReceiptValueNumber(event.target.value);
    };

    const handleBlurNumber = (event) => {
        // const newValue = Number(event.target.value.replace(/[^\d]/g, "")) / 100;
        setReceiptValueNumber(event.target.value);
    };

    return (
        <div className="NewReceiptModal">
            <CurrencyInput
                label="Valor da nota"
                onChange={handleChange}
                onBlur={handleBlur}
                value={receiptValue}
            />
            <hr />
            <div>
                <label>Numero</label>
                <input
                    type="number"
                    min="1"
                    step="0.01"
                    placeholder="Input number"
                    value={receiptValueNumber}
                    onChange={handleChangeNumber}
                    onBlur={handleBlurNumber}
                />
            </div>
            <hr />
            <CurrencyInputTel
                label="Valor da nota TEL"
                onChange={handleChange}
                onBlur={handleBlur}
                value={receiptValue}
            />
        </div>
    );
};

export default TestingComponentsPage;
