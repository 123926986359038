import React from "react";

import './style.scss'

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import api from "../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function ResponsiveDialog({
  token,
  setOpen,
  open,
  id,
  onConfirm,
}) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
      fontSize: 40,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  async function StatusDeposit(e) {
    e.preventDefault();
    const data = {
      password,
    };
    try {
      await api.patch(`api/v1/withdrawals/${id}/${age}`, data, {
        headers: { Authorization: token },
      });

      setOpen(false);
      onConfirm();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Alterar status de solicitação de saque?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Realize contato com o cliente e guarde o comprovante de envio!!
          </DialogContentText>
          <DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Senha do admin
                </InputLabel>
                <Input
                  type="password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  status de deposito
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange}
                >
                  <MenuItem value={"done"}>Concluido</MenuItem>
                  <MenuItem value={"pending"}>Pendente</MenuItem>
                </Select>
              </FormControl>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="cancel">Cancelar</button>

          <button onClick={StatusDeposit} className="confirm">Confirmar</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
