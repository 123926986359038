import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./style.scss";

import Button from "../../button/index";

import Arrow from "../../../assets/arrow-order.png";
import useSortedData from "../../../hooks/useSortedData";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const prepareDataToSort = (data) => {
    const newData = data.map((row) => {
        return {
            address: row.address,
            _id: row._id,
            name: row.name,
            cnpj: row.cnpj,
            managerCpf: row.managerCpf,
            managerName: row.managerName,
            whatsapp: row.whatsapp,
            createdAt: row.createdAt,
        };
    });

    return newData;
};

const StoresTable = ({ stores, loading, countStores }) => {
    const [, setId] = useState(null);
    const [sortedField, setSortedField] = useState("createdAt");
    const [descData, setDescData] = useState(true);
    const { items, requestSort } = useSortedData(prepareDataToSort(stores));

    const history = useHistory();

    const ID_EMPRESA = "id";
    const handleChangeStatus = (id) => {
        setId(id);
        localStorage.setItem(ID_EMPRESA, id);
        history.push("/admin/empresa");
    };

    const handleClick = (field) => {
        if (field === sortedField) {
            setDescData((prev) => !prev);
            requestSort(field);
            return;
        }

        setSortedField(field);
        setDescData(false);
        requestSort(field);
    };

    if (countStores === 0) {
        return (
            <div className="TableWrapper mock-table noContent">
                <div className="container">
                    <table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                    >
                        <thead>
                            <tr classNam="thead-dark">
                                <th
                                    scope="col"
                                    className="SellHead"
                                    align="center"
                                >
                                    Ainda não existem empresas cadastradas.
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        );
    } else
        return (
            <div className="TableWrapperAdmin">
                <h5 className="titleNumber">
                    Número de Empresas: {countStores}
                </h5>
                <div className="container">
                    <table className="table">
                        <thead className="thead-dark">
                            <tr className="TableRowHead">
                                <th
                                    className="CellHead"
                                    align="center"
                                    scope="col"
                                    onClick={() => handleClick("createdAt")}
                                >
                                    <span>
                                        Data do cadastro:{" "}
                                        {sortedField === "createdAt" && (
                                            <img
                                                className={
                                                    descData ? "desc" : "asc"
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() => handleClick("name")}
                                >
                                    <span>
                                        Nome da empresa:{" "}
                                        {sortedField === "name" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() => handleClick("cpf")}
                                >
                                    <span>
                                        CNPJ:{" "}
                                        {sortedField === "cpf" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() => handleClick("value")}
                                >
                                    <span>
                                        CPF do Responsavel:{" "}
                                        {sortedField === "value" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() => handleClick("pix")}
                                >
                                    <span>
                                        Nome do Responsável:{" "}
                                        {sortedField === "pix" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() =>
                                        handleClick("phoneToContact")
                                    }
                                >
                                    <span>
                                        whatsApp:{" "}
                                        {sortedField === "phoneToContact" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                                <th
                                    scope="col"
                                    className="CellHead"
                                    align="center"
                                    onClick={() => handleClick("status")}
                                >
                                    <span>
                                        Informações:{" "}
                                        {sortedField === "status" && (
                                            <img
                                                className={
                                                    descData ? "desc" : ""
                                                }
                                                src={Arrow}
                                                alt="arrow"
                                            />
                                        )}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items &&
                                items.map((row) => (
                                    <StyledTableRow
                                        key={row._id}
                                        className={"thead-darkSecond"}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <th
                                            component="th"
                                            scope="row"
                                            align="center"
                                        >
                                            {new Date(
                                                row.createdAt
                                            ).toLocaleString("pt-br")}
                                        </th>
                                        <th align="center">{row.name}</th>

                                        <th align="center">{row.cnpj}</th>

                                        <th align="center">{row.managerCpf}</th>
                                        <th align="center">
                                            {row.managerName}
                                        </th>
                                        <th align="center">
                                            {row.whatsapp || "Não Consta"}
                                        </th>

                                        <th align="center">
                                            <Button
                                                onClick={() =>
                                                    handleChangeStatus(row._id)
                                                }
                                            >
                                                {" "}
                                                Ver Mais{" "}
                                            </Button>
                                        </th>
                                    </StyledTableRow>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
};

export default StoresTable;
