import React from "react";
import "./style.scss";

const Pagination = ({
    currentPage,
    totalPages,
    paginate,
    showFirstAndLast,
}) => {
    return (
        <div
            className={totalPages > 1 ? "Pagination" : "Pagination center-text"}
        >
            {totalPages > 1 && (
                <>
                    {showFirstAndLast && (
                        <button
                            className="btn-pagination"
                            disabled={currentPage <= 1}
                            onClick={() => paginate(1)}
                        >
                            Ir para a primeira
                        </button>
                    )}
                    <button
                        className="btn-pagination"
                        disabled={currentPage <= 1}
                        onClick={() => paginate(currentPage - 1)}
                    >
                        Anterior
                    </button>
                </>
            )}
            {totalPages > 0 && (
                <span
                    style={{ fontWeight: "700", fontSize: "1.2em" }}
                >{` Página ${currentPage} de ${totalPages} `}</span>
            )}
            {totalPages > 1 && (
                <>
                    <button
                        className="btn-pagination"
                        disabled={currentPage >= totalPages}
                        onClick={() => paginate(currentPage + 1)}
                    >
                        Próxima
                    </button>
                    {showFirstAndLast && (
                        <button
                            className="btn-pagination"
                            disabled={currentPage >= totalPages}
                            onClick={() => paginate(totalPages)}
                        >
                            Ir para a última
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default Pagination;
