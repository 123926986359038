import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

import "./style.css";

import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";

const ListPromoCode = () => {
    const history = useHistory();
    const token = getToken();

    const [promoCode, setPromoCode] = useState([]);

    const [currentPageStores, setCurrentPageStores] = useState(1);
    const [totalPagesStores, setTotalPagesStores] = useState(1);

    async function getPromoCodes() {
        try {
            const response = await api.get(
                `api/v1/promo-codes?pageNumber=${currentPageStores}`,
                {
                    headers: { Authorization: token },
                }
            );
            setPromoCode(response.data.data);
            setTotalPagesStores(response.data.pages);
            console.log(response.data);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    async function getFilteredPromoCodes() {
        try {
            const response = await api.get(
                `api/v1/promo-codes?isActive=true&pageNumber=${currentPageStores}`,
                {
                    headers: { Authorization: token },
                }
            );
            setPromoCode(response.data.data);
            console.log(response.data);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    const setPromoCodeStatusToInactive = async (code) => {
        try {
            const response = await api.patch(
                `api/v1/promo-codes/${code}/inactive`,
                { headers: { Authorization: token } }
            );
            console.log(response);
            getPromoCodes();
        } catch (error) {
            console.log(error);
        }
    };

    const setPromoCodeStatusToActive = async (code) => {
        try {
            const response = await api.patch(
                `api/v1/promo-codes/${code}/active`,
                { headers: { Authorization: token } }
            );
            console.log(response);
            getPromoCodes();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPromoCodes();
    }, [currentPageStores]);

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="promoCodeWrapper">
                <p className="promoHeader">Códigos Promocionais Cadastrados</p>
                <hr />

                <div className="codesFilter">
                    <button
                        onClick={() => {
                            getPromoCodes();
                        }}
                    >
                        Mostrar Todos os Códigos
                    </button>
                    <button
                        onClick={() => {
                            getFilteredPromoCodes();
                        }}
                    >
                        Filtrar por: Códigos Ativos
                    </button>
                </div>
                <div className="listPromoCodesMobileWrapper">
                    <table className="listPromoCodes tablePromo">
                        <thead>
                            <tr>
                                <th>Data de Criação</th>
                                <th>Código</th>
                                <th>Status</th>
                                <th>Ações</th>
                                <th>Detalhes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {promoCode?.map((item) => {
                                var status = "ATIVO";

                                if (item.isActive) {
                                    status = "ATIVO";
                                } else {
                                    status = "INATIVO";
                                }
                                console.log(item);
                                return (
                                    <tr key={item._id}>
                                        <td>
                                            {new Date(
                                                item.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>
                                        <td>{item.code}</td>
                                        <td>{status}</td>
                                        <td>
                                            {item.isActive ? (
                                                <button
                                                    className="actionToggleBTN-Desativar"
                                                    onClick={() =>
                                                        setPromoCodeStatusToInactive(
                                                            item.code
                                                        )
                                                    }
                                                >
                                                    DESATIVAR
                                                </button>
                                            ) : (
                                                <button
                                                    className="actionToggleBTN-Ativar"
                                                    onClick={() =>
                                                        setPromoCodeStatusToActive(
                                                            item.code
                                                        )
                                                    }
                                                >
                                                    ATIVAR
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <Link
                                                className="seeMoreBTN"
                                                to={`/admin/promo-codes/${item.code}/detalhes?bonus=${item.bonusToCustomers}`}
                                            >
                                                EXIBIR
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    currentPage={currentPageStores}
                    totalPages={totalPagesStores}
                    paginate={setCurrentPageStores}
                />
            </div>
        </>
    );
};

export default ListPromoCode;
