export const getName = () => localStorage.getItem(NAME_USER);
export const getEmail = () => localStorage.getItem(EMAIL_USER);
export const getPhone = () => localStorage.getItem(PHONE_USER);

export const EMAIL_USER = "EmailUser";
export const PHONE_USER = "PhoneUser";
export const NAME_USER = "NameUser";
export const loginData = (name, email, phone) => {
    localStorage.setItem(NAME_USER, name);
    localStorage.setItem(EMAIL_USER, email);
    localStorage.setItem(PHONE_USER, phone);
};
export const clear = () => localStorage.clear();
