
import React,{useState,useEffect,} from 'react'
import api from '../../services/api'

import {
    getToken
  } from "../../services/auth";

import './style.scss'

const TableMarket = () =>{
    const [segments, setSegments] = useState([]);
    const token = getToken();
    useEffect(() => {
        async function HandleSegments() {
          const response = await api
            .get("api/v1/segments", { headers: { Authorization: token } })
            .then((response) => setSegments(response.data));
          console.log(response);
        }
        HandleSegments();
        /* eslint-disable-next-line */
      }, []);
    return(
        <div className='container'>
            <div className='tableMarket'>
            <table className='contentTable'>
                <tr
                 className='labelTable'
                 align='center'
                 >
                    <th
                    className='label'
                    id='label'
                    align='center'
                    >
                        Nome do seguimento

                    </th>
                </tr>
                <tr
                className='seguimentName'
                align="center"
                >
                    {segments?.map((item) => (
                    <td
                    key={item._id}
                    className='name'
                    id='name'
                    align='center'
                    >{item.name}</td>
                  ))}

                </tr>

            </table>

            </div>

        </div>

    )

}

export default TableMarket;
