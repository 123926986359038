import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";

const MainCouponsPage = () => {
    const history = useHistory();

    useEffect(() => {}, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="container default-page">
                <div className="row mb-4">
                    <h3 className="text-center">Página Inicial de Cupons</h3>
                </div>
                <div className="row d-flex align-items-stretch groupItems">
                    <div className="col-6">
                        <div className="card card-links p-2">
                            <div className="card-body">
                                <h5 className="mb-4">
                                    <Link to={"/admin/novo-cupom"}>
                                        Criar Novo Cupom
                                    </Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card p-2">
                            <div className="card-body">
                                <h5 className="mb-4">
                                    <Link to={"/admin/lista-cupons"}>
                                        Visualizar Todos
                                    </Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex align-items-stretch groupItems mt-4">
                    <div className="col-6">
                        <div className="card card-links p-2">
                            <div className="card-body">
                                <h5 className="mb-4">
                                    <Link to={"/admin/cupons-cliente"}>
                                        Cupons Gerados
                                    </Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainCouponsPage;
