import { useState } from "react";
import "./style.scss";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FaUserLock } from "react-icons/fa";
import RedefinePasswordModal from "../../components/RedefinePasswordModal";
import { useEffect } from "react";

export default function SearchCustomer() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setIsLoading] = useState(false);
    const [showRedefineModal, setShowRedefineModal] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [customer, setCustomer] = useState({});
    const [level, setLevel] = useState(3);

    const handleRedefinePassword = async (credential, password) => {
        try {
            setIsLoading(true);

            await api.post(
                "/api/v1/auth/temp-password",
                { credential, password },
                {
                    headers: { Authorization: token },
                }
            );

            toast.success("Senha redefinida com sucesso!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log({ error });
            const { message } = error?.response?.data;

            toast.error(`Erro: ${message}`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowRedefinePasswordModal = async (customer) => {
        setCustomer({ name: customer.name, email: customer.email });
        setShowRedefineModal(true);
    };

    async function handleSearchCustomer() {
        try {
            setIsLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/customers/search?name=${searchName}`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log(response);
            setSearchResults(response.data.result);
            setSearchName("");
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function getLoggedUser() {
            try {
                setIsLoading(true);
                const response = await api.get(`api/v1/auth/me`, {
                    headers: { Authorization: token },
                });

                setLevel(response.data.level);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }
        getLoggedUser();
    }, [token]);

    return (
        <div>
            <div className="container default-page">
                <div className="quick-access-buttons">
                    <Link
                        to="/admin/dash"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    {/* <Link
                        to="/admin/novo-cupom"
                        className="btn btn-primary"
                        role="button"
                        disabled={true}
                    >
                        Ir para Busca Usuários Empresas
                    </Link> */}
                </div>
                <p className="promoHeader mt-4">Busca Usuários - Clientes</p>

                {isLoading && <Loader />}
                <div className="tabela mt-4">
                    <div className="filter-wrapper">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Digite o nome completo ou parte do nome do usuário"
                            id="formGroupExampleInput"
                            value={searchName}
                            onChange={({ target }) =>
                                setSearchName(target.value)
                            }
                        />
                        <button
                            className="btn btn-primary"
                            onClick={handleSearchCustomer}
                            disabled={searchName.length < 3}
                        >
                            Pesquisar
                        </button>
                    </div>
                    {searchResults?.length > 0 && (
                        <div className="mt-4">
                            <h6>
                                {searchResults.length} usuários encontrado(s)
                            </h6>
                        </div>
                    )}
                    {searchResults?.length > 0 && (
                        <table className="table table-striped mt-4">
                            <thead>
                                <tr>
                                    <th scope="col">Nome</th>

                                    <th scope="col">Telefone</th>
                                    <th scope="col">CPF</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Último Login</th>

                                    {level === 1 && (
                                        <th scope="col">
                                            <FaUserLock />
                                        </th>
                                    )}
                                    {/* <th scope="col">
                                        <BiDetail />
                                    </th> */}
                                    {/* <th scope="col">
                                        <FaLock />
                                         {<FaLockOpen />}
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults?.map((customer) => (
                                    <tr key={customer._id}>
                                        <td scope="row">{customer.name}</td>
                                        <td>{customer.phone}</td>
                                        <td>{customer.cpf}</td>
                                        <td
                                            style={
                                                customer?.email?.length > 20
                                                    ? { fontSize: "12px" }
                                                    : { fontSize: "14px" }
                                            }
                                        >
                                            {customer.email}
                                        </td>
                                        <td>
                                            R${" "}
                                            {customer.wallet.balance
                                                .toFixed(2)
                                                .toLocaleString(
                                                    "pt-br",
                                                    {
                                                        minimumFractionDigits: 2,
                                                    },
                                                    {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }
                                                )}
                                        </td>

                                        <td>
                                            {new Date(
                                                customer.lastLogin
                                            ).toLocaleString("pt-br")}
                                        </td>
                                        {level === 1 && (
                                            <td>
                                                <button
                                                    className="btn btn-info btn-lucrei-alt"
                                                    onClick={() =>
                                                        handleShowRedefinePasswordModal(
                                                            customer
                                                        )
                                                    }
                                                >
                                                    Redefinir senha
                                                </button>
                                            </td>
                                        )}
                                        {/* <td>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    console.log("Clickou")
                                                }
                                            >
                                                Ver mais detalhes
                                            </button>
                                        </td> */}
                                        {/* <td>

                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    console.log("Clickou")
                                                }
                                            >
                                                Bloquear acesso
                                            </button>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {searchResults?.length === 0 && (
                        <p className="text-center mt-4">
                            Nenhum resultado foi encontrado.
                        </p>
                    )}
                </div>
            </div>

            {showRedefineModal && (
                <RedefinePasswordModal
                    onCancel={() => setShowRedefineModal(false)}
                    onConfirm={handleRedefinePassword}
                    customer={customer}
                />
            )}
        </div>
    );
}
