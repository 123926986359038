const data = [
    /* 1 */ {
        // _id: ObjectId("62165f57b3ec5d6f84e77dfc"),
        email: "cristiano69socorro@gmail.com",
        phone: "85994387599",
        // createdAt: new Date("2022-02-23T16:22:47.467Z"),
        updatedAt: new Date("2023-05-26T12:13:27.149Z"),
        store: {
            name: "Paraíso do Bebê",
            city: "Capistrano",
        },
    },

    /* 2 */ {
        // _id: ObjectId("626af667edb2154b9e293040"),
        email: "deivymatos@hotmail.com",
        phone: "85992634594",
        // createdAt: ISODate("2022-04-28T20:17:43.412Z"),
        updatedAt: new Date("2023-05-26T01:08:28.766Z"),
        store: {
            name: "EMPORIO BURGUER",
            city: "Capistrano",
        },
    },

    /* 3 */ {
        // _id: ObjectId("633ecdda3fb78f98d63f2e2b"),
        email: "paraisobebecapistrano@gmail.com",
        phone: "85994387578",
        // createdAt: new Date("2022-10-06T12:45:14.270Z"),
        updatedAt: new Date("2023-05-25T19:42:59.301Z"),
        store: {
            name: "Paraiso do Bebê Capistrano ",
            city: "Capistrano",
        },
    },

    /* 4 */ {
        // _id: ObjectId("61b118e284e337c4c428cf23"),
        email: "ronysgigante@gmail.com",
        phone: "85999263973",
        // createdAt: ISODate("2021-12-08T20:43:14.301Z"),
        updatedAt: new Date("2023-05-24T14:51:56.037Z"),
        store: {
            name: "Restaurante Ana do Paulista",
            city: "Capistrano",
        },
    },

    /* 5 */ {
        // _id: ObjectId("6228a6688cb8c22c0fe67844"),
        email: "cassiafelix12@hotmail.com",
        phone: "85992524074",
        // createdAt: new Date("2022-03-09T13:06:48.613Z"),
        updatedAt: new Date("2023-05-16T18:28:50.904Z"),
        store: {
            name: "E.E.I.E.F Cantinho da Criança",
            city: "Capistrano",
        },
    },

    /* 6 */ {
        // _id: ObjectId("629a3c6e206fffb30eef3749"),
        email: "aninhafavoritafashion@gmail.com",
        phone: "85991175872",
        // createdAt: new Date("2022-06-03T16:53:02.325Z"),
        updatedAt: new Date("2023-05-13T19:22:35.443Z"),
        store: {
            name: "Favorita Fashion",
            city: "Capistrano",
        },
    },

    /* 7 */ {
        // _id: ObjectId("61b1197684e33706d428cf27"),
        email: "emannuel_lima@hotmail.com",
        phone: "85992128641",
        // createdAt: new Date("2021-12-08T20:45:42.720Z"),
        updatedAt: new Date("2023-05-06T14:55:35.143Z"),
        store: {
            name: "Ct Superação",
            city: "Capistrano",
        },
    },

    /* 8 */ {
        // _id: ObjectId("61b1171984e337c26e28cf17"),
        email: "gonzagaluiz802@gmail.com",
        phone: "85991063872",
        // createdAt: new Date("2021-12-08T20:35:37.166Z"),
        updatedAt: new Date("2023-04-19T14:36:29.407Z"),
        store: {
            name: "NN Cell",
            city: "Capistrano",
        },
    },

    /* 9 */ {
        // _id: ObjectId("6377b33aa0f98c14e5e106b5"),
        email: "cashback@eulucrei.com.br",
        phone: "85992429308",
        // createdAt: new Date("2022-11-18T16:30:50.257Z"),
        updatedAt: new Date("2023-04-06T16:27:21.987Z"),
        store: {
            name: "Promoção Lucrei",
            city: "Capistrano",
        },
    },

    /* 10 */ {
        // _id: ObjectId("642b020dc828ab55adab6ea6"),
        email: "luxostoremc@gmail.com",
        phone: "85996159701",
        // createdAt: new Date("2023-04-03T16:42:53.867Z"),
        updatedAt: new Date("2023-04-03T16:46:39.095Z"),
        store: {
            name: "Luxo Store ",
            city: "Capistrano",
        },
    },

    /* 11 */ {
        // _id: ObjectId("63371ac4baed69c7ceb8556c"),
        email: "useatual@outlook.com",
        phone: "85998237012",
        // createdAt: new Date("2022-09-30T16:35:16.150Z"),
        updatedAt: new Date("2023-04-01T13:17:13.650Z"),
        store: {
            name: "Atual",
            city: "Capistrano",
        },
    },

    /* 12 */ {
        // _id: ObjectId("6348487c002e442c847a9c83"),
        email: "kleberholanda@life.com",
        phone: "85996514376",
        // createdAt: new Date("2022-10-13T17:18:52.464Z"),
        updatedAt: new Date("2023-02-17T12:44:56.971Z"),
        store: {
            name: "BamBam Atualizações",
            city: "Capistrano",
        },
    },

    /* 13 */ {
        // _id: ObjectId("63de88a37ec3f84e70b63209"),
        email: "sarahnyellen@hotmail.com",
        phone: "85997854082",
        // createdAt: new Date("2023-02-04T16:32:35.513Z"),
        updatedAt: new Date("2023-02-09T15:59:37.061Z"),
        store: {
            name: "Lovely Conceito",
            city: "Capistrano",
        },
    },

    /* 14 */ {
        // _id: ObjectId("61b1183784e337cf4e28cf1b"),
        email: "weryklys@hotmail.com",
        phone: "85999855553",
        // createdAt: new Date("2021-12-08T20:40:23.865Z"),
        updatedAt: new Date("2023-02-04T13:29:10.991Z"),
        store: {
            name: "smart tech acessórios",
            city: "Capistrano",
        },
    },

    /* 15 */ {
        // _id: ObjectId("63727b31a0f98ce176e105ef"),
        email: "sonhocoloridoescola@gmail.com",
        phone: "85991307022",
        // createdAt: new Date("2022-11-14T17:30:25.691Z"),
        updatedAt: new Date("2022-11-18T12:17:02.798Z"),
        store: {
            name: "Escola Sonho Colorido",
            city: "Capistrano",
        },
    },

    /* 16 */ {
        // _id: ObjectId("637775f5a0f98c47e5e10699"),
        email: "durvalvet@gmail.com",
        phone: "85997177980",
        // createdAt: new Date("2022-11-18T12:09:25.174Z"),
        updatedAt: new Date("2022-11-18T12:16:15.837Z"),
        store: {
            name: "DURVAL VET",
            city: "Capistrano",
        },
    },

    /* 17 */ {
        // _id: ObjectId("6344ab0e002e44671b7a9c19"),
        email: "alinecavalcante@gmail.com",
        phone: "85997216339",
        // createdAt: new Date("2022-10-10T23:30:22.437Z"),
        updatedAt: new Date("2022-10-10T23:30:22.553Z"),
        store: {
            name: "Contrata Maciço ",
            city: "Aracoiaba",
        },
    },

    /* 18 */ {
        // _id: ObjectId("629a4a67206fff5985ef3761"),
        email: "danielferinha10@hotmail.com",
        phone: "85994383316",
        // createdAt: new Date("2022-06-03T17:52:39.506Z"),
        updatedAt: new Date("2022-10-07T14:34:12.241Z"),
        store: {
            name: "AD IMPORTS",
            city: "Capistrano",
        },
    },

    /* 19 */ {
        // _id: ObjectId("61e60a8834de6f5ee53c6d6b"),
        email: "multifer2019@hotmail.com",
        phone: "85999755890",
        // createdAt: new Date("2022-01-18T00:32:08.347Z"),
        updatedAt: new Date("2022-10-06T15:08:27.886Z"),
        store: {
            name: "Multi Ferragens",
            city: "Aracoiaba",
        },
    },

    /* 20 */ {
        // _id: ObjectId("620c40617d59b83a5e3d7e85"),
        email: "farmaciaalbuquerque18@hotmail.com",
        phone: "85994221901",
        // createdAt: new Date("2022-02-16T00:08:01.277Z"),
        updatedAt: new Date("2022-08-11T21:45:13.374Z"),
        store: {
            name: "Farmácia Albuquerque",
            city: "Aracoiaba",
        },
    },

    /* 21 */ {
        // _id: ObjectId("62c4a1852dd1d2ecd7e508d5"),
        email: "lwcasrodrygues@gmail.com",
        phone: "85991167138",
        // createdAt: new Date("2022-07-05T20:39:33.501Z"),
        updatedAt: new Date("2022-07-14T19:24:54.630Z"),
        store: {
            name: "Maktub Grifes",
            city: "Baturité",
        },
    },

    /* 22 */ {
        // _id: ObjectId("629a379c206fff0541ef373f"),
        email: "marconesinfotec17@gmail.com",
        phone: "85999485592",
        // createdAt: new Date("2022-06-03T16:32:28.186Z"),
        updatedAt: new Date("2022-07-01T12:35:57.377Z"),
        store: {
            name: "Sempre Bela ",
            city: "Capistrano",
        },
    },

    /* 23 */ {
        // _id: ObjectId("61d5d490106e1c425e17ae61"),
        email: "keilane.pinheiro@gmail.com",
        phone: "85992070157",
        // createdAt: new Date("2022-01-05T17:25:36.221Z"),
        updatedAt: new Date("2022-06-06T16:16:08.918Z"),
        store: {
            name: "Farmácia da Familia",
            city: "Aracoiaba",
        },
    },

    /* 24 */ {
        // _id: ObjectId("62166098b3ec5dbd33e77e04"),
        email: "wendell853@outlook.com",
        phone: "85994386617",
        // createdAt: new Date("2022-02-23T16:28:08.962Z"),
        updatedAt: new Date("2022-05-28T14:54:51.417Z"),
        store: {
            name: "PREMIUM RAÇÕES",
            city: "Capistrano",
        },
    },

    /* 25 */ {
        // _id: ObjectId("6284ee052f2eed1d24478586"),
        email: "joseerasmolima20@gmail.com",
        phone: "85992749254",
        // createdAt: new Date("2022-05-18T13:00:53.909Z"),
        updatedAt: new Date("2022-05-25T12:12:38.470Z"),
        store: {
            name: "Blimef",
            city: "Capistrano",
        },
    },

    /* 26 */ {
        // _id: ObjectId("622cab6f2601d2d9326469e7"),
        email: "werbesn@gmail.com",
        phone: "85998668719",
        // createdAt: new Date("2022-03-12T14:17:19.970Z"),
        updatedAt: new Date("2022-05-15T14:56:11.968Z"),
        store: {
            name: "Churrascaria Sabor do sertão ",
            city: "Capistrano",
        },
    },

    /* 27 */ {
        // _id: ObjectId("61d34802106e1ca58117ae00"),
        email: "pixdjm0@gmail.com",
        phone: "85996869457",
        // createdAt: new Date("2022-01-03T19:01:22.202Z"),
        updatedAt: new Date("2022-05-12T17:18:42.351Z"),
        store: {
            name: "JM Construções",
            city: "Capistrano",
        },
    },

    /* 28 */ {
        // _id: ObjectId("61e60b2d34de6fb9e43c6d6f"),
        email: "tassiguedes2015@gmail.com",
        phone: "85998142339",
        // createdAt: ISODate("2022-01-18T00:34:53.895Z"),
        updatedAt: new Date("2022-05-10T21:41:55.364Z"),
        store: {
            name: "TG Boutique Multimarcas",
            city: "Aracoiaba",
        },
    },

    /* 29 */ {
        // _id: ObjectId("620b03307d59b8262c3d7de0"),
        email: "f.luzirene83@gmail.com",
        phone: "85999556225",
        // createdAt: new Date("2022-02-15T01:34:40.474Z"),
        updatedAt: new Date("2022-05-10T21:39:51.906Z"),
        store: {
            name: "Lu Opções",
            city: "Aracoiaba",
        },
    },

    /* 30 */ {
        // _id: ObjectId("61de3e57672f90d3920d3999"),
        email: "luanmario.lm52@gmail.com",
        phone: "85941412668",
        // createdAt: new Date("2022-01-12T02:35:03.775Z"),
        updatedAt: new Date("2022-05-10T21:38:29.282Z"),
        store: {
            name: "Infor Mario Digital",
            city: "Aracoiaba",
        },
    },

    /* 31 */ {
        // _id: ObjectId("61df6ddb34de6fad4c3c6ac1"),
        email: "novidadeshelena@gmail.com",
        phone: "85999070592",
        // createdAt: new Date("2022-01-13T00:10:03.566Z"),
        updatedAt: new Date("2022-05-10T21:37:21.043Z"),
        store: {
            name: "Helena Novidades",
            city: "Aracoiaba",
        },
    },

    /* 32 */ {
        // _id: ObjectId("622915368cb8c26b66e67b0c"),
        email: "luiz_queiroz01@hotmail.com",
        phone: "85999464221",
        // createdAt: new Date("2022-03-09T20:59:34.412Z"),
        updatedAt: new Date("2022-05-10T21:36:20.637Z"),
        store: {
            name: "Eletrônica Central",
            city: "Aracoiaba",
        },
    },

    /* 33 */ {
        // _id: ObjectId("6221170d5d228f7376c4ce0d"),
        email: "monaliserodrigues2708@gmail.com",
        phone: "85997385346",
        // createdAt: new Date("2022-03-03T19:29:17.652Z"),
        updatedAt: new Date("2022-05-10T21:34:46.193Z"),
        store: {
            name: "Delícias da Mona",
            city: "Aracoiaba",
        },
    },

    /* 34 */ {
        // _id: ObjectId("62157a5fb3ec5d8302e77d76"),
        email: "luthferreira@yahoo.com.br",
        phone: "85999278108",
        // createdAt: new Date("2022-02-23T00:05:51.258Z"),
        updatedAt: new Date("2022-05-04T22:13:03.717Z"),
        store: {
            name: "Delivery Liberty Pizzaria",
            city: "Aracoiaba",
        },
    },

    /* 35 */ {
        // _id: ObjectId("61b4129384e337f7d928d190"),
        email: "katiavendasbc@gmail.com",
        phone: "85992634591",
        // createdAt: new Date("2021-12-11T02:53:07.448Z"),
        updatedAt: new Date("2022-05-04T13:57:59.592Z"),
        store: {
            name: "Ótica da Família Capistrano",
            city: "Capistrano",
        },
    },

    /* 36 */ {
        // _id: ObjectId("620afcae7d59b882e23d7dda"),
        email: "antoniofilhomacedo094@gmail.com",
        phone: "85996016567",
        // createdAt: new Date("2022-02-15T01:06:54.466Z"),
        updatedAt: new Date("2022-04-21T17:43:04.967Z"),
        store: {
            name: "Geladão Da Fonte",
            city: "Aracoiaba",
        },
    },

    /* 37 */ {
        // _id: ObjectId("624c432756a59d4e3afd5a37"),
        email: "edivanilsoniptv@gmail.com",
        phone: "85990000001",
        // createdAt: new Date("2022-04-05T13:24:55.120Z"),
        updatedAt: new Date("2022-04-14T15:47:38.607Z"),
        store: {
            name: "VanilsonIPTV",
            city: "Capistrano",
        },
    },

    /* 38 */ {
        // _id: ObjectId("6228f0a48cb8c27d2ce6799d"),
        email: "agda.santos06@gmail.com",
        phone: "85998359316",
        // createdAt: new Date("2022-03-09T18:23:32.287Z"),
        updatedAt: new Date("2022-04-05T13:41:09.875Z"),
        store: {
            name: "Óticas Vitória",
            city: "Capistrano",
        },
    },

    /* 39 */ {
        // _id: ObjectId("6227b6698cb8c24570e675c6"),
        email: "lanchefepizzaria@gmail.com",
        phone: "85994004595",
        // createdAt: new Date("2022-03-08T20:02:49.393Z"),
        updatedAt: new Date("2022-03-28T17:13:39.280Z"),
        store: {
            name: "LanChefe Pizzaria",
            city: "Capistrano",
        },
    },

    /* 40 */ {
        // _id: ObjectId("623142d6ef36c5c9291006a4"),
        email: "cleuma_maria2015h@gmail.com",
        phone: "85999072743",
        // createdAt: new Date("2022-03-16T01:52:22.057Z"),
        updatedAt: new Date("2022-03-16T19:48:03.664Z"),
        store: {
            name: "Churrascaria Society Padre Cícero",
            city: "Aracoiaba",
        },
    },

    /* 41 */ {
        // _id: ObjectId("62314903ef36c5712c1007df"),
        email: "terceiraconquist@gmail.com",
        phone: "85933470022",
        // createdAt: new Date("2022-03-16T02:18:43.849Z"),
        updatedAt: new Date("2022-03-16T02:18:44.017Z"),
        store: {
            name: "3° Conquista",
            city: "Baturité",
        },
    },

    /* 42 */ {
        // _id: ObjectId("62314516ef36c579621006ab"),
        email: "topcell52@gmail.com",
        phone: "85999319423",
        // createdAt: new Date("2022-03-16T02:01:58.307Z"),
        updatedAt: new Date("2022-03-16T02:01:58.453Z"),
        store: {
            name: "Top Cell Baturité",
            city: "Baturité",
        },
    },

    /* 43 */ {
        // _id: ObjectId("62264a2a8cb8c280d3e67187"),
        email: "tiagocamelo@ymail.com",
        phone: "85999291439",
        // createdAt: new Date("2022-03-07T18:08:42.801Z"),
        updatedAt: new Date("2022-03-07T18:08:42.921Z"),
        store: {
            name: "Corpo Maciço",
            city: "Baturité",
        },
    },

    /* 44 */ {
        // _id: ObjectId("61b4117084e337630928d18c"),
        email: "diego@debritoengenharia.com.br",
        phone: "85997130755",
        // createdAt: new Date("2021-12-11T02:48:16.487Z"),
        updatedAt: new Date("2021-12-11T02:48:16.599Z"),
        store: {
            name: "LocPrime",
            city: "Baturité",
        },
    },
];

export default data;
