import { React, useState, useEffect } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";

import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import { getToken } from "../../services/auth";
import {
    FaUser,
    FaPhone,
    FaWallet,
    FaRegEnvelope,
    FaBirthdayCake,
    FaAddressCard,
    FaMapPin,
    FaTag,
    FaQuestion,
    FaUserTag,
} from "react-icons/fa";
import "./style.scss";

const CustomerSalesDetailsPage = () => {
    const history = useHistory();
    const token = getToken();
    const { idCliente } = useParams();

    const [customerPurchases, setCustomerPurchases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fetchError, setFetchError] = useState("");
    const [customerInfo, setCustomerInfo] = useState({});

    async function getCustomerPurchases() {
        try {
            const response = await api.get(
                `api/v1/customers/${idCliente}/purchases?pageNumber=${currentPage}`,
                {
                    headers: { Authorization: token },
                }
            );
            setCustomerPurchases(response.data.data);
            setTotalPages(response.data.pages);
            setFetchError(null);
        } catch (e) {
            console.log("ERRO: ", { e });
            setFetchError(e.message);
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (e?.response?.data?.error?.statusCode === 403) {
                toast.error("Faça login novamente!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push("/");
            }
        } finally {
            setLoading(false);
        }
    }

    async function getCustomerInfo() {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/customers/${idCliente}/`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log("RESPONSE", response.data);
            setCustomerInfo(response.data);
            setFetchError(null);
        } catch (e) {
            setFetchError(e.message);
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCustomerPurchases();
        getCustomerInfo();
    }, []);

    useEffect(() => {
        getCustomerPurchases();
    }, [currentPage]);

    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="CustomerSalesDetailsPage">
                <div className="btn-links-wrapper">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={goBack}
                    >
                        Voltar
                    </button>
                    <Link
                        to={`/clientes/${customerInfo._id}/notas-fiscais`}
                        className="btn-link-purchases"
                    >
                        Visualizar Notas Fiscais do cliente
                    </Link>
                </div>
                {fetchError && <p>{fetchError}</p>}
                {loading && <h2 className="text-center">Carregando...</h2>}
                {!loading && customerInfo && (
                    <div className="user-info-wrapper">
                        <div className="user-info">
                            <div className="primary-info">
                                <div className="user-avatar">
                                    <FaUser size={64} color={"#bcbcbc"} />
                                </div>
                                <div className="contact-info">
                                    <p>
                                        <FaRegEnvelope />{" "}
                                        {customerInfo?.account?.email}
                                    </p>
                                    <p>
                                        {" "}
                                        <FaPhone />
                                        {customerInfo?.account?.phone}
                                    </p>
                                    <p>
                                        <FaMapPin /> {customerInfo?.city}
                                    </p>
                                </div>
                            </div>
                            <div className="secondary-info">
                                <div>
                                    <h1>{customerInfo?.name}</h1>
                                    <p>
                                        <FaUserTag />
                                        Cód. Lucrei: {customerInfo?.lucreiCode}
                                    </p>

                                    <p>
                                        <FaAddressCard />
                                        CPF: {customerInfo?.cpf}
                                    </p>
                                    <p>
                                        <FaBirthdayCake /> Data de nascimento:{" "}
                                        {new Date(
                                            customerInfo?.dateOfBirth
                                        ).toLocaleDateString("pt-br")}
                                    </p>
                                </div>

                                <div className="financial-info">
                                    {customerInfo?.registerCode && (
                                        <p>
                                            <FaTag />{" "}
                                            {customerInfo?.registerCode}
                                        </p>
                                    )}

                                    <p>
                                        <FaWallet />
                                        Saldo:
                                        <span
                                            style={{
                                                color: "green",
                                                fontWeight: "600",
                                                marginLeft: "2px",
                                            }}
                                        >
                                            R$
                                            {customerInfo?.wallet?.balance.toLocaleString(
                                                "pt-br",
                                                {
                                                    minimumFractionDigits: 2,
                                                },
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <FaQuestion /> Recebeu bonus?{" "}
                                        {customerInfo?.receivedRegisterBonus ? (
                                            <span style={{ color: "green" }}>
                                                Sim
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Não
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!loading && customerPurchases && (
                    <div className="purchases-table-wrapper">
                        <table className="table table-stripped">
                            <thead>
                                <tr>
                                    <th>EMPRESA</th>
                                    <th>VALOR (R$)</th>
                                    <th>CASHBACK (R$)</th>
                                    <th>DATA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerPurchases.map((purchase) => (
                                    <tr key={purchase._id}>
                                        <td>{purchase?.storeName}</td>
                                        <td>
                                            R$
                                            {` ` +
                                                purchase.value.toLocaleString(
                                                    "pt-br",
                                                    {
                                                        minimumFractionDigits: 2,
                                                    },
                                                    {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }
                                                )}
                                        </td>
                                        <td>
                                            {purchase.cashback.toLocaleString(
                                                "pt-br",
                                                { minimumFractionDigits: 2 },
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}
                                        </td>
                                        <td>
                                            {new Date(
                                                purchase.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomerSalesDetailsPage;
