export const TOKEN_KEY = "@Cashback-Token";
export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token !== null) {
    return true;
  } else {
    return false;
  }
}
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const loginToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};


export default loginToken;
