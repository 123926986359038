import React, { useState } from "react";

import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Button from "../../button/index";
import DepositRequestModal from "../../Modals/depositRequest";


import "./style.scss";

import Swipe from "../../../assets/swipe.gif";

import Arrow from "../../../assets/arrow-order.png";
import useSortedData from "../../../hooks/useSortedData";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const prepareDataToSort = (data) => {
  const newData = data.map((row) => {
    return {
      _id: row._id,
      storeName: row.store.name,
      cnpj: row.store.cnpj,
      value: row.value,
      status: row.status,
      phoneToContact: row.phoneToContact,
      createdAt: row.createdAt,
    };
  });

  return newData;
};

const DepositsTable = ({ deposits, loadDeposits }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const [sortedField, setSortedField] = useState("createdAt");
  const [descData, setDescData] = useState(true);
  const { items, requestSort, sortConfig } = useSortedData(
    prepareDataToSort(deposits)
  );
  console.log("DEPOSITS", deposits);
  const handleChangeStatus = (id) => {
    setId(id);
    setOpen(true);
  };

  const handleClick = (field) => {
    if (field === sortedField) {
      setDescData((prev) => !prev);
      requestSort(field);
      return;
    }

    setSortedField(field);
    setDescData(false);
    requestSort(field);
  };

  return (
    <div className="TableWrapper">
      <div component={Paper} className="container">
        <h5> Solicitações de Deposito</h5>
        <table sx={{ minWidth: 650 }} aria-label="simple table" className="table table-hover">
          <thead>
            <tr className="TableRowHead">
              <th
                className="CellHead"
                align="center"
                onClick={() => handleClick("createdAt")}
              >
                <span>
                  Data da solicitação:{" "}
                  {sortedField === "createdAt" && (
                    <img
                      className={descData ? "desc" : "asc"}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>
              </th>
              <th
                className="CellHead"
                align="center"
                onClick={() => handleClick("storeName")}
              >
                <span>
                  Nome da empresa:{" "}
                  {sortedField === "storeName" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>
              </th>
              <th
                className="CellHead"
                align="center"
                onClick={() => handleClick("cnpj")}
              >
                <span>
                  CNPJ do Estabelecimento:{" "}
                  {sortedField === "cnpj" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>
              </th>
              <th
                className="CellHead"
                align="center"
                onClick={() => handleClick("value")}
              >
                <span>
                  Valor do depósito:{" "}
                  {sortedField === "value" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>
              </th>
              <th
                className="CellHead"
                align="center"
                onClick={() => handleClick("status")}
              >
                <span>
                  Status do depósito:{" "}
                  {sortedField === "status" && (
                    <img
                      className={descData ? "desc" : ""}
                      src={Arrow}
                      alt="arrow"
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((row) => (
                <StyledTableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <th component="th" scope="row" align="center">
                    {new Date(row.createdAt).toLocaleString("pt-br")}
                  </th>
                  <th align="center">{row.storeName}</th>

                  <th align="center">{row.cnpj}</th>

                  <th align="center">
                    R$
                    {` ` +
                      row.value.toLocaleString(
                        "pt-br",
                        { minimumFractionDigits: 2 },
                        { style: "currency", currency: "BRL" }
                      )}
                  </th>
                  <th align="center">
                    <Button onClick={() => handleChangeStatus(row._id)}>
                      {row.status}
                    </Button>
                  </th>
                  <th align="center"></th>
                </StyledTableRow>
              ))}
          </tbody>
        </table>
      </div>
      {open && (
        <DepositRequestModal
          setOpen={setOpen}
          open={open}
          id={id}
          loadDeposits={loadDeposits}
        >
          Entre em contato com a empresa para finalizar o processo de deposito
        </DepositRequestModal>
      )}
    </div>
  );
};

export default DepositsTable;
