import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";

import api from "../../services/api";
import { useCallback } from "react";

const LatestSalesPage = () => {
    const history = useHistory();
    const token = getToken();
    const [loading, setLoading] = useState(true);
    const [latestSales, setLatestSales] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fetchError, setFetchError] = useState("");

    const getLatestSales = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/get-latest-sales?pageNumber=${currentPage}`,
                {
                    headers: { Authorization: token },
                }
            );
            setLatestSales(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            setFetchError(error.message);
            console.log("ERRO: ", { error });
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (error?.response?.data?.error?.statusCode === 403) {
                toast.error("Faça login novamente!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push("/");
                return;
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLatestSales();
    }, []);

    useEffect(() => {
        getLatestSales();
    }, [currentPage]);

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="CustomersStoreReportsPage">
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPageAlter">
                {/* <div>
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={goBack}
                    >
                        Voltar
                    </button>
                </div> */}
                {loading && (
                    <div className="row">
                        <h4 className="text-center">Carregando...</h4>
                    </div>
                )}
                {!fetchError && !loading && (
                    <div className="AlingTable">
                        <h2 style={{ fontFamily: 'Sora', fontSize: '1.5em', fontWeight: '800' }}>Últimas Vendas:</h2>
                        <table className="table">
                            <thead className="headerTable">
                                <tr>
                                    <th>USUÁRIO</th>
                                    <th>EMPRESA</th>
                                    <th>VALOR</th>
                                    <th>CASHBACK</th>
                                    <th>DATA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {latestSales.map((sale) => (
                                    <tr key={sale._id}>
                                        <td>
                                            <Link
                                                to={`/clientes/${sale.customer._id}/compras`}
                                            >
                                                {sale.customer.name}
                                            </Link>
                                        </td>
                                        <td>{sale.store.name}</td>
                                        <td>
                                            {sale.value.toLocaleString(
                                                "pt-br",
                                                {
                                                    minimumFractionDigits: 2,
                                                },
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}
                                        </td>
                                        <td>
                                            {sale.cashback.toLocaleString(
                                                "pt-br",
                                                {
                                                    minimumFractionDigits: 2,
                                                },
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}
                                        </td>
                                        <td>
                                            {new Date(
                                                sale.createdAt
                                            ).toLocaleString("pt-br")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </div >
    );
};

export default LatestSalesPage;
