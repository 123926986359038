import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";

import { FaFileExport } from "react-icons/fa";

import api from "../../services/api";

const StoreReceiptsPage = () => {
    const history = useHistory();

    const [initialMonth, setInitialMonth] = useState(0);
    const [currentYear, setCurrentYear] = useState(0);
    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [topStores, setTopStores] = useState([]);
    const [showLatestSalesMessage, setShowLatestSalesMessage] = useState(false);
    const [totalReceipts, setTotalReceipts] = useState(0);
    const [createdAt, setCreatedAt] = useState("");
    const token = getToken();

    const getTopStoresByReceipt = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/total-receipts-by-store`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }
            console.log(response);
            setTotalReceipts(getTotalReceipts(data));
            setTopStores(data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const generateReport = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        console.log(currentDate);
        currentDate.setHours(currentDate.getHours() + 3);
        const title = createdAt
            ? `RELATÓRIO NOTAS FISCAIS POR EMPRESA -  (${createdAt
                  .split("-")
                  .reverse()
                  .join("-")}) - TOTAL: ${totalReceipts}`
            : `RELATÓRIO NOTAS FISCAIS POR EMPRESA - TOTAL: ${totalReceipts}`;

        const headers = [
            [
                "EMPRESA",
                "NOTAS FISCAIS ENVIADAS",
                "VAL. TOTAL DAS NOTAS FISCAIS ENVIADAS",
                "VAL. TOTAL CASHBACK ATRIBUÍDO",
            ],
        ];

        const data = topStores.map((elt) => [
            elt.store,
            elt.totalReceipts,
            elt.totalReceiptsValue.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            elt.totalCashbackToCustomers.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [0, 238, 0], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);

        doc.save(`REL-NOTAS-FISCAIS-${currentDate.toLocaleDateString()}.pdf`);
    };

    const handleCreatedAtChange = ({ target }) => {
        setCreatedAt(target.value);
    };

    const getTopStoresByReceiptFilteredByDate = async () => {
        try {
            console.log("CreatedAt", createdAt);
            const splittedData = createdAt.split("-");
            const day = splittedData[2];
            const month = splittedData[1];
            const year = splittedData[0];

            if (!day || !month || !year) {
                toast.error("Informe uma data válida dia/mês/ano", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/total-receipts-by-store?day=${day}&month=${month}&year=${year}`,
                {
                    headers: { Authorization: token },
                }
            );
            const { data } = response;
            console.log(response);
            setTotalReceipts(getTotalReceipts(data));
            setTopStores(data);

            // generateCustomersInfoReportByCreatedAt(data, createdAt);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getTopStoresByReceiptWithoutFilter = async () => {
        setCreatedAt("");
        getTopStoresByReceipt();
    };

    const getTotalReceipts = (receipts) => {
        const totalReceipts = receipts.reduce((total, item) => {
            return total + item.totalReceipts;
        }, 0);

        return totalReceipts;
    };

    useEffect(() => {
        getTopStoresByReceipt();
        console.log(currentYear);
        console.log(initialMonth);
    }, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                <div className="row">
                    <div className="button-wrapper">
                        <div className="filter-date-wrapper">
                            <input
                                type="date"
                                name="createdAt"
                                id="createdAt"
                                value={createdAt}
                                className="form-control"
                                min={"2021-03-01"}
                                onChange={handleCreatedAtChange}
                            />
                            <button
                                className="btn-export-pdf btn-filter"
                                onClick={getTopStoresByReceiptFilteredByDate}
                            >
                                FIltrar por data
                            </button>
                            {createdAt && (
                                <button
                                    className="btn-export-pdf btn-outline"
                                    onClick={getTopStoresByReceiptWithoutFilter}
                                >
                                    Remover filtro
                                </button>
                            )}
                        </div>

                        {topStores.length > 0 && (
                            <button
                                className="btn-export-pdf"
                                onClick={generateReport}
                            >
                                <FaFileExport className="icon-pdf" />
                                <span>Exportar como PDF</span>
                            </button>
                        )}
                    </div>

                    {!loading && (
                        <div className="listPromoCodesMobileWrapper">
                            <div>
                                <h5>
                                    Total notas cadastradas: {totalReceipts}
                                </h5>
                            </div>
                            <table className="listPromoCodes">
                                <thead>
                                    <tr>
                                        <th scope="col">Empresa</th>
                                        <th scope="col">
                                            Total de Notas Fiscais Enviadas
                                        </th>
                                        <th scope="col">
                                            Valor total das Notas Fiscais
                                        </th>
                                        <th scope="col">
                                            Valor total de Cashback atribuído
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topStores?.map((store) => (
                                        <tr key={store._id}>
                                            <td
                                                title={store.store}
                                                className="col-receipt-code"
                                            >
                                                {store.store}
                                            </td>

                                            <td>{store.totalReceipts}</td>

                                            <td>
                                                {" "}
                                                {store.totalReceiptsValue
                                                    ? store.totalReceiptsValue.toLocaleString(
                                                          "pt-br",
                                                          {
                                                              minimumFractionDigits: 2,
                                                          },
                                                          {
                                                              style: "currency",
                                                              currency: "BRL",
                                                          }
                                                      )
                                                    : "-"}
                                            </td>
                                            <td>
                                                {" "}
                                                {store.totalCashbackToCustomers
                                                    ? store.totalCashbackToCustomers.toLocaleString(
                                                          "pt-br",
                                                          {
                                                              minimumFractionDigits: 2,
                                                          },
                                                          {
                                                              style: "currency",
                                                              currency: "BRL",
                                                          }
                                                      )
                                                    : "-"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <div className="row">
                        {showLatestSalesMessage && (
                            <p className="text-center message">
                                Ainda não foram cadastradas notas fiscais para
                                serem exibidas.
                            </p>
                        )}
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default StoreReceiptsPage;
