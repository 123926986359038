import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";

import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

import "./style.css";

import Menu from "../../components/Menu/Menu";
import Pagination from "../../components/Pagination";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import api from "../../services/api";
import BonusModal from "../../components/BonusModal";

const PromoCodeDetails = ({ bonus }) => {
    const history = useHistory();
    const token = getToken();
    const { code } = useParams();
    const [customersList, setCustomersList] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [customerId, setCustomerId] = useState("");
    const [showBonusModal, setShowBonusModal] = useState(false);
    const [
        totalCustomersWithFirstPurchase,
        setTotalCustomersWithFirstPurchase,
    ] = useState(0);
    const search = useLocation().search;
    const defaultBonusValue = new URLSearchParams(search).get("bonus");

    async function getPromoCodeCustomers(code) {
        try {
            const response = await api.get(
                `api/v1/promo-codes/${code}/customers?pageNumber=${paginaAtual}`,
                {
                    headers: { Authorization: token },
                }
            );
            const { data, pages, totalItems } = response.data;
            setCustomersList(data);
            setTotalPaginas(pages);
            setTotalCustomers(totalItems);
            getCustomersWithFirstPurchase();
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    async function getCustomersWithFirstPurchase() {
        try {
            const response = await api.get(
                `api/v1/promo-codes/${code}/customers?firstPurchase=true`,
                {
                    headers: { Authorization: token },
                }
            );
            const { totalItems } = response.data;
            setTotalCustomersWithFirstPurchase(totalItems);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    async function getTotalPurchasesByCustomers() {
        try {
            const response = await api.get(
                `api/v1/promo-codes/${code}/purchases/count`,
                {
                    headers: { Authorization: token },
                }
            );
            const { data } = response;
            setTotalPurchases(data.totalPurchases);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    }

    useEffect(() => {
        getPromoCodeCustomers(code);
        getTotalPurchasesByCustomers();
        console.log("Passou useeffect[]");
    }, []);

    useEffect(() => {
        getPromoCodeCustomers(code);
        console.log("useEffect paginaAtual");
    }, [paginaAtual]);

    useEffect(() => {
        if (customerId === null) {
            console.log("Chama aqui??????");
            getPromoCodeCustomers(code);
        }
    }, [customerId]);

    const previousPage = () => {
        setPaginaAtual((paginaAtual) => paginaAtual - 1);
    };

    const nextPage = () => {
        setPaginaAtual((paginaAtual) => paginaAtual + 1);
    };

    const goBack = () => {
        history.goBack();
    };

    const closeBonusModal = () => {
        setShowBonusModal(false);
    };

    const giveBonusToCustomer = async (customerId) => {
        setShowBonusModal(true);
        setCustomerId(customerId);
    };

    return (
        <>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />

            <div className="promoCodeWrapper">
                <div>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={goBack}
                    >
                        <RiArrowGoBackFill /> Voltar
                    </button>
                </div>

                <p className="promoHeader">
                    Clientes cadastrados pelo Código {code || `Ocelio`}{" "}
                </p>

                <hr />

                <div className="customersNumbers">
                    <div className="card customSize">
                        <div className="card-header">
                            <h5>Clientes Cadastrados:</h5>
                        </div>
                        <div className="card-body">
                            <h5>{totalCustomers}</h5>
                        </div>
                    </div>

                    <div className="card customSize">
                        <div className="card-header">
                            <h5>Primeira Compra Realizada:</h5>
                        </div>
                        <div className="card-body">
                            <h5>{totalCustomersWithFirstPurchase}</h5>
                        </div>
                    </div>

                    <div className="card customSize">
                        <div className="card-header">
                            <h5>Total de Compras:</h5>
                        </div>
                        <div className="card-body">
                            <h5>{totalPurchases}</h5>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="codesFilter">
                    <button>Filtrar por: Mostrar Todos</button>
                    <button>Filtrar por: 1ª Compra Realizada</button>
                    <button>Filtrar por: Bônus Recebido</button>
                </div>

                <div className="listPromoCodesMobileWrapper">
                    <table className="listPromoCodes">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Fez 1º Compra?</th>
                                <th>Recebeu Bônus?</th>
                                <th>Bônus</th>
                                <th>Detalhes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customersList?.map((item) => {
                                return (
                                    <tr key={item._id}>
                                        <td>{item.name}</td>
                                        <td>
                                            {item.firstPurchase ? "SIM" : "NÃO"}
                                        </td>
                                        <td>
                                            {item.receivedRegisterBonus
                                                ? "SIM"
                                                : "NÂO"}
                                        </td>
                                        <td>
                                            <button
                                                className="seeMoreBTN"
                                                disabled={
                                                    item.receivedRegisterBonus ||
                                                    !item.firstPurchase
                                                }
                                                onClick={() =>
                                                    giveBonusToCustomer(item.id)
                                                }
                                            >
                                                Atribuir Bônus
                                            </button>
                                        </td>
                                        <td>
                                            {item.firstPurchase ? (
                                                <Link
                                                    className="seeMoreBTN"
                                                    to={`/admin/${item._id}/detalhes-compras?customer=${item.name}`}
                                                >
                                                    Ver compras
                                                </Link>
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    currentPage={paginaAtual}
                    totalPages={totalPaginas}
                    paginate={setPaginaAtual}
                />
            </div>
            {showBonusModal && (
                <BonusModal
                    onCancel={closeBonusModal}
                    id={customerId}
                    setCustomerId={setCustomerId}
                    defaultBonusValue={defaultBonusValue}
                />
            )}
        </>
    );
};

export default PromoCodeDetails;
