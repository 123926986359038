import React from "react";
import "./style.scss";

export default function Loader({ dataName = "dados" }) {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <p>Carregando {dataName}...</p>
        </div>
    );
}
