import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

import api from "../../services/api";

const TopBuyersPage = () => {
    const history = useHistory();
    const token = getToken();
    const [loading, setLoading] = useState(true);
    const [topBuyers, setTopBuyers] = useState([]);
    const [fetchError, setFetchError] = useState("");

    useEffect(() => {
        const getTopBuyers = async () => {
            try {
                setLoading(true);
                const response = await api.get(
                    `api/v1/dashboard/admin/customers/top-buyers`,
                    {
                        headers: { Authorization: token },
                    }
                );
                console.log(response);
                setTopBuyers(response.data.data);
            } catch (error) {
                setFetchError(error.message);
                console.log("ERRO: ", { error });
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }

                if (error?.response?.data?.error?.statusCode === 403) {
                    toast.error("Faça login novamente!", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push("/");
                    return;
                }
            } finally {
                setLoading(false);
            }
        };
        getTopBuyers();
    }, []);

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="CustomersStoreReportsPage">
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                <div className="row">
                    <h2 className="text-center">TOP 10 COMPRADORES</h2>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={goBack}
                    >
                        Voltar
                    </button>
                </div>
                {loading && (
                    <div className="row">
                        <h4 className="text-center">Carregando...</h4>
                    </div>
                )}
                {!fetchError && !loading && (
                    <div className="row">
                        <table className="table table-stripped latest-sales-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>USUÁRIO</th>
                                    <th>CIDADE</th>
                                    <th>TOTAL DE COMPRAS CADASTRADAS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topBuyers.map((buyer, index) => (
                                    <tr key={buyer._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <Link
                                                to={`/clientes/${buyer._id}/compras`}
                                            >
                                                {buyer.customer.name}
                                            </Link>
                                        </td>
                                        <td>{buyer.customer.city}</td>
                                        <td>{buyer.totalPurchases}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopBuyersPage;
