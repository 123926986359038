import "./style.scss";
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { getToken } from "../../services/auth";
// import { getToken, loginToken } from '../../../services/auth'
// import { loginData } from '../../../services/Data_User';
import MaskedInputCpf from "../../utils/maskInput_cpf";
import MaskedInputCnpj from "../../utils/maskInput_cnpj";

import Menu from "../../components/Menu/Menu";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";

import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'

import "react-toastify/dist/ReactToastify.css";

import { cidades, estados } from "../../utils/optionsSelect";

import { toast } from "react-toastify";

toast.configure();

function CadastroEmpresa() {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [managerCpf, setManagerCpf] = useState("");
  const [managerName, setManagerName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setComfirmPassword] = useState("");
  const [city, setCity] = useState("");

  //setError - não é usada
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  //limpar dados do form
  function LimparForm() {
    setName("");
    setPassword("");
    setPhone("");
    setCity("");
    setCnpj("");
    setComfirmPassword("");
    setEmail("");
    setManagerCpf("");
    setManagerName("");

    setSegmentsChosen1("");
    setSegmentsChosen2("");
    setSegmentsChosen3("");
    setState("");
    setStreet("");
    setCep("");
    setPlaceNumber("");
    setLandmark("");
    setDistrict("");
    setWhatsapp("");
  }

  //values do label
  let percentageBusinessPartner = 0.2;


  //seguimentos de mercado
  // 1 - para map / 2 seguimento escolhido / 3 coloca o seguimento escolhido dentro de um vetor de strings
  const [segments, setSegments] = useState([]);
  const [segmentsChosen1, setSegmentsChosen1] = useState("");
  const [segmentsChosen2, setSegmentsChosen2] = useState("");
  const [segmentsChosen3, setSegmentsChosen3] = useState("");
  const [segmentsChosen4, setSegmentsChosen4] = useState("");
  let marketSegments = []

  function Seguiments(s1, s2, s3, s4) {
    if (s1 !== "" || s1 === 'Selecione...') {
      marketSegments.push(s1)
    }
    if (s2 !== "" || s2 === 'Selecione...') {
      marketSegments.push(s2)
    }
    if (s3 !== "" || s3 === 'Selecione...') {
      marketSegments.push(s3)
    }
    if (s4 !== "" || s4 === 'Selecione...') {
      marketSegments.push(s4)
    }

  }

  const percentageAgreement = {
    percentageBusinessPartner,
  };
  const token = getToken();

  const [street, setStreet] = useState('')
  const [placeNumber, setPlaceNumber] = useState('')
  const [state, setState] = useState('')
  const [cep, setCep] = useState('')
  const [landmark, setLandmark] = useState('')
  const [district, setDistrict] = useState('')

  const [whatsapp, setWhatsapp] = useState('')


  const address = {
    street,
    placeNumber,
    state,
    district,
    landmark,
    cep
  }
  //função conexao API
  async function handleCadEmpresa(e) {
    e.preventDefault();
    Seguiments(segmentsChosen1, segmentsChosen2, segmentsChosen3, segmentsChosen4)


    const data = {
      name,
      cnpj,
      managerCpf,
      managerName,
      phone,
      email,
      password,
      confirmPassword,
      percentageAgreement,
      address,
      city,
      marketSegments,
      whatsapp
    };
    console.log(data);
    try {
      const response = await api.post("api/v1/auth/stores/signup", data, {
        headers: { Authorization: token },
      });
      LimparForm();

      toast.success("empresa Cadastrada", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(response);
    } catch (err) {
      setLoading(false);

      if (!window.navigator.onLine) {
        toast.error(
          "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
          {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return;
      }

      if (err.message === "Network Error") {
        toast.error(
          "Os servidores da Lucrei encontram-se indisponíveis no momento. Prometemos retornar o mais breve possível.",
          {
            position: "top-right",
            autoClose: 7500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return;
      }

      const { status, data } = err.response;
      const { message } = data;

      if (status && status === 400) {
        if (
          message === "Duplicate field value: cpf. Please use another value!"
        ) {
          toast.error(
            "Este CPF ja esta cadastrado! Verifique seus dados ou realize login.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
        } else if (
          message === "Duplicate field value: email. Please use another value!"
        ) {
          toast.error(
            "Este Email ja esta cadastrado! Verifique seus dados ou realize login.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
        } else if (
          message === "Duplicate field value: phone. Please use another value!"
        ) {
          toast.error(
            "Este Telefone ja esta cadastrado! Verifique seus dados ou realize login.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
        }
      } else if (status === 500) {
        toast.error("Servidor Indisponível, entre em contato com a Kobe!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("Algo deu errado, verifique todos os dados ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
      }
    }
  }

  useEffect(() => {
    async function HandleSegments() {
      const response = await api
        .get("api/v1/segments", { headers: { Authorization: token } })
        .then((response) => setSegments(response.data));
      console.log(response);
    }
    HandleSegments();
    /* eslint-disable-next-line */
  }, []);
  /*
    Remover esse erro Acima -- Linha 112
    https://pt.stackoverflow.com/questions/389512/react-hook-useeffect-has-a-missing-dependency-either-include-it-or-remove
  */
  const history = useHistory();

  return (
    <div className="Register">
      <Menu
        title1="Dashboard"
        icon1={
          <img src={logo} alt="" with="25" height="25" />
        }
        onClick1={() => history.push("/admin/dash")}


        title2="Cadastrar Empresa"
        icon2={
          <img src={logo} alt="" with="25" height="25" />
        }
        onClick2={() => history.push("/admin/cadastro")}


        title3="Saques"
        icon3={<img src={logo} alt="" with="25" height="25" />}
        onClick3={() => history.push("/admin/saques")}

        title4="Depositos"
        icon4={<img src={logo} alt="" with="25" height="25" />}
        onClick4={() => history.push("/admin/depositos")}

      />
      <form id="cadastro" onSubmit={handleCadEmpresa}>
        <div>
          <h5 style={{ fontWeight: '700', fontSize: '2em' }}>CADASTRE A SUA EMPRESA</h5>

          <div className="infoEmpresa">
            <p>Informações da Empresa</p>
            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="name">Nome da Empresa:</label>
                </div>
                <input
                  className="campo"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="name"
                  name="name"
                  id="name"
                  placeholder="Ex: atacado"
                />
              </div>

              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="CNPJ">Informe o CNPJ:</label>
                </div>
                <MaskedInputCnpj
                  className="campo"
                  onChange={(e) => setCnpj(e.target.value)}
                  value={cnpj}
                  mask="99.999.999/9999-99"
                  maxLength="18"
                  type="text"
                  name="cnpj"
                  id="cnpj"
                  placeholder="Ex: 69.165.660/0001-28"
                />
              </div>
            </div>

            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="email">Informe o Email:</label>
                </div>
                <input
                  className="campo"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="exemplo@exemplo.com"
                />
              </div>


            </div>

            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="number">Telefone:</label>
                </div>
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  maxLength="16"
                  className="campo"
                  type="text"
                  id="number"
                  placeholder="(85)9 9999.9999"
                />
              </div>
              <div className="inputControl">
                <div className="Infomations">
                  <label>WhatsApp:</label>
                </div>
                <input
                  onChange={(e) => setWhatsapp(e.target.value)}
                  value={whatsapp}
                  maxLength="16"
                  className="campo"
                  type="text"
                  id="number"
                  placeholder="(85)9 9999.9999"
                />
              </div>
            </div>
          </div>

          <div className="infoResponsavel">
            <p>Informações do Responsável</p>
            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="managerName">Nome do Responsável:</label>
                </div>
                <input
                  className="campo"
                  onChange={(e) => setManagerName(e.target.value)}
                  value={managerName}
                  type="text"
                  name="managerName"
                  id="managerName"
                  placeholder="Ex: João Gomes"
                  required
                />
              </div>

              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="managerCpf">CPF do Responsável:</label>
                </div>

                <MaskedInputCpf
                  onChange={(e) => setManagerCpf(e.target.value)}
                  value={managerCpf}
                  className="campo"
                  mask="999.999.999-99"
                  type="text"
                  name="managerCpf"
                  id="managerCpf"
                  placeholder="Ex: 999.999.999-99"
                  required
                />
              </div>
            </div>
          </div>
          <div className="infoLocalização">
            <p>Seguimento da empresa</p>
            <div className="formInput">
              <div className="inputControl">
                <select
                  className="camposelect"
                  onChange={(e) => {
                    setSegmentsChosen1(e.target.value)

                  }}

                  placeholder="Selecione"
                  value={segmentsChosen1}
                >
                  <option value="" >
                    Selecione...
                  </option>
                  {segments?.map((item) => (
                    <option key={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>



              <div className="inputControl">
                <select
                  className="camposelect"
                  onChange={(e) => {
                    setSegmentsChosen2(e.target.value);

                  }}

                  placeholder="Selecione"
                  value={segmentsChosen2}
                >
                  <option value="" >
                    Selecione...
                  </option>
                  {segments?.map((item) => (
                    <option key={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>


            </div>

            <div className="formInput">
              <div className="inputControl">
                <select
                  className="camposelect"
                  onChange={(e) => {
                    setSegmentsChosen3(e.target.value);

                  }}

                  placeholder="Selecione"
                  value={segmentsChosen3}
                >
                  <option value="" >
                    Selecione...
                  </option>
                  {segments?.map((item) => (
                    <option key={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>



              <div className="inputControl">
                <select
                  className="camposelect"
                  onChange={(e) => {
                    setSegmentsChosen4(e.target.value);

                  }}

                  placeholder="Selecione"
                  value={segmentsChosen4}
                >
                  <option value="" >
                    Selecione...
                  </option>
                  {segments?.map((item) => (
                    <option key={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>


            </div>

          </div>

          <div className="infoLocalização">
            <p>Informações de Local</p>
            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="city">Cidade:</label>
                </div>
                <select
                  className="campo"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  name="city"
                  id="city"
                  placeholder="Ex: Fortaleza"
                >
                  <option value="" >
                    Selecione...
                  </option>
                  {cidades.sort().map((cidades) => (
                    <option key={cidades} value={cidades}>{cidades}</option>
                  ))}
                </select>
              </div>

              <div className="inputControl">
                <div className="Infomations">
                  <label>Endereço:</label>
                </div>
                <input
                  onChange={(e) => setStreet(e.target.value)}
                  value={street}
                  className="campo"
                  id="managerName"
                  placeholder="Av. / R. / Tv."

                />
              </div>
            </div>

            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label>Número:</label>
                </div>
                <input
                  onChange={(e) => setPlaceNumber(e.target.value)}
                  value={placeNumber}
                  className="campo"
                  type="text"
                  id="managerName"
                  placeholder="S/N"

                />
              </div>
              <div className="inputControl">
                <div className="Infomations">
                  <label>Bairro:</label>
                </div>
                <input
                  onChange={(e) => setDistrict(e.target.value)}
                  value={district}
                  className="campo"
                  type="text"
                  id="managerName"
                  placeholder=" "
                  required
                />
              </div>
            </div>

            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label>Estado:</label>
                </div>
                <select
                  className="campo"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  name="estado"
                  id="state"
                  placeholder="Ex: Ceará"
                  required
                >
                  <option value="">
                    Selecione...
                  </option>
                  {estados.sort().map((estados) => (
                    <option key={estados} value={estados}>{estados}</option>
                  ))}
                </select>
              </div>
              <div className="inputControl">
                <div className="Infomations">
                  <label>CEP:</label>
                </div>
                <input
                  onChange={(e) => setCep(e.target.value)}
                  value={cep}
                  className="campo"
                  type="text"
                  id="managerName"
                  placeholder="00000 - 000"
                  required
                />
              </div>
            </div>

            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label>Complemento:</label>
                </div>
                <input
                  onChange={(e) => setLandmark(e.target.value)}
                  value={landmark}
                  className="campo"
                  type="text"
                  id="managerName"
                  placeholder="Ponto de Referência"
                />
              </div>
            </div>
          </div>

          <div className="infoSenhas">
            <p>Senhas</p>
            <div className="formInput">
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="password">Crie Uma Senha:</label>
                </div>
                <input
                  className="campo"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="**********"
                />
              </div>
              <div className="inputControl">
                <div className="Infomations">
                  <label htmlFor="confirmPassword">Confirme Sua Senha:</label>
                </div>
                <input
                  className="campo"
                  onChange={(e) => setComfirmPassword(e.target.value)}
                  value={confirmPassword}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="**********"
                />
              </div>
            </div>
          </div>

          <div className="termosInput">
            <input
              className="termos"
              type="checkbox"
              id="termos"
              name="termos"
              placeholder="name"
            />
            <label htmlFor="termos">Li e Aceito os Termos?</label>
          </div>

          <div className="submitInput">
            <button
              className="submit"
              type="submit"
              id="submit"
              name="submit"
              value="CADASTRAR"
              placeholder="name"
            >
              {loading ? (
                <CircularProgress color="primary" size={21} />
              ) : (
                "Cadastrar"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CadastroEmpresa;
