import { useState } from "react";
import "./style.scss";
import api from "../../services/api";

import Logo from "../../assets/LOGO.svg";
import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import {
    FaCreditCard,
    FaMedal,
    FaRegCreditCard,
    FaUserLock,
} from "react-icons/fa";
import { MdOutlinePointOfSale } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlineSendToMobile } from "react-icons/md";

import { useEffect } from "react";
import { formatPhoneNumber } from "../../utils/format-phone";

export default function StoreMonthlyReport() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setIsLoading] = useState(false);

    const [vendasAvulsas, setVendasAvulsas] = useState([]);

    useEffect(() => {
        async function getVendasAvulsasPorPeriodo() {
            try {
                setIsLoading(true);
                const response = await api.get(
                    `api/v1/dashboard/admin/monthly-report/ID/separate-sales?month=11&year=2023`,
                    {
                        headers: { Authorization: token },
                    }
                );
                console.log(response.data);
                setVendasAvulsas(response.data.data);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }
        //getVendasAvulsasPorPeriodo();
    }, [token]);

    return (
        <div className="default-print-layout">
            <div className="quick-access-buttons">
                {/* <Link
                        to="/admin/dash"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link> */}
                {/* <Link
                        to="/admin/novo-cupom"
                        className="btn btn-primary"
                        role="button"
                        disabled={true}
                    >
                        Ir para Busca Usuários Empresas
                    </Link> */}
            </div>
            <div className="report-heading">
                <h1>RELATÓRIO MENSAL - NOVEMBRO/23</h1>
                <p>Granitares Marmoraria</p>
            </div>

            <div className="section-title">
                <h6>VENDAS</h6>
                <hr />
            </div>
            <div className="row cards-row">
                <div className="col-4">
                    <div className="card">
                        <div className="title-wrapper">
                            <span>TOTAL VENDAS</span>
                            <div className="icon-wrapper sales-icon">
                                <MdOutlinePointOfSale />
                            </div>
                        </div>
                        <span className="main-text">
                            {vendasAvulsas.length || 0}
                        </span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="title-wrapper">
                            <span>TOTAL VENDIDO</span>
                            <div className="icon-wrapper sales-icon">
                                <FaRegMoneyBillAlt />
                            </div>
                        </div>
                        <span className="main-text">R$ 21.948,00</span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="title-wrapper">
                            <span>TICKET MÉDIO</span>
                            <div className="icon-wrapper sales-icon">
                                <IoTicketOutline />
                            </div>
                        </div>
                        <span className="main-text">R$ 708,00</span>
                    </div>
                </div>
            </div>

            <div className="section-title">
                <h6>VENDAS E TOTAL VENDIDO POR MEIO DE PAGAMENTO</h6>
                <hr />
            </div>
            <div className="row">
                <div className="col">
                    <div className="card payment-card">
                        <div className="title-wrapper">
                            <span>PIX</span>
                            <div className="icon-wrapper">
                                <MdOutlineSendToMobile />
                            </div>
                        </div>
                        <span className="main-text">R$ 6.367,00</span>
                        <span className="secondary-text">12 VENDAS</span>
                    </div>
                </div>
                <div className="col">
                    <div className="card payment-card">
                        <div className="title-wrapper">
                            <span>CART. CRÉDITO</span>
                            <div className="icon-wrapper">
                                <FaRegCreditCard />
                            </div>
                        </div>
                        <span className="main-text">R$ 4.881,00</span>
                        <span className="secondary-text">7 VENDAS</span>
                    </div>
                </div>
                <div className="col">
                    <div className="card payment-card">
                        <div className="title-wrapper">
                            <span>CART. DÉBITO</span>
                            <div className="icon-wrapper">
                                <FaCreditCard />
                            </div>
                        </div>
                        <span className="main-text">R$ 2.898,00</span>
                        <span className="secondary-text">4 VENDAS</span>
                    </div>
                </div>
                <div className="col">
                    <div className="card payment-card">
                        <div className="title-wrapper">
                            <span>DINHEIRO</span>
                            <div className="icon-wrapper">
                                <FaRegMoneyBillAlt />
                            </div>
                        </div>
                        <span className="main-text">R$ 7.337,00</span>
                        <span className="secondary-text">7 VENDAS</span>
                    </div>
                </div>
            </div>
            <div className="message-payment mt-2">
                <p>
                    * <span>1 venda no valor de R$ 465,00</span> não teve o
                    método de pagamento informado.
                </p>
            </div>

            <div className="section-title">
                <h6>CATEGORIAS MAIS VENDIDAS</h6>
                <hr />
            </div>
            <div className="row cards-row">
                <div className="col-4">
                    <div className="card payment-card h-100 category">
                        <div className="title-wrapper">
                            <span>1º LUGAR</span>
                            <div className="icon-wrapper gold">
                                <FaMedal />
                            </div>
                        </div>
                        <span className="main-text">
                            CUBA CASCATA SAO GABRIEL, LAVATORIO 1.50X 0.50 COM
                            ESPELHO 20CM
                        </span>
                        <span className="secondary-text">3 VENDAS</span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card h-100 category">
                        <div className="title-wrapper">
                            <span>2º LUGAR</span>
                            <div className="icon-wrapper silver">
                                <FaMedal />
                            </div>
                        </div>
                        <span className="main-text">
                            5 SUPORTES, 2 SUPORTES, VERDE UBATUBA
                        </span>
                        <span className="secondary-text">2 VENDAS</span>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card h-100 category">
                        <div className="title-wrapper">
                            <span>3º LUGAR</span>
                            <div className="icon-wrapper bronze">
                                <FaMedal />
                            </div>
                        </div>
                        <span className="main-text">
                            CUBA DE CASCATA NO TRAVERTINO
                        </span>
                        <span className="secondary-text">1 VENDAS</span>
                    </div>
                </div>
            </div>

            {isLoading && <Loader />}

            <div className="section-title">
                <h6>VENDAS - MÊS NOVEMBRO/23</h6>
                <hr />
            </div>
            <div className="tabela mt-4">
                {vendasAvulsas?.length > 0 && (
                    <table className="table table-striped mt-4">
                        <thead>
                            <tr>
                                <th scope="col">DATA</th>

                                <th scope="col">CATEGORIA(S)</th>
                                <th scope="col">VALOR</th>
                                <th scope="col">PAGAMENTO</th>
                                <th scope="col">CLIENTE</th>
                                <th scope="col">TEL. CLIENTE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendasAvulsas?.map((sale) => (
                                <tr key={sale._id}>
                                    <td>
                                        {new Date(
                                            sale.createdAt
                                        ).toLocaleString("pt-br")}
                                    </td>
                                    <td scope="row">
                                        {sale.categories.map((category) => (
                                            <span className="category-name">
                                                {category.name}
                                            </span>
                                        ))}
                                    </td>
                                    <td scope="row">
                                        R${" "}
                                        {sale.value.toLocaleString(
                                            "pt-br",
                                            {
                                                minimumFractionDigits: 2,
                                            },
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}
                                    </td>

                                    <td>{sale.paymentType || "-"}</td>

                                    <td>
                                        {sale?.customerName ||
                                            sale?.leadCustomerName ||
                                            "-"}
                                    </td>
                                    <td>{sale.customerPhone || "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {vendasAvulsas?.length === 0 && (
                    <p className="text-center mt-4">
                        Nenhuma venda cadastrada no período selecionado.
                    </p>
                )}
                <img src={Logo} alt="Logo" className="logo" />
            </div>
        </div>
    );
}
