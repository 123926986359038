import React from 'react'

import './style.scss'
export default function endereçoEmpresa({ store, address }) {
   return (
      <div className="containerAddress">


         <div className="address1">
            <p id="labelInfo">Rua:</p>
            <input
               value={address.street}
               disabled
               id="inputAddress"
            />
            <p id="labelInfo" >Número:</p>
            <input
               value={address.placeNumber}
               id="inputAddress"
               disabled
            />
            <p id="labelInfo" >Bairro:</p>
            <input
               value={address.district}
               id="inputAddress"
               disabled
            />
         </div>

         <div className="address2">
            <p id="labelInfo" >Cidade:</p>
            <input
               value={store.city}
               id="inputAddress"
               disabled
            />
            <p id="labelInfo">Estado:</p>
            <input
               value={address.state}
               disabled
               id="inputAddress"
            />
            <p id="labelInfo" >CEP:</p>
            <input
               value={address.cep}
               id="inputAddress"
               disabled
            />
         </div>

      </div>
   )


}
