import {

} from "react-router-dom";
import {

   useState
} from "react";



import formatCurrency from "../../utils/format-currency"

import './style.scss'

import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';


import Deposit from "../../components/Deposito/index"

const useStyles = makeStyles((theme) => ({
   dados1: {

      padding: 10,
      '& .MuiTextField-root': {
         margin: theme.spacing(1),
         width: 'auto',

      },
   },
   dados2: {

      padding: 10,
      '& .MuiTextField-root': {
         margin: theme.spacing(1),
         width: 'auto',

      },
   },




}));
export default function DadosEmpresa({ store, account }) {
   const [showDeposit, setShowDeposit] = useState(false);
   const classes = useStyles();

   return (
      <div className="dadosEmpresa">
         {showDeposit && (
            <Deposit
               setShowDepositModal={setShowDeposit}
               showDepositModal={showDeposit}
            />
         )}
         <div className='container'>
            <div className="logo">
               <img
                  src={`${store.avatar}`}
                  alt="Imagem da loja"
               />
            </div>
            <div className={classes.dados1}>
               <div className="person" maxWidth="sm">
                  <InputLabel htmlFor="input-with-icon-adornment"> Noma do Responsavel</InputLabel>
                  <TextField
                     className="Alinhamento"
                     value={store.managerName}
                     disabled
                     id="outlined-disabled"
                     variant="standard"
                  />
                  <InputLabel htmlFor="input-with-icon-adornment" >CPF DO Responsavel</InputLabel>
                  <TextField
                     value={store.managerCpf}
                     className='Alinhamento'
                     id="outlined-disabled"
                     disabled
                     variant="standard"
                  />
                  <section className="sectionButton">
                     <div className="btnComissao">
                        <button className="comissao" onClick={() =>
                           setShowDeposit(true)
                        }>
                           <div className="icon_valueInLine">
                              <p>
                                 Solicitar Deposito:
                                 <br />
                                 {`R$ ${formatCurrency(store.amountToPay)}`}
                              </p>
                           </div>
                        </button>
                     </div>

                     <div className="btnShop">
                        <button className="shopLucrei" >
                           <div className="icon_valueInLine">
                              <p>
                                 Shop Lucrei
                              </p>
                           </div>
                        </button>
                     </div>
                  </section>
               </div>
            </div>



            <div className={classes.dados1}>
               <div className="store" maxWidth="sm">
                  <InputLabel htmlFor="input-with-icon-adornment">Noma da empresa:</InputLabel>

                  <TextField
                     value={store.name}
                     disabled
                     className="Alinhamento"
                     variant="standard"
                     id="outlined-disabled"
                  />
                  <InputLabel htmlFor="input-with-icon-adornment" >CNPJ:</InputLabel>
                  <TextField
                     value={store.cnpj}
                     className="Alinhamento"
                     id="outlined-disabled"
                     disabled
                     variant="standard"
                  />
                  <InputLabel htmlFor="input-with-icon-adornment">Email:</InputLabel>
                  <TextField
                     value={account.email}
                     className="Alinhamento"
                     id="outlined-disabled"
                     disabled
                     variant="standard"
                  />
                  <InputLabel htmlFor="input-with-icon-adornment">Telefone:</InputLabel>
                  <TextField
                     value={account.phone}
                     className="Alinhamento"
                     id="outlined-disabled"
                     disabled
                     variant="standard"
                  />
                  <InputLabel htmlFor="input-with-icon-adornment">Whatsapp:</InputLabel>
                  <TextField
                     value={store.whatsapp}
                     className="Alinhamento"
                     id="outlined-disabled"
                     disabled
                     variant="standard"
                  />
               </div>
            </div>



            <div>
            </div>

         </div>
      </div>


   )


}
