import { useEffect, useState } from "react";
import "./style.scss";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import { MdDelete, MdReceiptLong } from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import Loader from "../../components/Loader";
import ConfirmationDeleteCouponModal from "../../components/ConfirmationDeleteCouponModal";

export default function CupomListPage() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [storeId, setStoreId] = useState("");
    const [description, setDescription] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [status, setStatus] = useState("ATIVO");
    const [coupons, setCoupons] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [coupon, setCoupon] = useState();

    async function getAllCupons() {
        try {
            setIsLoading(true);
            const response = await api.get("api/v1/coupons", {
                headers: { Authorization: token },
            });
            setCoupons(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteCoupon = async (id) => {
        try {
            setIsLoading(true);
            const response = await api.delete(`api/v1/coupons/${id}`, {
                headers: { Authorization: token },
            });

            getAllCupons();
        } catch (error) {
            console.log({ error });
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const handleDisplayConfirmationModal = async (coupon) => {
        setShowConfirmationModal(true);
        setCoupon(coupon);
    };

    useEffect(() => {
        async function getCouponsWithPagination() {
            try {
                const params = statusFilter ? `&status=${statusFilter}` : ``;
                setIsLoading(true);
                const response = await api.get(
                    `api/v1/coupons?pageNumber=${currentPage}${params}`,
                    {
                        headers: { Authorization: token },
                    }
                );
                setCoupons(response.data.data);
                setTotalPages(response.data.pages);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }
        /** aqui no useEffect currentPage já está com o valor atualizado */

        getCouponsWithPagination();
    }, [currentPage, token]);

    function getBadgeByCouponStatus(status) {
        if (status === "ATIVO")
            return <span className="badge bg-success">{status}</span>;
        else if (status === "EXPIRADO")
            return <span className="badge bg-danger">{status}</span>;
        else if (status === "INATIVO")
            return <span className="badge bg-primary">{status}</span>;
        else return <span className="badge bg-dark">{status}</span>;
    }

    const handleFilterByStatus = async () => {
        try {
            const params = statusFilter ? `&status=${statusFilter}` : ``;
            setIsLoading(true);
            const response = await api.get(
                `api/v1/coupons?pageNumber=1${params}`,
                {
                    headers: { Authorization: token },
                }
            );
            setCoupons(response.data.data);
            setTotalPages(response.data.pages);
            setCurrentPage(1);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="container default-page">
                <div className="quick-access-buttons">
                    <Link
                        to="/admin/cupons"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    <Link
                        to="/admin/novo-cupom"
                        className="btn btn-primary"
                        role="button"
                    >
                        Ir para Cadastro de Cupons
                    </Link>
                </div>
                <p className="promoHeader">Listagem de Cupons</p>
                <hr />
                {isLoading && <Loader />}
                <div className="tabela">
                    <div className="filter-wrapper">
                        <div className="form-">
                            <select
                                className="form-select"
                                value={statusFilter}
                                disabled={isLoading}
                                onChange={({ target }) =>
                                    setStatusFilter(target.value)
                                }
                            >
                                <option value="">Todos</option>
                                <option value="ATIVO">Ativo</option>
                                <option value="EXPIRADO">Expirado</option>
                                <option value="INATIVO">Inativo</option>
                                <option value="CANCELADO">Cancelado</option>
                            </select>
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={handleFilterByStatus}
                        >
                            Filtrar por status
                        </button>
                    </div>
                    {coupons?.length > 0 && (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">-</th>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Preço Original</th>
                                    <th scope="col">Desconto %</th>
                                    <th scope="col">Expira em</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Criado por</th>

                                    <th scope="col">
                                        <BsPencilSquare />
                                    </th>
                                    <th scope="col">
                                        <MdDelete />
                                    </th>
                                    <th scope="col">
                                        <MdReceiptLong />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons?.map((coupon) => (
                                    <tr key={coupon._id}>
                                        <td scope="row">
                                            <img
                                                src={coupon.storeLogo}
                                                alt="Logo da Empresa"
                                                width={54}
                                                height={54}
                                            />
                                        </td>
                                        <td>{coupon.store.name}</td>
                                        <td
                                            style={
                                                coupon?.description?.length > 20
                                                    ? { fontSize: "12px" }
                                                    : { fontSize: "14px" }
                                            }
                                        >
                                            {coupon.description}
                                        </td>
                                        <td>
                                            {coupon.originalPrice
                                                ? `R$ ${coupon.originalPrice.toLocaleString(
                                                      "pt-br",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      },
                                                      {
                                                          style: "currency",
                                                          currency: "BRL",
                                                      }
                                                  )}`
                                                : "-"}
                                        </td>
                                        <td>
                                            {Number(
                                                coupon.discountPercentage * 100
                                            ).toFixed(0)}
                                            %
                                        </td>

                                        <td>
                                            {new Date(
                                                coupon.expirationDate.slice(
                                                    0,
                                                    10
                                                )
                                            ).toLocaleDateString(undefined, {
                                                timeZone: "UTC",
                                            })}
                                        </td>
                                        <td>
                                            {getBadgeByCouponStatus(
                                                coupon.status
                                            )}
                                        </td>
                                        <td>{coupon.createdBy}</td>
                                        <td>
                                            <Link
                                                role="button"
                                                className="btn btn-info btn-lucrei-alt"
                                                to={`cupons/${coupon._id}`}
                                            >
                                                Atualizar
                                            </Link>
                                        </td>
                                        <td>
                                            {/**
                                         *
                                            ISSO DEVE SER UM MODAL COM CONFIRMAÇÃO

                                         */}
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    handleDisplayConfirmationModal(
                                                        coupon
                                                    )
                                                }
                                            >
                                                Excluir
                                            </button>
                                        </td>
                                        <td>
                                            <Link
                                                role="button"
                                                className="btn btn-primary"
                                                to={`cupons/${coupon._id}/cupons-gerados`}
                                            >
                                                Ver cupons gerados
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {totalPages > 1 && (
                        <div className="pagination-wrapper">
                            <button
                                className="btn btn-primary"
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Anterior
                            </button>
                            <p>
                                {currentPage} de {totalPages}
                            </p>

                            <button
                                className="btn btn-primary"
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Próxima
                            </button>
                        </div>
                    )}
                    {coupons?.length === 0 && (
                        <p className="text-center">Sem cupons para exibir.</p>
                    )}
                </div>
            </div>
            {showConfirmationModal && (
                <ConfirmationDeleteCouponModal
                    onCancel={() => setShowConfirmationModal(false)}
                    onConfirm={() => handleDeleteCoupon(coupon._id)}
                    coupon={coupon}
                />
            )}
        </div>
    );
}
