import React, { useCallback, useState } from "react";
import {} from "../..";
import "./style.css";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";

const RedefinePasswordModal = ({ onCancel, onConfirm, customer }) => {
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const handleConfirmation = () => {
        if (
            password !== passwordConfirmation ||
            password.length < 6 ||
            passwordConfirmation.length < 6
        ) {
            toast.error(
                `As senhas devem ser iguais e maiores que 6 caracteres.`,
                {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            console.log(password, passwordConfirmation);
            return;
        } else {
            onCancel();
            onConfirm(customer.email, password);
        }
    };

    return (
        <div className="ConfirmationDeleteCouponModal">
            <form className="bonus-form">
                <h5 className="text-center mb-3">Redefinir senha para:</h5>

                <div className="form-group my-2">
                    <label htmlFor="nome">Nome</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nome"
                        value={customer.name}
                        disabled={true}
                    />
                </div>
                <div className="form-group my-2">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={customer.email}
                        disabled={true}
                    />
                </div>
                <div className="form-group my-2">
                    <label htmlFor="password">Nova senha</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                    />
                </div>
                <div className="form-group my-2">
                    <label htmlFor="confirmPassword">
                        Confirme a nova senha
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={passwordConfirmation}
                        onChange={({ target }) =>
                            setPasswordConfirmation(target.value)
                        }
                    />
                </div>

                <div className="row mt-4">
                    <div className="col">
                        <button
                            tabIndex={1}
                            className="btn btn-danger"
                            type="button"
                            onClick={onCancel}
                        >
                            Cancelar
                        </button>
                    </div>

                    <div className="col">
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={handleConfirmation}
                        >
                            Redefinir senha
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RedefinePasswordModal;
