import React, { useEffect, useState, useCallback } from "react";

import "./style.scss";

import api from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import formatCurrency from "../../utils/format-currency";
import CloseIcon from "@material-ui/icons/Close";

toast.configure();

const Deposit = ({
  token,
  setShowDepositModal,
  showDepositModal,
  getLoggedUser,
}) => {
  const [password, setPassword] = useState("");




  const ID_EMPRESA = "id"
  const id = localStorage.getItem(ID_EMPRESA)
  async function CreateDeposit(e) {
    try {
      e.preventDefault();
      const data = {
        password,
      };

        const response = await api.post(`/api/v1/stores/${id}/deposits`, data, {
          headers: { Authorization: token },
        })

      setShowDepositModal((prev) => !prev);

      if (response.status === 201 || response.status === 200 ) {
        getLoggedUser();
        toast.success(
          "Solicitação de Deposito criada com sucesso! Em breve entraremos em contato :)",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
          }
        );
      }
    } catch (error) {
      const { status } = error.response;

      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Unable to create a new deposit. You have a pending deposit."
      ) {
        toast.error(
          "Caro usuário, você já possui uma solicitação de Deposito Pedente. Entre em contato com a Kobe para maiores informações.",
          {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      }

      if (status === 403) {
        toast.error(
          "Senha incorreta! Verifique seus dados e tente novamente.",
          {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      }

      if (status === 500) {
        toast.error("Erro de servidor! Entre em contato com a Kobe", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  }

  const keyPress = useCallback(
    (e) => {
      console.log("KEY", e.key);
      console.log("showWith", showDepositModal);
      if (e.key === "Escape" && showDepositModal) {
        setShowDepositModal(false);
      }
    },
    [showDepositModal, setShowDepositModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);

    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

 // const { innerHeight, innerWidth } = window;

  const DEPOSIT = "@DEPOSIT";
  const deposit = localStorage.getItem(DEPOSIT);
  return (
    <div className="ModalDeposito">
      <div className="ModalWrapper">
        <form onSubmit={CreateDeposit} className="DepositForm">
          <button
            className="closeButton"
            onClick={() => setShowDepositModal(false)}
          >
            <CloseIcon />
          </button>

          <div>
            <div className="valueInfo">
              <label>Valor de depósito:</label>
              <h2>{`R$ ${formatCurrency(deposit)}`}</h2>
            </div>
            <div className="group">
              <label>
                <span>Confirme sua senha:</span>
                <input
                  className="formInput"
                  type="password"
                  //className="formInput"
                  placeholder="**********"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </label>
            </div>
            <div className="buttonGroup">
              <button type="submit" className="buttonConfirm">
                Depositar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Deposit;
