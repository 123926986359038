import React from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";

// import data from "../../dados/cap_1103.json";

import api from "../../services/api";

const CustomersSingleLoginReportPage = () => {
    const history = useHistory();
    const [city, setCity] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const token = getToken();

    const handleCreatedAtChange = ({ target }) => {
        setCreatedAt(target.value);
    };

    const getTopSellersData = async () => {
        try {
            if (city === "") return;

            const response = await api.get(
                `api/v1/sales/top-sellers?city=${city}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;
            setCity("");
            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }
            console.log(response);

            generateReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getCustomersReportByCreatedAt = async () => {
        try {
            const data = [];
            generateCustomersInfoReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const generateReport = (topSellersData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO VENDAS POR EMPRESA - ${city.toUpperCase()} (${currentDate.toLocaleDateString()})`;

        const headers = [
            ["EMPRESA", "NÚMERO TOTAL DE VENDAS", "TOTAL VENDIDO (R$)"],
        ];

        const data = topSellersData.map((elt) => [
            elt.empresa.name,
            elt.totalVendas,
            elt.totalVendido,
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateFilename(city, currentDate);
        doc.save(`${filename}.pdf`);
    };

    const generateCustomersInfoReport = (customersInfo) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO CLIENTES - UNICO LOGIN`;

        const headers = [
            [
                "NOME",
                "TELEFONE",
                "EMAIL",
                "CIDADE",
                "CADASTRO",
                "ULTIMO ACESSO",
            ],
        ];

        const data = customersInfo.map((elt) => [
            elt.nome,
            elt.phone,
            elt.email,
            elt.cidade,
            new Date(elt.criado_em).toLocaleString("pt-br"),
            new Date(elt.atualizado_em).toLocaleString("pt-br"),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [0, 156, 96], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const { day, month, year } = formatDate(currentDate);
        const filename = `RCC-${day}${month}${year}`;

        doc.save(`${filename}.pdf`);
    };

    const generateFilename = (city, date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return `RV-${city.toUpperCase()}-${day}${month}${year}`;
    };

    const formatDate = (date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return { day, month, year };
    };

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                {/* <p>
                    Selecione a cidade para obter o número de vendas das suas
                    empresas.
                </p>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={city}
                >
                    <option selected value="">
                        Selecionar cidade
                    </option>
                    <option value="Aracoiaba">Aracoiaba</option>
                    <option value="Capistrano">Capistrano</option>
                </select> */}

                <hr />
                <div className="customer-input-wrapper">
                    <input
                        type="date"
                        name="createdAt"
                        id="createdAt"
                        value={createdAt}
                        onChange={handleCreatedAtChange}
                        className="form-control"
                        min={"2021-03-01"}
                    />
                    <button
                        className="btn btn-success m-1 btn-customers-report"
                        onClick={getCustomersReportByCreatedAt}
                    >
                        GERAR RELATÓRIO DE CLIENTES PELA DATA ESCOLHIDA
                    </button>
                </div>

                <div className="row">
                    {showResultsMessage && (
                        <p className="text-center message">
                            A cidade selecionada ainda não possui registros de
                            vendas!
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomersSingleLoginReportPage;
