import { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import "./style.scss";
import api from "../../services/api";

import { useHistory, Link, useParams } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import { MdDelete, MdReceiptLong } from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";

export default function CreatedCustomerCouponsListPage() {
    const history = useHistory();
    const token = getToken();
    const couponId = useParams().id;

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParentCoupon, setIsLoadingParentCoupon] = useState(false);
    const [storeId, setStoreId] = useState("");
    const [description, setDescription] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [status, setStatus] = useState("ATIVO");
    const [statusFilter, setStatusFilter] = useState("");
    const [coupons, setCoupons] = useState([]);
    const [parentCoupon, setParentCoupon] = useState();
    const [totalCoupons, setTotalCoupons] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    async function getAllCupons() {
        try {
            setIsLoading(true);
            const response = await api.get(
                `api/v1/coupons/${couponId}/customer-coupons`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log(response);
            setCoupons(response.data.data);

            setTotalCoupons(response.data.totalItems);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function getParentCoupon() {
        try {
            setIsLoading(true);
            const response = await api.get(`api/v1/coupons/${couponId}`, {
                headers: { Authorization: token },
            });
            console.log(response);
            setParentCoupon(response.data.data);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // getAllCupons();
        getParentCoupon();
    }, []);

    useEffect(() => {
        async function getCustomerCouponsWithPagination() {
            try {
                const params = statusFilter ? `&status=${statusFilter}` : ``;
                setIsLoading(true);
                const response = await api.get(
                    `api/v1/coupons/${couponId}/customer-coupons?pageNumber=${currentPage}${params}`,
                    {
                        headers: { Authorization: token },
                    }
                );
                setCoupons(response.data.data);
                setTotalPages(response.data.pages);
                setTotalCoupons(response.data.totalItems);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }
        /** aqui no useEffect currentPage já está com o valor atualizado */

        getCustomerCouponsWithPagination();
    }, [currentPage, token]);

    function getBadgeByCouponStatus(status) {
        if (status === "VÁLIDO")
            return <span className="badge bg-success">{status}</span>;
        else if (status === "EXPIRADO")
            return <span className="badge bg-danger">{status}</span>;
        else if (status === "ATIVO")
            return <span className="badge bg-primary">{status}</span>;
        else return <span className="badge bg-dark">{status}</span>;
    }

    const handleFilterByStatus = async () => {
        try {
            const params = statusFilter ? `&status=${statusFilter}` : ``;
            setIsLoading(true);
            const response = await api.get(
                `api/v1/coupons/${couponId}/customer-coupons?pageNumber=1${params}`,
                {
                    headers: { Authorization: token },
                }
            );

            setCoupons(response.data.data);
            setTotalPages(response.data.pages);
            setCurrentPage(1);
        } catch (error) {
            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="container default-page">
                <div className="quick-access-buttons">
                    <Link
                        to="/admin/lista-cupons"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    <Link
                        to="/admin/novo-cupom"
                        className="btn btn-primary"
                        role="button"
                    >
                        Ir para Cadastro de Cupons
                    </Link>
                </div>

                {
                    <div className="card mt-4 p-3">
                        {parentCoupon?.storeLogo && (
                            <img
                                src={parentCoupon.storeLogo}
                                alt="Logo da empresa"
                                width={128}
                                height={128}
                            />
                        )}
                        {parentCoupon && (
                            <span>
                                {getBadgeByCouponStatus(parentCoupon.status)}
                            </span>
                        )}
                        <div className="card-body">
                            {parentCoupon?.description && (
                                <h5 className="card-title">
                                    {parentCoupon?.description}
                                </h5>
                            )}
                            {totalCoupons && (
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Total de cupons gerados: {totalCoupons}
                                </h6>
                            )}
                            {parentCoupon && (
                                <p className="card-text">
                                    Expira em:{" "}
                                    {new Date(
                                        parentCoupon?.expirationDate
                                    ).toLocaleDateString(undefined, {
                                        timeZone: "UTC",
                                    })}
                                </p>
                            )}
                            {parentCoupon && (
                                <p className="card-text">
                                    Desconto:{" "}
                                    {parentCoupon.discountPercentage * 100}%
                                </p>
                            )}
                        </div>
                    </div>
                }

                {isLoading && <Loader />}
                <div className="tabela mt-4">
                    {
                        <div className="filter-wrapper">
                            <div className="form-group">
                                <select
                                    className="form-select"
                                    value={statusFilter}
                                    disabled={isLoading}
                                    onChange={({ target }) =>
                                        setStatusFilter(target.value)
                                    }
                                >
                                    <option value="">Todos</option>
                                    <option value="VÁLIDO">Válido</option>
                                    <option value="USADO">Usado</option>
                                    <option value="EXPIRADO">Expirado</option>
                                    <option value="INVÁLIDO">Inválido</option>
                                </select>
                            </div>
                            <button
                                className="btn btn-primary"
                                onClick={handleFilterByStatus}
                            >
                                Filtrar por status
                            </button>
                        </div>
                    }

                    {coupons?.length > 0 && (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Código</th>
                                    <th scope="col">Gerado por</th>
                                    <th scope="col">Telefone Usuário</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Gerado em</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons?.map((coupon) => (
                                    <tr key={coupon._id}>
                                        <td>{coupon.code}</td>
                                        <td
                                            style={
                                                coupon?.customerName?.length >
                                                20
                                                    ? { fontSize: "12px" }
                                                    : { fontSize: "14px" }
                                            }
                                        >
                                            {coupon.customerName}
                                        </td>
                                        <td>{coupon.customerPhone}</td>
                                        <td>
                                            {getBadgeByCouponStatus(
                                                coupon.status
                                            )}
                                        </td>
                                        <td>
                                            {new Date(
                                                coupon.createdAt
                                            ).toLocaleDateString("pt-br")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {/* PAGINATION */}
                    {totalPages > 1 && (
                        <div className="pagination-wrapper">
                            <button
                                className="btn btn-primary"
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Anterior
                            </button>
                            <p>
                                {currentPage} de {totalPages}
                            </p>

                            <button
                                className="btn btn-primary"
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Próxima
                            </button>
                        </div>
                    )}

                    {coupons?.length === 0 && (
                        <p className="text-center">Sem cupons para exibir.</p>
                    )}
                </div>
            </div>
        </div>
    );
}
