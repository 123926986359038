import {
    useHistory
  } from "react-router-dom";
  import {
    useEffect,
    useState
  } from "react";
  import api from "../../services/api";
  import {
    getToken
  } from "../../services/auth";


  import Menu from "../../components/Menu/Menu";
  import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'
  import DepositTable from "../../components/admin/RequestDeposit/index"
  import Pagination from "../../components/Pagination";

  import './style.scss'

  export default function Deposit() {



    const token = getToken();

    const history = useHistory();


const [deposits, setDeposits] = useState([]);
const [currentPageDeposits, setCurrentPageDeposits] = useState(1);
const [totalPagesDeposits, setTotalPagesDeposits] = useState(1);



    const loadDeposits = async () => {
        const response = await api.get(
          `api/v1/deposits?sortBy=-createdAt&pageNumber=${currentPageDeposits}`,
          {
            headers: { Authorization: token },
          }
        );

        setDeposits(response.data);
        setTotalPagesDeposits(response.data.pages);
      };

      useEffect(() => {
        loadDeposits();

        /* eslint-disable-next-line */
      }, [currentPageDeposits]);


     useEffect(() => {
         loadDeposits()

    /* eslint-disable-next-line */
  }, []);


    return (
      <div className="containerDeposit">

     <div className="menu">
     <Menu
         title1="Dashboard"
         icon1={
           <img src={logo} alt="" with="25" height="25" />
         }
         onClick1={() => history.push("/admin/dash")}


        title2="Cadastrar Empresa"
        icon2={
          <img src={logo} alt="" with="25" height="25" />
        }
        onClick2={() => history.push("/admin/cadastro")}


        title3="Saques"
        icon3={<img src={logo} alt="" with="25" height="25" />}
        onClick3={() => history.push("/admin/saques")}

        title4="Depositos"
        icon4={<img src={logo} alt="" with="25" height="25" />}
        onClick4={() => history.push("/admin/depositos")}

      />
     </div>
     <div className="tableDeposit">

     {deposits.data && deposits.data.length > 0 && (
          <DepositTable deposits={deposits.data} loadDeposits={loadDeposits} />
        )}
        <Pagination
          currentPage={currentPageDeposits}
          totalPages={totalPagesDeposits}
          paginate={setCurrentPageDeposits}
        />

  </div>

  </div>
    );
  }
