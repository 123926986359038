import React, { useCallback, useState } from "react";
import {} from "../..";
import "./style.css";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import ConfirmationReceiptAcceptModal from "../ConfirmationReceiptAcceptModal";

const defaultFormData = {
    cashback: "",
    password: "",
};

const ReceiptStatusModal = ({
    onCancel,
    id,
    currentStatus,
    getReceipts,
    receiptValue,
    receiptPhoto,
}) => {
    console.log("receiptPhoto", receiptPhoto);
    const [formData, setFormData] = useState({
        ...defaultFormData,
        receiptValue: receiptValue,
    });
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("PENDENTE");
    const [denyDetails, setDenyDetails] = useState("Selecionar");
    const defaultDenyDetails = [
        "Selecionar",
        "Nota já registrada",
        "Ausência de dados do estabelecimento da compra",
        "Data da compra não visível",
        "Foto sem dados visíveis",
        "Código fiscal invalido pela Sefaz",
        "Código inválido",
        "Codigo de barras/QR code sem visibilidade",
        "Data inválida",
        "Valor não compatível com a nota",
        "Envio não válido como nota fiscal",
        "Data superior a 2 dias da emissão, conforme estabelecido no regulamento",
        "Nota fiscal de estabelecimento fora do Maciço de Baturité",
        "Outro",
    ];
    const [denyOption, setDenyOption] = useState("");
    const [textareaValue, setTextareaValue] = useState("");
    const [satValue, setSatValue] = useState("");
    const [satStatusMessage, setSatStatusMessage] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const token = getToken();

    const handleInputChange = ({ target }) => {
        setFormData({ ...formData, [target.name]: target.value });
    };

    const showCashbackAmountGiven = (cashback) => {
        if (cashback === 0) return `Valor de cashback dado: R$0.00`;
        else
            return `Valor de cashback dado: R$${(cashback * 1)
                .toFixed(2)
                .toLocaleString(
                    "pt-br",
                    { minimumFractionDigits: 2 },
                    { style: "currency", currency: "BRL" }
                )}`;
    };

    const showCashbackPercentageGiven = (cashback) => {
        console.log(cashback, receiptValue);
        if (cashback === 0) return "";
        else
            return `${((cashback / receiptValue) * 100)
                .toFixed(2)
                .toLocaleString(
                    "pt-br",
                    { minimumFractionDigits: 2 },
                    { style: "currency", currency: "BRL" }
                )}`;
    };

    const handleTextareaValueChange = useCallback(({ target }) => {
        setTextareaValue(target.value);
    }, []);

    const handleSATValueChange = useCallback(({ target }) => {
        setSatValue(target.value);
    }, []);

    const handleDenyOptionChange = useCallback(({ target }) => {
        if (target.value === "Outro") {
            setDenyOption("Outro");
            setDenyDetails(target.value);
            return;
        }
        setDenyOption("");
        setTextareaValue("");
        setDenyDetails(target.value);
    }, []);

    const closeConfirmationModal = useCallback(() => {
        setShowConfirmationModal(false);
    }, []);

    const handleAcceptReceipt = async () => {
        if (formData.cashback * 1 === 0) {
            toast.error("O valor de cashback não pode ser nulo!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (formData.cashback * 1 > 1) {
            toast.error("Cashback deve ser menor ou igual a R$ 1,00", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const value = receiptValue * 1;
        const cashbackGiven = formData.cashback * 1;

        if (value < 100 && cashbackGiven > 0.25) {
            toast.error(
                "Até R$ 100,00 o cashback atribuído é de 0,25 centavos.",
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }

        if (value >= 100 && value < 200 && cashbackGiven !== 0.5) {
            toast.error(
                "Para valores entre R$100,00 e R$200,00 o cashback atribuído é de 0,50 centavos.",
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }

        if (value >= 200 && cashbackGiven !== 1) {
            toast.error(
                "Para valores de R$200,00 em diante, o cashback atribuído é de R$1,00.",
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }

        const data = {
            password: formData.password,
            cashback: formData.cashback,
            sat: satValue,
        };

        try {
            setLoading(true);
            const response = await api.patch(
                `api/v1/receipts/${id}/accept`,
                data,
                {
                    headers: { Authorization: token },
                }
            );

            setFormData(defaultFormData);
            onCancel();
            getReceipts();
            setLoading(false);
            toast.success("Status atualizado com sucesso!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log(error.response);
            if (error.response.status === 403) {
                toast.error("Senha incorreta!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setFormData({ ...formData, password: "" });
            }

            if (
                error &&
                error.response.data.message.includes("Falha no envio de email!")
            ) {
                setFormData({ ...formData, password: "" });
                onCancel();
                getReceipts();
                toast.info(
                    "Nota fiscal ACEITA com sucesso! Porém houve uma falha no serviço de envio de email.",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDenyReceipt = async () => {
        if (denyDetails === "Selecionar") {
            toast.info("Selecione o detalhamento da recusa da nota", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const details = textareaValue ? textareaValue : denyDetails;

        const data = {
            password: formData.password,
            denyDetails: details,
        };

        try {
            setLoading(true);
            const response = await api.patch(
                `api/v1/receipts/${id}/deny`,
                data,
                {
                    headers: { Authorization: token },
                }
            );

            setFormData(defaultFormData);
            onCancel();
            getReceipts();
            setLoading(false);
            toast.success("Status atualizado para RECUSADA", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log({ error });
            if (error.response.status === 403) {
                toast.error("Senha incorreta!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setFormData({ ...formData, password: "" });
            }
            if (error.response?.data?.error?.code === 20003) {
                toast.info(
                    "Status da nota alterado! Porém houve uma falha no serviço de SMS.",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );

                setFormData({ ...formData, password: "" });
                onCancel();
            }

            if (
                error &&
                error.response.data.message.includes("Falha no envio de email!")
            ) {
                setFormData({ ...formData, password: "" });
                onCancel();
                getReceipts();
                toast.info(
                    "Nota fiscal NEGADA com sucesso! Porém houve uma falha no serviço de envio de email.",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (status === "ACEITA") {
            setShowConfirmationModal(true);
            return;
        } else if (status === "RECUSADA") handleDenyReceipt();
        else return;
    };

    const handleValidateSAT = async () => {
        console.log("SAT VALUE", satValue);

        const data = {
            sat: satValue,
        };

        if (!satValue) {
            toast.error("Informe o SAT (9 dígitos)", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            setLoading(true);
            setSatStatusMessage("");
            const response = await api.patch(
                `api/v1/receipts/${id}/validate-sat`,
                data,
                {
                    headers: { Authorization: token },
                }
            );

            setLoading(false);
            setSatStatusMessage("SAT válido");
            toast.success(response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log(error.response);
            if (error.response.status === 400) {
                setStatus("RECUSADA");
                toast.error("SAT já existente!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (
                error &&
                error.response.data.message.includes("Falha no envio de email!")
            ) {
                setFormData({ ...formData, password: "" });
                onCancel();
                getReceipts();
                toast.info(
                    "Nota fiscal ACEITA com sucesso! Porém houve uma falha no serviço de envio de email.",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="ReceiptStatusModal">
            <form className="bonus-form" onSubmit={handleSubmit}>
                <h4 className="text-center">Atualizar Status</h4>
                <div className="form-group mt-3">
                    {/* TODO! tratar envio de valor e cashback */}
                    {/* TODO! Com base no status muda a url e os campos ACEITA/RECUSADA */}
                    <label htmlFor="bonus">Status da nota fiscal</label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleChangeStatus}
                        value={status}
                    >
                        <option value="">{currentStatus}</option>
                        <option value="ACEITA">ACEITA</option>
                        <option value="RECUSADA">RECUSADA</option>
                    </select>
                </div>
                {status === "ACEITA" && (
                    <div className="form-group mt-3">
                        {receiptPhoto && (
                            <>
                                <label htmlFor="sat">Validar SAT:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sat"
                                    name="sat"
                                    placeholder="123456789"
                                    onChange={handleSATValueChange}
                                    value={satValue}
                                    maxLength="9"
                                    autoComplete="off"
                                />
                                <span
                                    style={{
                                        fontSize: "12px",
                                        color: "lightgreen",
                                    }}
                                >
                                    {satStatusMessage}
                                </span>
                                <button
                                    type="button"
                                    onClick={handleValidateSAT}
                                    className="btn btn-primary mt-1"
                                >
                                    Validar SAT
                                </button>
                            </>
                        )}
                        <label htmlFor="bonus">Cashback dado:</label>
                        <input
                            type="number"
                            className="form-control"
                            id="cashback"
                            name="cashback"
                            placeholder="20"
                            required
                            step="0.01"
                            onChange={handleInputChange}
                            min="0"
                            max="1.00"
                            value={formData.cashback}
                        />
                    </div>
                )}
                {status === "ACEITA" && (
                    <div className="form-group mt-3">
                        <p className="h6">
                            <strong>
                                Valor da nota fiscal: R${" "}
                                {receiptValue
                                    .toFixed(2)
                                    .toLocaleString(
                                        "pt-br",
                                        { minimumFractionDigits: 2 },
                                        { style: "currency", currency: "BRL" }
                                    )}
                            </strong>
                        </p>
                        <p className="h6">
                            <strong>
                                {showCashbackAmountGiven(formData.cashback)}
                            </strong>
                        </p>
                        <p className="h6">
                            <strong>
                                Porcentagem correspondente:
                                {showCashbackPercentageGiven(formData.cashback)}
                                %
                            </strong>
                        </p>
                    </div>
                )}
                {status !== "PENDENTE" && (
                    <div className="form-group mt-3">
                        <label htmlFor="password">Senha de Admin</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={handleInputChange}
                            value={formData.password}
                            required
                        />
                    </div>
                )}
                {status !== "PENDENTE" && status === "RECUSADA" && (
                    <div className="form-group mt-3">
                        <div style={{ marginTop: "20px" }}>
                            <label htmlFor="denyDetails">Detalhamento:</label>
                            <select
                                name=""
                                id=""
                                value={denyDetails}
                                style={{ width: "100%" }}
                                onChange={handleDenyOptionChange}
                            >
                                {defaultDenyDetails.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            {denyOption === "Outro" && (
                                <textarea
                                    id="denyDetails"
                                    placeholder="Detalhes da recusa"
                                    style={{
                                        width: "100%",
                                        padding: "5px",
                                        marginTop: "4px",
                                    }}
                                    rows={5}
                                    value={textareaValue}
                                    onChange={handleTextareaValueChange}
                                ></textarea>
                            )}
                        </div>
                    </div>
                )}
                {status === "ACEITA" && (
                    <button
                        className="btn btn-success mt-4"
                        type="submit"
                        disabled={loading || satStatusMessage !== "SAT válido"}
                    >
                        Atribuir bônus
                    </button>
                )}
                {status === "RECUSADA" && (
                    <button
                        className="btn btn-warning mt-4"
                        type="submit"
                        disabled={loading || denyDetails === "Selecionar"}
                    >
                        Recusar nota fiscal
                    </button>
                )}
                <button
                    className="btn btn-danger mt-2"
                    type="button"
                    onClick={onCancel}
                >
                    Cancelar
                </button>
            </form>
            {showConfirmationModal && (
                <ConfirmationReceiptAcceptModal
                    cashback={formData.cashback}
                    onCancel={closeConfirmationModal}
                    onConfirm={handleAcceptReceipt}
                    sat={satValue}
                    cashbackPercentage={showCashbackPercentageGiven(
                        formData.cashback
                    )}
                />
            )}
        </div>
    );
};

export default ReceiptStatusModal;
