import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";
import data from "./data.js";

import api from "../../services/api";

const MONTHS = [
    "JANEIRO",
    "FEVEREIRO",
    "MARÇO",
    "ABRIL",
    "MAIO",
    "JUNHO",
    "JULHO",
    "AGOSTO",
    "SETEMBRO",
    "OUTUBRO",
    "NOVEMBRO",
    "DEZEMBRO",
];

const CustomersStoreReportsPage = () => {
    const history = useHistory();
    const [city, setCity] = useState("");
    const [initialMonth, setInitialMonth] = useState(0);
    const [currentYear, setCurrentYear] = useState(0);
    const [monthlyCustomers, setMonthlyCustomers] = useState([]);
    const [monthlySales, setMonthlySales] = useState([]);
    const [totalMonthlySales, setTotalMonthlySales] = useState(0);
    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const [showLatestSalesMessage, setShowLatestSalesMessage] = useState(false);
    const token = getToken();

    const handleSelectChange = ({ target }) => {
        setCity(target.value);
        setShowResultsMessage(false);
    };

    const handleSelectMonthChange = ({ target }) => {
        setInitialMonth(target.value);
        setShowLatestSalesMessage(false);
    };

    const handleYearChange = ({ target }) => {
        setCurrentYear(target.value);
        setShowLatestSalesMessage(false);
    };

    const getTopSellersData = async () => {
        try {
            if (city === "") return;

            const response = await api.get(
                `api/v1/sales/top-sellers?city=${city}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;
            setCity("");
            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }
            console.log(response);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getTotalCustomersPurchased = async () => {
        try {
            const response = await api.get(
                `api/v1/dashboard/admin/total-customers-purchased?month=${initialMonth}&year=${currentYear}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                // setShowLatestSalesMessage(true);
                return;
            }
            console.log(response);
            setMonthlyCustomers(data.result);
            // generateLatestSalesReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getTotalSalesPerStore = async () => {
        try {
            const response = await api.get(
                `api/v1/dashboard/admin/total-sales-by-store?month=${initialMonth}&year=${currentYear}`,
                {
                    headers: { Authorization: token },
                }
            );

            const response2 = await api.get(
                `api/v1/dashboard/admin/total-monthly-sales?month=${initialMonth}&year=${currentYear}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                // setShowLatestSalesMessage(true);
                return;
            }

            setMonthlySales(data);
            setTotalMonthlySales(
                response2.data.result[0].totalSalesOnThisMonth
            );
            generateLatestSalesReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getTotalCustomersByCity = async () => {
        try {
            const response = await api.get(
                `api/v1/reports/total-customers-by-city`,
                {
                    headers: { Authorization: token },
                }
            );
            const { data } = response;

            console.log(response);

            generateTotalCustomersByCityReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const generateReport = (topSellersData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO VENDAS POR EMPRESA - ${city.toUpperCase()} (${currentDate.toLocaleDateString()})`;

        const headers = [
            [
                "EMPRESA",
                "NÚM. TOTAL DE VENDAS",
                "TOT. CASHBACK DADO (R$)",
                "TOT. VENDIDO (R$)",
            ],
        ];

        const data = topSellersData.map((elt) => [
            elt.empresa.name,
            elt.totalVendas,
            elt.totalCashback.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            elt.totalVendido.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateFilename(city, currentDate);
        doc.save(`${filename}.pdf`);
    };

    const generateReportDataA = (topSellersData) => {
        console.log(topSellersData);
        console.log(topSellersData.length);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `ACESSO EMPRESAS INFO - 26/05/2023`;

        const headers = [
            ["EMPRESA", "TELEFONE", "ACESSO", "CIDADE", "ÚLTIMO LOGIN"],
        ];

        const data = topSellersData.map((elt) => [
            elt.store.name,
            elt.phone,
            elt.email,
            elt.store.city,
            new Date(elt.updatedAt).toLocaleString("pt-br"),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateFilename(city, currentDate);
        doc.save(`${filename}.pdf`);
    };

    const generateLatestSalesReport = (latestSales) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO DE VENDAS - ${
            MONTHS[initialMonth - 1]
        } / ${currentYear}`;

        const headers = [
            ["EMPRESA", "VALOR", "% CASHBACK", "CASHBACK", "DATA"],
        ];

        const data = latestSales.map((elt) => [
            elt.empresa[0].name,
            elt.value.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            elt.cashbackPercentage * 100,
            elt.cashback.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            new Date(elt.createdAt).toLocaleString("pt-br"),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateLatestSalesFilename(initialMonth);
        doc.save(filename);
    };

    const generateTotalCustomersByCityReport = (totalCustomersByCityData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO NÚMERO DE CLIENTES POR CIDADE - (${currentDate.toLocaleDateString()})`;

        const headers = [["CIDADE", "NÚMERO DE CLIENTES"]];

        const data = totalCustomersByCityData.map((elt) => [
            elt._id,
            elt.totalDeClientes,
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [0, 156, 96], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const { day, month, year } = formatDate(currentDate);
        const filename = `RNC-${day}${month}${year}`;

        doc.save(`${filename}.pdf`);
    };

    const generateLatestSalesFilename = (initialMonth) => {
        const filename = `RV-${MONTHS[initialMonth - 1]}_${currentYear}.pdf`;

        return filename;
    };

    const generateFilename = (city, date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return `RV-${city.toUpperCase()}-${day}${month}${year}`;
    };

    const formatDate = (date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return { day, month, year };
    };

    useEffect(() => {
        const now = new Date();
        setCurrentYear(now.getFullYear());
        setInitialMonth(now.getMonth() + 1);
        console.log(currentYear);
        console.log(initialMonth);
    }, []);

    return (
        <div className="CustomersStoreReportsPage">
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                <div className="row">
                    <p>Selecione o período que deseja obter os dados:</p>
                    <div
                        className="row d-flex"
                        style={{ alignItems: "baseline" }}
                    >
                        <div className="col-auto">
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={handleSelectMonthChange}
                                value={initialMonth}
                            >
                                <option value="1" selected={initialMonth === 1}>
                                    Janeiro
                                </option>
                                <option value="2" selected={initialMonth === 2}>
                                    Fevereiro
                                </option>
                                <option value="3" selected={initialMonth === 3}>
                                    Março
                                </option>
                                <option value="4" selected={initialMonth === 4}>
                                    Abril
                                </option>
                                <option value="5" selected={initialMonth === 5}>
                                    Maio
                                </option>
                                <option value="6" selected={initialMonth === 6}>
                                    Junho
                                </option>
                                <option value="7" selected={initialMonth === 7}>
                                    Julho
                                </option>
                                <option value="8" selected={initialMonth === 8}>
                                    Agosto
                                </option>
                                <option value="9" selected={initialMonth === 9}>
                                    Setembro
                                </option>
                                <option
                                    value="10"
                                    selected={initialMonth === 10}
                                >
                                    Outubro
                                </option>
                                <option
                                    value="11"
                                    selected={initialMonth === 11}
                                >
                                    Novembro
                                </option>
                                <option
                                    value="12"
                                    selected={initialMonth === 12}
                                >
                                    Dezembro
                                </option>
                            </select>
                        </div>
                        <div className="col-auto">
                            <p>de</p>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                type="number"
                                min={2022}
                                placeholder="2022"
                                value={currentYear}
                                onChange={handleYearChange}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col">
                            <div
                                className="card bg-primary text-white p-4 text-center"
                                role="button"
                                onClick={getTotalCustomersPurchased}
                            >
                                OBTER NÚMERO E QUAIS CLIENTES COMPRARAM NESSE
                                PERÍODO
                            </div>
                        </div>
                        <div className="col">
                            <div
                                className="card bg-success text-white p-4 text-center"
                                role="button"
                                onClick={getTotalSalesPerStore}
                            >
                                OBTER EMPRESAS E SEU NÚMERO DE VENDAS NESSE
                                PERÍODO
                            </div>
                        </div>
                    </div>

                    <div className="row table-monthly-customers">
                        {monthlyCustomers?.length > 0 && (
                            <div>
                                <h4 className="text-center">
                                    CLIENTES E NÚMERO DE COMPRAS NO PERÍODO
                                    ESCOLHIDO
                                </h4>
                            </div>
                        )}
                        {monthlyCustomers?.length > 0 && (
                            <table className="listPromoCodes monthlyCustomers">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Cliente</th>
                                        <th>Número de compras nesse mês</th>
                                        <th>
                                            Total de cashback recebido nesse mês
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {monthlyCustomers.map((item, index) => (
                                        <tr key={item._id}>
                                            <td> {index + 1}</td>
                                            <td>{item.customer.name}</td>
                                            <td>{item.totalPurchases}</td>
                                            <td>
                                                R$
                                                {` ` +
                                                    item.totalCashbackReceived.toLocaleString(
                                                        "pt-br",
                                                        {
                                                            minimumFractionDigits: 2,
                                                        },
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div
                        className="row"
                        style={{ alignItems: "baseline" }}
                    ></div>

                    <div className="row table-monthly-sales">
                        {monthlySales?.length > 0 && (
                            <div>
                                <h4 className="text-center">
                                    EMPRESAS E SUAS VENDAS NO PERÍODO ESCOLHIDO
                                </h4>
                                <h6>
                                    TOTAL DE VENDAS NESSE PERÍODO:{" "}
                                    <strong>{totalMonthlySales}</strong>
                                </h6>
                            </div>
                        )}
                        {monthlySales?.length > 0 && (
                            <table className="listPromoCodes monthlySales">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Empresa</th>
                                        <th>Número de vendas nesse mês</th>
                                        <th>Total vendido nesse mês</th>
                                        <th>Total cashback dado nesse mês</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {monthlySales.map((item, index) => (
                                        <tr key={item._id}>
                                            <td>{index + 1}</td>
                                            <td>{item.store.name}</td>
                                            <td>{item.totalSales}</td>
                                            <td>
                                                R$
                                                {` ` +
                                                    item.totalSold.toLocaleString(
                                                        "pt-br",
                                                        {
                                                            minimumFractionDigits: 2,
                                                        },
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                            </td>
                                            <td>
                                                R$
                                                {` ` +
                                                    item.totalCashbackToCustomers.toLocaleString(
                                                        "pt-br",
                                                        {
                                                            minimumFractionDigits: 2,
                                                        },
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomersStoreReportsPage;
