export function convertLinkToHttpsAndCDN(link) {
    if (!link.startsWith("nyc3.")) {
        // LINK NORMAL COM HTTPS SEM CDN
        return link.replace("nyc3", "nyc3.cdn");
    } else {
        // LINK SEM HTTPS E CDN
        const parts = link.split("/");
        const bucket = parts.shift(); // "nyc3.digitaloceanspaces.com"
        const subdomain = parts.shift(); // "lucrei"
        const path = parts.join("/"); // "receipts/uploads/1680262582253.jpeg"
        const output = `https://${subdomain}.${bucket}/${path}`;
        return output.replace("nyc3", "nyc3.cdn");
    }
}
