import {
  useHistory
} from "react-router-dom";
import {
  useEffect,
  useState
} from "react";
import api from "../../services/api";
import {
  getToken
} from "../../services/auth";


import Menu from "../../components/Menu/Menu";
import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'


import './style.scss'


export default function ViewStore() {

  const n = ["janeiro", "fevereiro", "março",
    "abril", "maio", "junho", "julho", "agosto",
    "setembro", "outubro", "novembro", "dezembro"][(new Date()).getMonth()]

  var mes = (new Date().getMonth() + 1)

  const token = getToken();
  const history = useHistory();

  const [countSales, setCountSales] = useState()

  const [depositos, setDepositos] = useState(0);
  const [cashbacks, setCashbacks] = useState(0);
  const [taxas, setTaxas] = useState(0)

  const Balance = async () => {
    const response = await api.get("/api/v1/sales/monthly/balance"
      , {
        headers: { Authorization: token },
      }
    );
    setDepositos(Object.values(response.data)[0])
    setTaxas(Object.values(response.data)[1])
    setCashbacks(Object.values(response.data)[2])
  };

  const CountSales = async () => {
    const response = await api.get(
      `api/v1/sales/count?month=${mes}`,
      {
        headers: { Authorization: token }
      }
    )

    setCountSales(Object.values(response.data)[0])
  }


  useEffect(() => {
    Balance();
    CountSales()
    /* eslint-disable-next-line */
  }, [])
  function Graph(props) {
    return (
      <div className="graph">
        <button className="buttonGraph" onClick={props.linkDestination}>
          <div>
            <span>{props.title}</span>

          </div>
          <div>
            <span> {props.value}</span>
          </div>
          <div style={{ border: `8px solid ${props.color}`, borderRadius: '15px' }}>
            {props.icon}
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className="containerSaque">

      <div className="menu">
        <Menu
          title1="Dashboard"
          icon1={
            <img src={logo} alt="" with="25" height="25" />
          }
          onClick1={() => history.push("/admin/dash")}


          title2="Cadastrar Empresa"
          icon2={
            <img src={logo} alt="" with="25" height="25" />
          }
          onClick2={() => history.push("/admin/cadastro")}


          title3="Saques"
          icon3={<img src={logo} alt="" with="25" height="25" />}
          onClick3={() => history.push("/admin/saques")}

          title4="Depositos"
          icon4={<img src={logo} alt="" with="25" height="25" />}
          onClick4={() => history.push("/admin/depositos")}

        />
      </div>
      <div className="container" id="content">

        <div className="box">
          {console.log(countSales)}
          <Graph title="Cashbacks" value={cashbacks ? cashbacks.toFixed(2) : '0.00'} icon={""} color='orange' linkDestination={"s"} />
          <Graph title="Taxas" value={taxas ? taxas.toFixed(2) : '0.00'} linkTitle="Últimos 30 dias" icon={""} color='rgb(99,183,100)' />
          <Graph title="Depositos" value={depositos ? depositos.toFixed(2) : '0.00'} linkTitle="Listar Motoristas" icon={""} color='rgb(28,190,211)' />
          <Graph title="Vendas Mensais" value={countSales} linkTitle="Listar Motoristas" icon={""} color='rgb(28,190,211)' />
        </div>

      </div>

    </div>
  );
}
