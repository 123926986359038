import {
    useHistory
  } from "react-router-dom";
  import {
    useEffect,
    useState
  } from "react";
  import api from "../../services/api";
  import {
    getToken
  } from "../../services/auth";


  import Menu from "../../components/Menu/Menu";
  import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'
  import WithdrawTable from "../../components/admin/RequestWithdraw/index"
  import Pagination from "../../components/Pagination";

  import './style.scss'

  export default function ViewStore() {
    const token = getToken();
    const history = useHistory();

    const [withdrawals, setWithdrawals] = useState([]);
    const [currentPageWithdrawals, setCurrentPageWithdrawals] = useState(1);
    const [totalPagesWithdrawals, setTotalPagesWithdrawals] = useState(1);

    const loadWithdrawals = async () => {
        const response = await api.get(
          `api/v1/withdrawals?sortBy=-createdAt&pageNumber=${currentPageWithdrawals}`,
          {
            headers: { Authorization: token },
          }
        );

        setWithdrawals(response.data);
        setTotalPagesWithdrawals(response.data.pages);
      };

      useEffect(() => {
            loadWithdrawals()
            /* eslint-disable-next-line */
          }, []);

      useEffect(() => {
        loadWithdrawals();

        /* eslint-disable-next-line */
      }, [currentPageWithdrawals])


    return (
      <div className="containerSaque">

     <div className="menu">
     <Menu
         title1="Dashboard"
         icon1={
           <img src={logo} alt="" with="25" height="25" />
         }
         onClick1={() => history.push("/admin/dash")}


        title2="Cadastrar Empresa"
        icon2={
          <img src={logo} alt="" with="25" height="25" />
        }
        onClick2={() => history.push("/admin/cadastro")}


        title3="Saques"
        icon3={<img src={logo} alt="" with="25" height="25" />}
        onClick3={() => history.push("/admin/saques")}

        title4="Depositos"
        icon4={<img src={logo} alt="" with="25" height="25" />}
        onClick4={() => history.push("/admin/depositos")}

      />
     </div>
     <div className="tableSaques">

     {withdrawals.data && withdrawals.data.length > 0 && (
          <WithdrawTable
            withdrawals={withdrawals.data}
            loadWithdrawals={loadWithdrawals}
          />
        )}
        <Pagination
          currentPage={currentPageWithdrawals}
          totalPages={totalPagesWithdrawals}
          paginate={setCurrentPageWithdrawals}
        />

  </div>

  </div>
    );
  }
