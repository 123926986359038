import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";

import api from "../../services/api";

const MONTHS = [
    "JANEIRO",
    "FEVEREIRO",
    "MARÇO",
    "ABRIL",
    "MAIO",
    "JUNHO",
    "JULHO",
    "AGOSTO",
    "SETEMBRO",
    "OUTUBRO",
    "NOVEMBRO",
    "DEZEMBRO",
];

const TopSellerStoresPage = () => {
    const history = useHistory();
    const [city, setCity] = useState("");
    const [initialMonth, setInitialMonth] = useState(0);
    const [currentYear, setCurrentYear] = useState(0);
    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const [showLatestSalesMessage, setShowLatestSalesMessage] = useState(false);
    const token = getToken();

    const handleSelectChange = ({ target }) => {
        setCity(target.value);
        setShowResultsMessage(false);
    };

    const handleSelectMonthChange = ({ target }) => {
        setInitialMonth(target.value);
        setShowLatestSalesMessage(false);
    };

    const handleYearChange = ({ target }) => {
        setCurrentYear(target.value);
        setShowLatestSalesMessage(false);
    };

    const getTopSellersData = async () => {
        try {
            if (city === "") return;

            const response = await api.get(
                `api/v1/sales/top-sellers?city=${city}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;
            setCity("");
            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }
            console.log(response);

            generateReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getLatestSalesByMonth = async () => {
        try {
            const response = await api.get(
                `api/v1/sales/latest-sales-by-month?initialMonth=${initialMonth}&currentYear=${currentYear}`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                setShowLatestSalesMessage(true);
                return;
            }
            console.log(response);

            generateLatestSalesReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getTotalCustomersByCity = async () => {
        try {
            const response = await api.get(
                `api/v1/reports/total-customers-by-city`,
                {
                    headers: { Authorization: token },
                }
            );
            const { data } = response;

            console.log(response);

            generateTotalCustomersByCityReport(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const generateReport = (topSellersData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO VENDAS POR EMPRESA - ${city.toUpperCase()} (${currentDate.toLocaleDateString()})`;

        const headers = [
            [
                "EMPRESA",
                "NÚM. TOTAL DE VENDAS",
                "TOT. CASHBACK DADO (R$)",
                "TOT. VENDIDO (R$)",
            ],
        ];

        const data = topSellersData.map((elt) => [
            elt.empresa.name,
            elt.totalVendas,
            elt.totalCashback.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            elt.totalVendido.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateFilename(city, currentDate);
        doc.save(`${filename}.pdf`);
    };

    const generateLatestSalesReport = (latestSales) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO DE VENDAS - ${
            MONTHS[initialMonth - 1]
        } / ${currentYear}`;

        const headers = [
            ["EMPRESA", "VALOR", "% CASHBACK", "CASHBACK", "DATA"],
        ];

        const data = latestSales.map((elt) => [
            elt.empresa[0].name,
            elt.value.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            elt.cashbackPercentage * 100,
            elt.cashback.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 },
                { style: "currency", currency: "BRL" }
            ),
            new Date(elt.createdAt).toLocaleString("pt-br"),
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [13, 110, 253], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const filename = generateLatestSalesFilename(initialMonth);
        doc.save(filename);
    };

    const generateTotalCustomersByCityReport = (totalCustomersByCityData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        const title = `RELATÓRIO NÚMERO DE CLIENTES POR CIDADE - (${currentDate.toLocaleDateString()})`;

        const headers = [["CIDADE", "NÚMERO DE CLIENTES"]];

        const data = totalCustomersByCityData.map((elt) => [
            elt._id,
            elt.totalDeClientes,
        ]);

        let content = {
            startY: 70,
            head: headers,
            body: data,
            headStyles: { fillColor: [0, 156, 96], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 40);
        doc.autoTable(content);
        const { day, month, year } = formatDate(currentDate);
        const filename = `RNC-${day}${month}${year}`;

        doc.save(`${filename}.pdf`);
    };

    const generateLatestSalesFilename = (initialMonth) => {
        const filename = `RV-${MONTHS[initialMonth - 1]}_${currentYear}.pdf`;

        return filename;
    };

    const generateFilename = (city, date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return `RV-${city.toUpperCase()}-${day}${month}${year}`;
    };

    const formatDate = (date) => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const year = date.getFullYear();
        return { day, month, year };
    };

    useEffect(() => {
        const now = new Date();
        setCurrentYear(now.getFullYear());
        setInitialMonth(now.getMonth() + 1);
        console.log(currentYear);
        console.log(initialMonth);
    }, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage">
                <div className="row">
                    <p>Criar relatório de vendas para:</p>
                    <div className="row" style={{ alignItems: "baseline" }}>
                        <div className="col-auto">
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={handleSelectMonthChange}
                                value={initialMonth}
                            >
                                <option value="1" selected={initialMonth === 1}>
                                    Janeiro
                                </option>
                                <option value="2" selected={initialMonth === 2}>
                                    Fevereiro
                                </option>
                                <option value="3" selected={initialMonth === 3}>
                                    Março
                                </option>
                                <option value="4" selected={initialMonth === 4}>
                                    Abril
                                </option>
                                <option value="5" selected={initialMonth === 5}>
                                    Maio
                                </option>
                                <option value="6" selected={initialMonth === 6}>
                                    Junho
                                </option>
                                <option value="7" selected={initialMonth === 7}>
                                    Julho
                                </option>
                                <option value="8" selected={initialMonth === 8}>
                                    Agosto
                                </option>
                                <option value="9" selected={initialMonth === 9}>
                                    Setembro
                                </option>
                                <option
                                    value="10"
                                    selected={initialMonth === 10}
                                >
                                    Outubro
                                </option>
                                <option
                                    value="11"
                                    selected={initialMonth === 11}
                                >
                                    Novembro
                                </option>
                                <option
                                    value="12"
                                    selected={initialMonth === 12}
                                >
                                    Dezembro
                                </option>
                            </select>
                        </div>
                        <div className="col-auto">
                            <p>de</p>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                type="number"
                                min={2022}
                                placeholder="2022"
                                value={currentYear}
                                onChange={handleYearChange}
                            />
                        </div>
                    </div>

                    <button
                        className="btn btn-primary m-2"
                        onClick={getLatestSalesByMonth}
                    >
                        GERAR RELATÓRIO
                    </button>

                    <div className="row">
                        {showLatestSalesMessage && (
                            <p className="text-center message">
                                Não foram cadastradas vendas no período
                                selecionado
                            </p>
                        )}
                    </div>
                </div>
                <hr />
                <p>
                    Selecione a cidade para obter o número de vendas das suas
                    empresas.
                </p>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={city}
                >
                    <option selected value="">
                        Selecionar cidade
                    </option>
                    <option value="Aracoiaba">Aracoiaba</option>
                    <option value="Baturité">Baturité</option>
                    <option value="Capistrano">Capistrano</option>
                </select>
                <button
                    className="btn btn-primary m-2"
                    disabled={city === ""}
                    onClick={getTopSellersData}
                >
                    GERAR RELATÓRIO VENDAS POR CIDADE
                </button>
                <hr />
                <div>
                    <button
                        className="btn btn-success m-1"
                        onClick={getTotalCustomersByCity}
                    >
                        GERAR RELATÓRIO CLIENTES POR CIDADE
                    </button>
                </div>

                <div className="row">
                    {showResultsMessage && (
                        <p className="text-center message">
                            A cidade selecionada ainda não possui registros de
                            vendas!
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopSellerStoresPage;
