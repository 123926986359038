import {
  useHistory
} from "react-router-dom";
import {
  useEffect,
  useState
} from "react";
import api from "../../services/api";
import {
  getToken
} from "../../services/auth";

import Pagination from "../../components/Pagination";

import logo from '../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png'

import Menu from "../../components/Menu/Menu";

import Dados from '../../components/dadosEmpresa/index'

import Endereço from '../../components/endereçoEmpresa/index';

import './style.scss'

import Sales from '../../components/sales/index'

export default function ViewStore({ deposit }) {

  const token = getToken();

  const history = useHistory();


  const ID_EMPRESA = "id"

  const id = localStorage.getItem(ID_EMPRESA)
  const [currentPageSales, setcurrentPageSales] = useState(1);
  const [totalPagesSales, setTotalPagesSales] = useState(1)


  const [store, setStore] = useState({});
  const [address, setAddress] = useState({})
  const [account, setAccount] = useState({})
  const [sales, setSales] = useState([])


  const SalesStore = async () => {
    const response = await api.get(`/api/v1/stores/${id}/sales?pageSize=10&sortBy=-createdAt&pageNumber=${currentPageSales}`, {
      headers: {
        Authorization: token
      },



    });
    setSales(response.data.data);
    // console.log(response.data.data)
    setTotalPagesSales(response.data.pages);

  }



  useEffect(() => {
    SalesStore();

    /* eslint-disable-next-line */
  }, [currentPageSales])



  useEffect(() => {
    async function getLoggedUser() {
      const response = await api.get(`api/v1/admin/stores/${id}`, {
        headers: {
          Authorization: token
        },
      });
      const {
        amountToPay,
        name,
        role,
        whatsapp,
        city,
        cnpj,
        managerName,
        marketSegments,
        managerCpf,
        avatar
      } = response.data;

      const {
        street,
        placeNumber,
        district,
        state,
        cep
      } = response.data.address;

      const {
        email,
        phone

      } = response.data.account;

      setStore({
        amountToPay,
        email,
        name,
        role,
        phone,
        whatsapp,
        city,
        cnpj,
        avatar,
        managerName,
        marketSegments,
        managerCpf,
        address,
        account
      });
      setAddress({
        street,
        placeNumber,
        district,
        state,
        cep
      })
      setAccount({
        email,
        phone
      })

    };
    getLoggedUser()
  }, []);


  const DEPOSIT = "@DEPOSIT";
  localStorage.setItem(DEPOSIT, store.amountToPay);




  return (
    <div className="container" id="principal">

      <div className="menu">
        <Menu
          title1="Dashboard"
          icon1={
            <img src={logo} alt="" with="25" height="25" />
          }
          onClick1={() => history.push("/admin/dash")}


          title2="Saques"
          icon2={<img src={logo} alt="" with="25" height="25" />}
          onClick2={() => history.push("/admin/saques")}

          title3="Depositos"
          icon3={<img src={logo} alt="" with="25" height="25" />}
          onClick3={() => history.push("/admin/depositos")}

        />
      </div>
      <div className="content" id="conteudo" >
        <div className="container" id="dados">
          <Dados
            store={store}
            account={account}
          />

        </div>
        <div className="container" id="endereco">
          <Endereço
            store={store}
            address={address}

          />
        </div>

        <div className="container" id="vendas">
          <Sales
            sales={sales}
          />
          <Pagination
            currentPage={currentPageSales}
            totalPages={totalPagesSales}
            paginate={setcurrentPageSales}
          />


        </div>



      </div>

    </div>
  );
}
