import { useEffect, useState } from "react";
import "./style.scss";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";
import { toast } from "react-toastify";
import { MdDelete, MdReceiptLong } from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import Loader from "../../components/Loader";
import ConfirmationDeleteCouponModal from "../../components/ConfirmationDeleteCouponModal";

export default function CustomerCouponsStatsPage() {
    const history = useHistory();
    const token = getToken();

    const [isLoading, setIsLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [couponsByParent, setCouponsByParent] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        async function getCouponsWithPagination() {
            try {
                setIsLoading(true);
                const response = await api.get(
                    "api/v1/dashboard/admin/customer-coupons/by-store",
                    {
                        headers: { Authorization: token },
                    }
                );

                setCoupons(response.data);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }
        /** aqui no useEffect currentPage já está com o valor atualizado */

        getCouponsWithPagination();
    }, []);

    useEffect(() => {
        async function getTotalCuponsByParentCount() {
            try {
                setIsLoading(true);
                const response = await api.get(
                    "api/v1/dashboard/admin/customer-coupons/by-parent-coupon",
                    {
                        headers: { Authorization: token },
                    }
                );
                console.log(response);
                setCouponsByParent(response.data);
            } catch (error) {
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        }

        getTotalCuponsByParentCount();
    }, []);

    return (
        <div style={{ paddingBottom: "20px" }}>
            <div className="container default-page">
                <div
                    className="quick-access-buttons"
                    style={{ marginBottom: "60px" }}
                >
                    <Link
                        to="/admin/cupons"
                        className="btn btn-light"
                        role="button"
                    >
                        Voltar
                    </Link>
                    <Link
                        to="/admin/lista-cupons"
                        className="btn btn-primary"
                        role="button"
                    >
                        Visualizar todos
                    </Link>
                </div>
                <p className="promoHeader">Números de Cupons por Empresa</p>

                {isLoading && <Loader />}
                <div className="tabela tabela-coupons">
                    {coupons?.length > 0 && (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">-</th>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">
                                        <span className="badge bg-success">
                                            USADOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-primary">
                                            VÁLIDOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-warning">
                                            EXPIRADOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-danger">
                                            INVÁLIDOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-dark">
                                            TOTAL
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons?.map((coupon) => (
                                    <tr key={coupon._id}>
                                        <td scope="row">
                                            <img
                                                src={coupon?.storeAvatar}
                                                alt="Logo da Empresa"
                                                width={54}
                                                height={54}
                                                style={{ margin: "0 auto" }}
                                            />
                                        </td>
                                        <td>{coupon?.storeName}</td>

                                        <td>{coupon.usedCount}</td>
                                        <td
                                            style={
                                                coupon?.description?.length > 20
                                                    ? { fontSize: "12px" }
                                                    : { fontSize: "14px" }
                                            }
                                        >
                                            {coupon.validCount}
                                        </td>
                                        <td>{coupon.expiredCount}</td>

                                        <td>{coupon.invalidCount}</td>
                                        <td>{coupon.generatedCount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {coupons?.length === 0 && (
                        <p className="text-center">Sem cupons para exibir.</p>
                    )}
                </div>

                <p className="promoHeader">Números de Cupons Individuais</p>

                <div className="tabela">
                    {couponsByParent?.length > 0 && (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Cupom</th>
                                    <th scope="col">
                                        <span className="badge bg-success">
                                            USADOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-primary">
                                            VÁLIDOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-warning">
                                            EXPIRADOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-danger">
                                            INVÁLIDOS
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="badge bg-dark">
                                            TOTAL
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {couponsByParent?.map((couponByParent) => (
                                    <tr key={couponByParent._id}>
                                        <td scope="row">
                                            <img
                                                src={couponByParent?.storeLogo}
                                                alt="Logo da Empresa"
                                                width={54}
                                                height={54}
                                                style={{ margin: "0 auto" }}
                                            />
                                        </td>

                                        <td
                                            style={
                                                couponByParent?.description
                                                    ?.length > 20
                                                    ? { fontSize: "12px" }
                                                    : { fontSize: "14px" }
                                            }
                                        >
                                            {couponByParent?.description}
                                        </td>
                                        <td>{couponByParent.usedCount}</td>
                                        <td>{couponByParent.validCount}</td>

                                        <td>{couponByParent.invalidCount}</td>
                                        <td>{couponByParent.expiredCount}</td>
                                        <td>{couponByParent.generatedCount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {couponsByParent?.length === 0 && (
                        <p className="text-center">Sem cupons para exibir.</p>
                    )}
                </div>
            </div>
        </div>
    );
}
