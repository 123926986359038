import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import logo from "../../assets/GeneralLogo/Logotipo/icones/Logo icone amarelo.png";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { getToken } from "../../services/auth";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";

import { FaFileExport } from "react-icons/fa";

import api from "../../services/api";

const TopReceiptsSendersPage = () => {
    const history = useHistory();

    const [showResultsMessage, setShowResultsMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [topReceiptSenders, setTopReceiptSenders] = useState([]);
    const [showLatestSalesMessage, setShowLatestSalesMessage] = useState(false);
    const [totalReceiptsSenders, setTotalReceiptsSenders] = useState(0);
    const [createdAt, setCreatedAt] = useState("");
    const token = getToken();

    const getTotalReceiptSenders = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/total-receipts-senders`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }

            setTotalReceiptsSenders(data.data);
            // setTopStores(data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getTopReceiptSenders = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `api/v1/dashboard/admin/notas-fiscais/top-receipts-senders`,
                {
                    headers: { Authorization: token },
                }
            );

            const { data } = response;

            if (data.length === 0) {
                setShowResultsMessage(true);
                return;
            }

            console.log("DATA TOP", { data });
            setTopReceiptSenders(data.data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const generateReport = async () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 3);
        const totalSendersTitle = `NÚMERO DE USUÁRIOS COM ENVIOS DE NOTAS FISCAIS: ${totalReceiptsSenders}`;
        const topSendersTitle = `TOP 30 USUÁRIOS COM MAIS ENVIOS DE NOTAS FISCAIS`;

        const headers = [
            ["#", "USUÁRIO", "CIDADE", "NÚMERO DE NOTAS ENVIADAS"],
        ];

        const data = topReceiptSenders.map((elt, index) => [
            index + 1,
            elt.customer.name,
            elt.customer.city,
            elt.totalSentReceipts,
        ]);

        let content = {
            startY: 90,
            head: headers,
            body: data,
            headStyles: { fillColor: [0, 238, 0], halign: "center" },
            bodyStyles: { halign: "center" },
        };

        const xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(totalSendersTitle) *
                doc.internal.getFontSize()) /
                2;
        const xOffsetTopSenders =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(totalSendersTitle) *
                doc.internal.getFontSize()) /
                2;
        // doc.text(title, xOffset, 40);
        doc.text(xOffset, 40, totalSendersTitle);
        doc.text(xOffsetTopSenders, 80, topSendersTitle);
        doc.autoTable(content);

        doc.save(
            `REL-TOP-ENVIOS-NOTAS-${currentDate.toLocaleDateString()}.pdf`
        );
    };

    useEffect(() => {
        getTotalReceiptSenders();
        getTopReceiptSenders();
    }, []);

    return (
        <div>
            <Menu
                title1="Dashboard"
                icon1={<img src={logo} alt="" with="25" height="25" />}
                onClick1={() => history.push("/admin/dash")}
                title2="Cadastrar Empresa"
                icon2={<img src={logo} alt="" with="25" height="25" />}
                onClick2={() => history.push("/admin/cadastro")}
                title3="Saques"
                icon3={<img src={logo} alt="" with="25" height="25" />}
                onClick3={() => history.push("/admin/saques")}
                title4="Depositos"
                icon4={<img src={logo} alt="" with="25" height="25" />}
                onClick4={() => history.push("/admin/depositos")}
            />
            <div className="TopSellerStoresPage TopReceiptsSendersPage">
                <div className="row">
                    <div>
                        <h5>
                            Total usuários que já enviaram notas:{" "}
                            <span>{totalReceiptsSenders}</span>
                        </h5>
                    </div>
                    <div className="button-wrapper">
                        <h4>Top 30 usuários que mais enviaram notas</h4>
                        {topReceiptSenders.length > 0 && (
                            <button
                                className="btn-export-pdf"
                                onClick={generateReport}
                            >
                                <FaFileExport className="icon-pdf" />
                                <span>Exportar como PDF</span>
                            </button>
                        )}
                    </div>
                    {!loading && (
                        <div className="listPromoCodesMobileWrapper">
                            <table className="listPromoCodes">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Usuário</th>
                                        <th scope="col">Cidade</th>
                                        <th scope="col">Notas Enviados</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topReceiptSenders?.map((sender, index) => (
                                        <tr key={sender._id}>
                                            <td>{index + 1}</td>
                                            <td title={sender?.customer.name}>
                                                <Link
                                                    to={`/clientes/${sender._id}/notas-fiscais`}
                                                >
                                                    {sender?.customer.name}
                                                </Link>
                                            </td>

                                            <td>{sender?.customer.city}</td>

                                            <td> {sender.totalSentReceipts}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <div className="row">
                        {showLatestSalesMessage && (
                            <p className="text-center message">
                                Ainda não foram cadastradas notas fiscais para
                                serem exibidas.
                            </p>
                        )}
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default TopReceiptsSendersPage;
