import { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

import "./style.scss";
import api from "../../services/api";

import { useHistory, Link } from "react-router-dom";
import { getToken } from "../../services/auth";

const data = [
    {
        name: "Jun 23",
        total_vendido: 3922.6,
        num_vendas: 43,
        amt: 100,
    },
    {
        name: "Jul 23",
        total_vendido: 3000,
        num_vendas: 35,
        amt: 100,
    },
];

export default function StatsReportPage() {
    const history = useHistory();
    const token = getToken();

    return (
        <div>
            <div className="container default-page">
                <ResponsiveContainer width="50%" height="50%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total_vendido" fill="#8884d8" />
                        <Bar dataKey="num_vendas" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
